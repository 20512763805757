import React from 'react';
import { IFaqs } from '@mayfield/sanity';
import cn from 'classnames';
import { useIntersectionAnimation } from '@mayfield/common/hooks';
import {
  IntersectionAnimation,
  LayoutProvider
} from '@mayfield/common/components';
import * as styles from './styles.module.scss';
import Accordion from './components/Accordion';

interface IProps {
  data: IFaqs;
  id?: string;
}

const FAQs = ({
  data: { backgroundColor, questions, textColor, title },
  id
}: IProps) => {
  const { inView, ref } = useIntersectionAnimation();

  return (
    <div
      className={styles.container}
      id={id}
      style={{ backgroundColor: backgroundColor.hex, color: textColor.hex }}
    >
      <LayoutProvider grid padding maxWidth>
        <div className={styles.content}>
          <h2 className={cn('h1', styles.title)} ref={ref}>
            {title}
          </h2>

          {questions.map(({ answer, question }, i) => (
            <IntersectionAnimation
              key={i}
              delay={i * 100 + 100}
              trigger={inView}
            >
              <Accordion answer={answer} question={question} />
            </IntersectionAnimation>
          ))}
        </div>
      </LayoutProvider>
    </div>
  );
};

export default FAQs;

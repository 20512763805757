// extracted by mini-css-extract-plugin
export var button = "styles-module--button--b9b8c";
export var buttonContainer = "styles-module--buttonContainer--a51ae";
export var container = "styles-module--container--b0bd1";
export var desktop = "1340px";
export var disabled = "styles-module--disabled--718df";
export var error = "styles-module--error--78e37";
export var giant = "2200px";
export var hasArrow = "styles-module--hasArrow--e5708";
export var hasIcon = "styles-module--hasIcon--bde73";
export var icon = "styles-module--icon--84d2e";
export var iconContainer = "styles-module--iconContainer--c6e85";
export var input = "styles-module--input--a0ce5";
export var inputContainer = "styles-module--inputContainer--65a50";
export var label = "styles-module--label--707a2";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var password = "styles-module--password--fecb0";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var textarea = "styles-module--textarea--482a2";
export var topText = "styles-module--topText--8b1a1";
export var warning = "styles-module--warning--eefff";
import React, { useEffect, useState } from 'react';
import { ICalculator, IChildcareSubsidyCalculator } from '@mayfield/sanity';
import cn from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import {
  Button,
  LayoutProvider,
  PortableTextRenderer,
  TextInput
} from '@mayfield/common/components';
import { formatDollarAmount } from '@mayfield/common/utils';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import Section from './components/Section';
import Question from './components/Question';
import FormOptionButton from './components/FormOptionButton';
import ChildResults from './components/ChildResults';
import NumberOfChildrenSelector from './components/NumberOfChildrenSelector';
import ChildDetailsForm from './components/ChildDetailsForm';
import calculateSubsidy from './calculateSubsidy';
import * as styles from './styles.module.scss';

interface IProps {
  data: IChildcareSubsidyCalculator;
}

export type TActiveSection = '1. Subsidy' | '2. Children' | '3. Results';

export type TFortnightlyActivity =
  | 'Less than 8 hours'
  | '8-16 hours'
  | '17-48 hours'
  | '48+ hours';

export type TNumberOfChildren = 1 | 2 | 3 | 4 | 5;

export type TDaysPerWeek = 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri';

type TChildData = {
  daysPerWeek: TDaysPerWeek[];
  hoursPerDay: number | null;
  dailyCentreRate: number | null;
};

export type TResultsPeriod = 'Fortnightly' | 'Weekly';

export interface IFormData {
  fortnightlyActivity: TFortnightlyActivity | null;
  annualIncome: number | null;
  numberOfChildren: TNumberOfChildren | null;
  childData: TChildData[];
}

interface IQuestionData {
  fortnightlyActivity: TFortnightlyActivity[];
  numberOfChildren: TNumberOfChildren[];
  daysPerWeek: TDaysPerWeek[];
}

const questionData: IQuestionData = {
  fortnightlyActivity: [
    'Less than 8 hours',
    '8-16 hours',
    '17-48 hours',
    '48+ hours'
  ],
  numberOfChildren: [1, 2, 3, 4, 5],
  daysPerWeek: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri']
};

const resultsPeriods: TResultsPeriod[] = ['Fortnightly', 'Weekly'];

const ChildcareSubsidyCalculator = ({
  data: {
    _rawDisclaimer,
    atfImage,
    header,
    subheader,
    annualIncomeQuestion,
    childrenSectionTitle,
    dailyCentreRateQuestion,
    daysPerWeekQuestion,
    fortnightlyActivityQuestion,
    hoursPerDayQuestion,
    numberOfChildrenQuestion,
    subsidySectionTitle,
    summarySectionTitle
  }
}: IProps) => {
  const calculatorSettings: ICalculator =
    useStaticQuery(calculatorQuery).sanityCalculator;

  const initialFormData: IFormData = {
    fortnightlyActivity: null,
    annualIncome: null,
    numberOfChildren: null,
    childData: []
  };

  const [formData, setFormData] = useState<IFormData>(initialFormData);
  const [activeSection, setActiveSection] =
    useState<TActiveSection>('1. Subsidy');
  const [resultsPeriod, setResultsPeriod] =
    useState<TResultsPeriod>('Fortnightly');

  const resetForm = () => {
    setFormData(initialFormData);
    setActiveSection('1. Subsidy');
  };

  const isSection1Complete =
    formData.fortnightlyActivity && formData.annualIncome;
  const isSection2Complete =
    formData.numberOfChildren &&
    formData.childData?.[0] &&
    formData.childData.every(
      (child) => child.dailyCentreRate && child.hoursPerDay && child.daysPerWeek
    );

  const numberOfChildren = formData.numberOfChildren || 0;
  const numberOfChildrenArray = [...Array(numberOfChildren).fill(null)];

  useEffect(() => {
    if (numberOfChildren === 0) return;

    setFormData((prev) => {
      const prevNumberOfChildren = prev.childData.length;

      let newChildData: TChildData[];

      if (numberOfChildren > prevNumberOfChildren) {
        const newBlankChildData: TChildData[] = Array(
          numberOfChildren - prevNumberOfChildren
        )
          .fill(null)
          .map(() => ({
            dailyCentreRate: null,
            daysPerWeek: [],
            hoursPerDay: null
          }));
        newChildData = [...prev.childData, ...newBlankChildData];
      } else {
        newChildData = prev.childData.slice(0, numberOfChildren);
      }

      return {
        ...prev,
        childData: newChildData
      };
    });
  }, [numberOfChildren]);

  const totalOutOfPocketCosts: () => string = () => {
    let totalFortnightly = 0;
    const { annualIncome, childData, fortnightlyActivity } = formData;

    for (let i = 0; i < numberOfChildren; i++) {
      const { dailyCentreRate, daysPerWeek, hoursPerDay } = childData[i] || {};
      if (
        !fortnightlyActivity ||
        !annualIncome ||
        !hoursPerDay ||
        !dailyCentreRate
      )
        break;

      const { outOfPocketCostsFortnightly } = calculateSubsidy({
        annualIncome,
        calculatorSettings,
        childIndex: i,
        dailyCentreRate,
        daysPerWeek: daysPerWeek.length,
        fortnightlyActivity,
        hoursPerDay
      });

      totalFortnightly += outOfPocketCostsFortnightly;
    }

    if (resultsPeriod === 'Fortnightly') {
      return formatDollarAmount(totalFortnightly);
    }

    return formatDollarAmount(totalFortnightly / 2);
  };

  return (
    <div className={styles.container}>
      <LayoutProvider padding maxWidth className={styles.atf}>
        <div className={styles.imageContainer}>
          <GatsbyImage
            style={{ position: 'absolute', inset: 0 }}
            alt={atfImage.altText || ''}
            image={atfImage.asset.gatsbyImageData}
          />
        </div>

        <LayoutProvider grid>
          <div className={styles.atfText}>
            <h1 className={cn('h1', styles.header)}>{header}</h1>
            <p className={cn('h4')}>{subheader}</p>
          </div>
        </LayoutProvider>
      </LayoutProvider>

      <Section
        section="1. Subsidy"
        title={subsidySectionTitle}
        completed={isSection1Complete ? true : false}
        activeSection={activeSection}
        setActiveSection={setActiveSection}
      >
        {/* Fortnightly activity */}
        <Question {...fortnightlyActivityQuestion} />
        <div className={styles.formInput}>
          {questionData.fortnightlyActivity.map((option, i) => (
            <FormOptionButton
              key={i}
              active={formData.fortnightlyActivity === option}
              text={option}
              onClick={() =>
                setFormData((prev) => ({
                  ...prev,
                  fortnightlyActivity: option
                }))
              }
            />
          ))}
        </div>

        {/* Annual income */}
        <Question {...annualIncomeQuestion} />
        <TextInput
          className={cn(styles.textInput, styles.formInput)}
          iconLeft="dollarSign"
          placeholder="Enter Amount"
          type="number"
          themeColor="grass"
          onChange={(e) =>
            setFormData((prev) => ({ ...prev, annualIncome: parseInt(e) }))
          }
          value={formData.annualIncome?.toString() || ''}
        />

        <div className={styles.buttons}>
          <Button
            disabled={!isSection1Complete}
            color="grass"
            onClick={() => setActiveSection('2. Children')}
          >
            Save
          </Button>
        </div>
      </Section>

      <Section
        title={childrenSectionTitle}
        completed={isSection2Complete ? true : false}
        activeSection={activeSection}
        setActiveSection={setActiveSection}
        section="2. Children"
      >
        {/* Number of children */}
        <Question {...numberOfChildrenQuestion} />
        <div className={styles.formInput}>
          <NumberOfChildrenSelector
            formData={formData}
            options={questionData.numberOfChildren}
            setFormData={setFormData}
          />
        </div>

        <div>
          {numberOfChildrenArray.map((_, i) => (
            <ChildDetailsForm
              dailyCentreRateQuestion={dailyCentreRateQuestion}
              formData={formData}
              daysPerWeekQuestion={daysPerWeekQuestion}
              hoursPerDayQuestion={hoursPerDayQuestion}
              index={i}
              setFormData={setFormData}
              daysPerWeekOptions={questionData.daysPerWeek}
              numberOfChildren={numberOfChildren}
              key={i}
            />
          ))}
        </div>

        <div className={styles.buttons}>
          <Button
            disabled={!isSection2Complete}
            color="grass"
            onClick={() => setActiveSection('3. Results')}
          >
            Save
          </Button>

          <Button
            color="grass"
            variant="secondary"
            onClick={() => setActiveSection('1. Subsidy')}
          >
            Back
          </Button>
        </div>
      </Section>

      {/* Results */}
      <Section
        section="3. Results"
        title={summarySectionTitle}
        activeSection={activeSection}
        setActiveSection={setActiveSection}
      >
        <div className={styles.formInput}>
          {resultsPeriods.map((period, i) => (
            <FormOptionButton
              text={period}
              key={i}
              onClick={() => setResultsPeriod(period)}
              active={resultsPeriod === period}
            />
          ))}
        </div>

        {numberOfChildrenArray.map((_, i) => (
          <ChildResults
            index={i}
            key={i}
            resultsPeriod={resultsPeriod}
            formData={formData}
            calculatorSettings={calculatorSettings}
          />
        ))}

        <div className={styles.total}>
          <h3 className={cn('h3', styles.totalTitle)}>Grand Total</h3>

          <div className={cn('h3', styles.youPay)}>You pay</div>

          <div className={styles.totalResults}>
            <div className={cn('b1')}>
              Per {resultsPeriod === 'Fortnightly' ? 'fortnight' : 'week'}
            </div>

            <div className={cn('h4')}>{totalOutOfPocketCosts()}</div>
          </div>

          <Button onClick={resetForm} variant="secondary" color="grass">
            Reset form
          </Button>
        </div>
      </Section>

      <LayoutProvider padding maxWidth grid>
        <PortableTextRenderer
          rawText={_rawDisclaimer}
          containerClassName={styles.disclaimer}
          gatsbyImageComponent={GatsbyImage}
          getGatsbyImageData={getGatsbyImageData}
        />
      </LayoutProvider>
    </div>
  );
};

export default ChildcareSubsidyCalculator;

const calculatorQuery = graphql`
  query CalculatorSettingsQuery {
    sanityCalculator {
      receivePercentage
      incomeToCCSPercentageAdditionalChildren {
        totalFamilyIncome
        percentage
      }
      incomeToCCSPercentage1Child {
        totalFamilyIncome
        percentage
      }
      hourlyRateCap
      activityLevel {
        moreThan48
        lessThan8
        between8and16
        between17and48
      }
    }
  }
`;

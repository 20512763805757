import React from 'react';
import { IHomepageOverview } from '@mayfield/sanity';
import {
  AnimatedGraphic,
  IntersectionAnimation,
  LayoutProvider,
  SVG,
  UniversalLink
} from '@mayfield/common/components';
import cn from 'classnames';
import { ChalkDrawImage } from '@mayfield/website/components';
import { useIntersectionAnimation } from '@mayfield/common/hooks';
import flowerVideo from './assets/flower.mp4';
import starVideo from './assets/star.mp4';
import ticTacToeVideo from './assets/tic-tac-toe.mp4';
import * as styles from './styles.module.scss';

interface IProps {
  data: IHomepageOverview;
}

const HomepageOverview = ({
  data: {
    title,
    description,
    cta,
    block1Text,
    block1Title,
    block1Image,
    block2Text,
    block2Title,
    block2Image,
    block3Text,
    block3Title,
    block3Image
  }
}: IProps) => {
  const { ref: refIntro, inView: inViewIntro } = useIntersectionAnimation();
  const { ref: ref1, inView: inView1 } = useIntersectionAnimation();
  const { ref: ref2, inView: inView2 } = useIntersectionAnimation();
  const { ref: ref3, inView: inView3 } = useIntersectionAnimation();

  return (
    <div className={styles.container}>
      <LayoutProvider grid padding maxWidth className={styles.content}>
        <div className={styles.topBlock}>
          <AnimatedGraphic className={styles.flowerVideo} url={flowerVideo} />
          <SVG svg="arrowChalk1" className={styles.chalkArrow} />

          <div className={styles.blockContent}>
            <IntersectionAnimation trigger={inViewIntro} delay={200}>
              <h2 className={cn('h1', styles.title)} ref={refIntro}>
                {title}
              </h2>
            </IntersectionAnimation>

            <IntersectionAnimation trigger={inViewIntro} delay={400}>
              <p className={cn('h2', styles.description)}>{description}</p>
            </IntersectionAnimation>

            <IntersectionAnimation trigger={inViewIntro} delay={600}>
              <UniversalLink link={cta} color="floral" />
            </IntersectionAnimation>
          </div>
        </div>

        <div className={styles.block1}>
          <AnimatedGraphic className={styles.starVideo} url={starVideo} />

          <div className={styles.blockContent}>
            <ChalkDrawImage image={block1Image} className={styles.image} />

            <IntersectionAnimation trigger={inView1} delay={200}>
              <h3 ref={ref1} className={cn('h2', styles.blockTitle)}>
                {block1Title}
              </h3>
            </IntersectionAnimation>
            <IntersectionAnimation trigger={inView1} delay={400}>
              <p className={cn('b1', styles.blockText)}>{block1Text}</p>
            </IntersectionAnimation>
          </div>
        </div>

        <div className={styles.block2}>
          <SVG svg="arrowChalk1" className={styles.chalkArrow1} />
          <SVG svg="arrowChalk2" className={styles.chalkArrow2} />

          <ChalkDrawImage image={block2Image} className={styles.image} />

          <IntersectionAnimation trigger={inView2} delay={200}>
            <h3 ref={ref2} className={cn('h2', styles.blockTitle)}>
              {block2Title}
            </h3>
          </IntersectionAnimation>
          <IntersectionAnimation trigger={inView2} delay={400}>
            <p className={cn('b1', styles.blockText)}>{block2Text}</p>
          </IntersectionAnimation>
        </div>

        <div className={styles.block3}>
          <AnimatedGraphic
            className={styles.ticTacToeVideo}
            url={ticTacToeVideo}
          />

          <div className={styles.blockContent}>
            <ChalkDrawImage image={block3Image} className={styles.image} />

            <IntersectionAnimation trigger={inView3} delay={200}>
              <h3 ref={ref3} className={cn('h2', styles.blockTitle)}>
                {block3Title}
              </h3>
            </IntersectionAnimation>
            <IntersectionAnimation trigger={inView3} delay={400}>
              <p className={cn('b1', styles.blockText)}>{block3Text}</p>
            </IntersectionAnimation>
          </div>
        </div>
      </LayoutProvider>
    </div>
  );
};

export default HomepageOverview;

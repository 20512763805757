// extracted by mini-css-extract-plugin
export var active = "styles-module--active--fae6f";
export var andMore = "styles-module--andMore--01b9e";
export var centreName = "styles-module--centreName--90fad";
export var centreNames = "styles-module--centreNames--2093b";
export var container = "styles-module--container--1d298";
export var desktop = "1340px";
export var flowerImage = "styles-module--flowerImage--1daef";
export var flowerImageContainer = "styles-module--flowerImageContainer--3ba57";
export var giant = "2200px";
export var grid = "styles-module--grid--a18e0";
export var header = "styles-module--header--c3fa7";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var textContent = "styles-module--textContent--66811";
// extracted by mini-css-extract-plugin
export var atf = "styles-module--atf--fdbdd";
export var atfText = "styles-module--atfText--11a05";
export var buttons = "styles-module--buttons--e90cb";
export var container = "styles-module--container--bdf6e";
export var desktop = "1340px";
export var disclaimer = "styles-module--disclaimer--01089";
export var formInput = "styles-module--formInput--874b2";
export var giant = "2200px";
export var header = "styles-module--header--11ff5";
export var imageContainer = "styles-module--imageContainer--07534";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var textInput = "styles-module--textInput--6c955";
export var total = "styles-module--total--4c476";
export var totalResults = "styles-module--totalResults--b233e";
export var totalTitle = "styles-module--totalTitle--bf925";
export var youPay = "styles-module--youPay--67e16";
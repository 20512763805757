// extracted by mini-css-extract-plugin
export var accordion = "styles-module--accordion--7ab48";
export var button = "styles-module--button--0c223";
export var chevronIcon = "styles-module--chevronIcon--9a9dc";
export var container = "styles-module--container--ec03c";
export var desktop = "1340px";
export var disabled = "styles-module--disabled--193a9";
export var expanded = "styles-module--expanded--225ed";
export var giant = "2200px";
export var groupTitle = "styles-module--groupTitle--10f1a";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var option = "styles-module--option--3c4c5";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
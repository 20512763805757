import React from 'react';
import { IStaggeredImageText } from '@mayfield/sanity';
import { LayoutProvider } from '@mayfield/common/components';
import * as styles from './styles.module.scss';
import TopicSection from './components/TopicSection';

interface IProps {
  data: IStaggeredImageText;
}

const StaggeredImageText = ({
  data: { image1, image2, image3, text1, text2, text3, title1, title2, title3 }
}: IProps) => {
  return (
    <div className={styles.container}>
      <LayoutProvider grid maxWidth padding>
        <TopicSection
          image={image1}
          text={text1}
          title={title1}
          className={styles.topic1}
        />

        <TopicSection
          image={image2}
          text={text2}
          title={title2}
          className={styles.topic2}
          imageOnLeft
        />

        <TopicSection
          image={image3}
          text={text3}
          title={title3}
          className={styles.topic3}
        />
      </LayoutProvider>
    </div>
  );
};

export default StaggeredImageText;

// extracted by mini-css-extract-plugin
export var bottomText = "styles-module--bottomText--a1130";
export var container = "styles-module--container--8ea9e";
export var corporateLink = "styles-module--corporateLink--8f3ec";
export var corporateLinkContainer = "styles-module--corporateLinkContainer--1a25f";
export var desktop = "1340px";
export var footerText = "styles-module--footerText--f7843";
export var giant = "2200px";
export var internalLinks = "styles-module--internalLinks--436e1";
export var lamLink = "styles-module--lamLink--b0ca4";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var letters = "styles-module--letters--bb247";
export var lettersDesktop = "styles-module--lettersDesktop--103c2";
export var lettersMobile = "styles-module--lettersMobile--30aed";
export var linkItem = "styles-module--linkItem--92f77";
export var listHeader = "styles-module--listHeader--a377d";
export var locations = "styles-module--locations--0b32f";
export var mobile = "400px";
export var nav = "styles-module--nav--733f1";
export var singleColumnContent = "styles-module--singleColumnContent--f6a50";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var socials = "styles-module--socials--743b0";
export var tablet = "769px";
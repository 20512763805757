import React, { useEffect, useState } from 'react';
import { SVG, TSvg } from '@mayfield/common/components';
import cn from 'classnames';
import { animated, useSpring } from 'react-spring';
import * as styles from './styles.module.scss';

interface IProps {
  className?: string;
}

const DesktopLetters = ({ className }: IProps) => {
  return (
    <div className={cn(className, styles.ratioProvider)}>
      <div className={styles.content}>
        <DesktopLetter svg="letterM" flex={1.7} className={styles.letterM} />
        <DesktopLetter svg="letterA" flex={1} className={styles.letterA} />
        <DesktopLetter svg="letterY" flex={1} className={styles.letterY} />
        <DesktopLetter svg="letterFI" flex={1.05} className={styles.letterFI} />
        <DesktopLetter svg="letterE" flex={1} className={styles.letterE} />
        <DesktopLetter svg="letterL" flex={0.55} className={styles.letterL} />
        <DesktopLetter svg="letterD" flex={1.2} className={styles.letterD} />
      </div>
    </div>
  );
};

export default DesktopLetters;

interface IDesktopLetter {
  svg: TSvg;
  flex: number;
  className: string;
}

const DesktopLetter = ({ svg, flex, className }: IDesktopLetter) => {
  const [isHovered, setIsHovered] = useState(false);
  const [style, set] = useSpring(() => ({
    y: 0
  }));

  const animationConfig = {
    tension: 200,
    friction: isHovered ? 10 : 20
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    if (isHovered) {
      set({
        y: -100,
        config: animationConfig
      });
    } else {
      set({
        y: 0,
        config: animationConfig
      });
    }
  }, [isHovered]);

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={styles.hoverArea}
      style={{ flex }}
    >
      <animated.div
        className={styles.letterContainer}
        style={{
          transform: style.y.to((y) => `translateY(${y}px)`)
        }}
      >
        <SVG className={cn(styles.letter, className)} svg={svg} />
      </animated.div>
    </div>
  );
};

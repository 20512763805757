// extracted by mini-css-extract-plugin
export var desktop = "1340px";
export var drawing = "styles-module--drawing--7ee5c";
export var drawing1a = "styles-module--drawing1a--f2de6";
export var drawing1b = "styles-module--drawing1b--5ce9a";
export var drawing2a = "styles-module--drawing2a--f9b56";
export var drawing2b = "styles-module--drawing2b--3fe9d";
export var drawing3a = "styles-module--drawing3a--a6770";
export var drawing3b = "styles-module--drawing3b--a0263";
export var drawing3c = "styles-module--drawing3c--02b12";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
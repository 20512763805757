import React from 'react';
import cn from 'classnames';
import { SVG } from '@mayfield/common/components';
import * as styles from './styles.module.scss';

interface IProps {
  isActive: boolean;
  onClick: () => void;
  className?: string;
}

const HamburgerButton = ({ isActive, onClick, className }: IProps) => {
  return (
    <div className={cn(className)}>
      <button className={styles.button} onClick={onClick}>
        {isActive ? (
          <SVG className={cn(styles.icon, styles.x)} svg="x" />
        ) : (
          <SVG className={cn(styles.icon, styles.hamburger)} svg="hamburger" />
        )}
      </button>
    </div>
  );
};

export default HamburgerButton;

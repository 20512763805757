import React from 'react';
import cn from 'classnames';
import { ICalculator } from '@mayfield/sanity';
import { formatDollarAmount } from '@mayfield/common/utils';
import { IFormData, TResultsPeriod } from '../..';
import calculateSubsidy from '../../calculateSubsidy';
import * as styles from './styles.module.scss';

interface IProps {
  index: number;
  formData: IFormData;
  resultsPeriod: TResultsPeriod;
  calculatorSettings: ICalculator;
}

const ChildResults = ({
  index,
  resultsPeriod,
  formData,
  calculatorSettings
}: IProps) => {
  const { annualIncome, childData, fortnightlyActivity } = formData;

  const { dailyCentreRate, daysPerWeek, hoursPerDay } = childData[index] || {};

  if (!fortnightlyActivity || !annualIncome || !hoursPerDay || !dailyCentreRate)
    return null;

  const {
    childcareFeesFortnightly,
    estimatedCCSFortnightly,
    outOfPocketCostsFortnightly
  } = calculateSubsidy({
    annualIncome,
    calculatorSettings,
    childIndex: index,
    dailyCentreRate,
    daysPerWeek: daysPerWeek.length,
    hoursPerDay,
    fortnightlyActivity
  });

  const getTotalFees = () => {
    if (resultsPeriod === 'Fortnightly') {
      return formatDollarAmount(childcareFeesFortnightly);
    }
    return formatDollarAmount(childcareFeesFortnightly / 2);
  };

  const getEstimatedSubsidy = () => {
    if (resultsPeriod === 'Fortnightly') {
      return formatDollarAmount(estimatedCCSFortnightly);
    }
    return formatDollarAmount(estimatedCCSFortnightly / 2);
  };

  const getEstimatedOutOfPocketCosts = () => {
    if (resultsPeriod === 'Fortnightly') {
      return formatDollarAmount(outOfPocketCostsFortnightly);
    }
    return formatDollarAmount(outOfPocketCostsFortnightly / 2);
  };

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <h3 className={cn('h4')}>Child {index + 1}</h3>
      </div>

      <div className={styles.results}>
        <div className={styles.result}>
          <div>
            <div className={cn('b1')}>Total childcare fees</div>
            <div className={cn('caption')}>
              (Per {resultsPeriod.toLowerCase()} average)
            </div>
          </div>

          <div className={cn('h4')}>{getTotalFees()}</div>
        </div>

        <div className={styles.result}>
          <div>
            <div className={cn('b1')}>Estimated childcare subsidy</div>
            <div className={cn('caption')}>
              (Per {resultsPeriod.toLowerCase()} average)
            </div>
          </div>

          <div className={cn('h4')}>{getEstimatedSubsidy()}</div>
        </div>

        <div className={styles.result}>
          <div>
            <div className={cn('b1')}>Estimated out of pocket cost</div>
            <div className={cn('caption')}>
              (Per {resultsPeriod.toLowerCase()} average)
            </div>
          </div>

          <div className={cn('h4')}>{getEstimatedOutOfPocketCosts()}</div>
        </div>
      </div>
    </div>
  );
};

export default ChildResults;

import React from 'react';
import * as styles from './styles.module.scss';
import { useApp } from '@mayfield/website/hooks';
import { LayoutProvider, SVG } from '@mayfield/common/components';
import Drawings from './components/Drawings';

const Modal = () => {
  const { modalContent, setModalContent } = useApp();

  const closeModal = () => {
    setModalContent(null);
  };

  if (!modalContent) return null;

  return (
    <div className={styles.container}>
      <div className={styles.backgroundButton} onClick={closeModal} />

      <LayoutProvider className={styles.grid} grid maxWidth padding>
        <div className={styles.buttonContainer}>
          <button onClick={closeModal} className={styles.closeModalButton}>
            <SVG svg="x" className={styles.xIcon} />
          </button>
        </div>

        <div className={styles.modalContentContainer}>
          <Drawings />

          <div className={styles.modalContent}>{modalContent}</div>
        </div>
      </LayoutProvider>
    </div>
  );
};

export default Modal;

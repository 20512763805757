// extracted by mini-css-extract-plugin
export var container = "styles-module--container--6ae60";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var letterA = "styles-module--letterA--9ed16";
export var letterContainer = "styles-module--letterContainer--9847b";
export var letterContainerA = "styles-module--letterContainerA--40731";
export var letterContainerD = "styles-module--letterContainerD--d1a16";
export var letterContainerE = "styles-module--letterContainerE--29e40";
export var letterContainerFI = "styles-module--letterContainerFI--9cb15";
export var letterContainerL = "styles-module--letterContainerL--174bb";
export var letterContainerM = "styles-module--letterContainerM--32cb5";
export var letterContainerY = "styles-module--letterContainerY--a7d69";
export var letterD = "styles-module--letterD--1360e";
export var letterE = "styles-module--letterE--2a2f5";
export var letterFI = "styles-module--letterFI--cba03";
export var letterL = "styles-module--letterL--5716a";
export var letterM = "styles-module--letterM--db135";
export var letterY = "styles-module--letterY--104d3";
export var letters = "styles-module--letters--3c880";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
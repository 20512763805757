import React from 'react';
import * as Slices from '@mayfield/website/slices';
import { ISliceConfig } from '@mayfield/sanity';
import cn from 'classnames';
import * as styles from './styles.module.scss';

interface IProps {
  data: {
    _type: string;
    sliceConfig?: ISliceConfig;
  };
  sliceKey: string;
  id?: string;
}

const SanitySlice = (props: IProps) => {
  const { data, id, sliceKey } = props;
  const { _type: sliceType } = data;

  if (!sliceType) {
    console.error(
      `SanitySlice requires a valid _type, but none was received. Check the /templates/page file to ensure a valid slice type is being passed to the SanitySlice component.`
    );

    return null;
  }

  const sliceName =
    sliceType[0].toUpperCase() + sliceType.slice(1, sliceType.length);

  const SliceSection = (Slices as any)?.[sliceName];

  if (!SliceSection) {
    console.error(
      `Can't find Slice '${sliceName}', are you sure it has been configured in the slices directory?`
    );

    return null;
  }

  const bottomBorder = !data.sliceConfig?.disableBottomBorder;

  return (
    <section
      data-key={sliceKey}
      id={id}
      className={cn({
        [styles.bottomBorder]: bottomBorder
      })}
    >
      {/* @ts-ignore */}
      <SliceSection {...props} />
    </section>
  );
};

export default SanitySlice;

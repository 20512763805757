import React from 'react';
import { IResponsiveImage } from '@mayfield/sanity';
import cn from 'classnames';
import {
  useBreakpoints,
  useIntersectionAnimation
} from '@mayfield/common/hooks';
import {
  IntersectionAnimation,
  LayoutProvider,
  ResponsiveImage
} from '@mayfield/common/components';
import * as styles from './styles.module.scss';

interface IProps {
  image: IResponsiveImage;
  title: string;
  text: string;
  className?: string;
  imageOnLeft?: boolean;
}

const TopicSection = ({
  image,
  text,
  title,
  className,
  imageOnLeft
}: IProps) => {
  const { smallDesktop } = useBreakpoints();
  const { ref, inView } = useIntersectionAnimation();

  return (
    <div className={cn(className, { [styles.imageOnLeft]: imageOnLeft })}>
      <LayoutProvider className={styles.grid} grid={!smallDesktop}>
        <IntersectionAnimation
          delay={200}
          animation="fadeGrow"
          className={styles.image}
        >
          <ResponsiveImage imageData={image} breakpoint="smallDesktop" />
        </IntersectionAnimation>

        <div className={styles.textContent} ref={ref}>
          <IntersectionAnimation trigger={inView} delay={300}>
            <h3 className={cn('h2', styles.title)}>{title}</h3>
          </IntersectionAnimation>

          <IntersectionAnimation trigger={inView} delay={500}>
            <p className={cn('b2')}>{text}</p>
          </IntersectionAnimation>
        </div>
      </LayoutProvider>
    </div>
  );
};

export default TopicSection;

import React, { useState } from 'react';
import { ICentrePreview } from '@mayfield/sanity';
import {
  IntersectionAnimation,
  LayoutProvider,
  UniversalLink
} from '@mayfield/common/components';
import { FlowerMaskImage } from '@mayfield/website/components';
import {
  useInterval,
  useBreakpoints,
  useIntersectionAnimation
} from '@mayfield/common/hooks';
import cn from 'classnames';
import * as styles from './styles.module.scss';

interface IProps {
  data: ICentrePreview;
}

const CentrePreview = ({ data: { centres, cta, headerText } }: IProps) => {
  const [activeCentreIndex, setActiveCentreIndex] = useState(0);
  const [isAnimationActive, setIsAnimationActive] = useState(true);

  const ANIMATION_SPEED_MS = 2000;

  const { smallTablet } = useBreakpoints();

  const updateActiveCentreIndex = () => {
    setActiveCentreIndex((prev) => (prev + 1) % centres.length);
  };

  useInterval(
    updateActiveCentreIndex,
    isAnimationActive ? ANIMATION_SPEED_MS : null
  );

  const handleMouseEnterList = () => {
    if (!smallTablet) return;
    setIsAnimationActive(false);
  };

  const handleMouseLeaveList = () => {
    setIsAnimationActive(true);
  };

  const handleMouseEnterItem = (index: number) => {
    if (!smallTablet) return;
    setActiveCentreIndex(index);
  };

  const { inView, ref } = useIntersectionAnimation();

  return (
    <div className={styles.container}>
      <LayoutProvider maxWidth padding>
        <div className={styles.grid}>
          <div className={styles.flowerImageContainer}>
            {centres.map((centre, i) => (
              <div
                key={i}
                className={cn(styles.flowerImage, {
                  [styles.active]: activeCentreIndex === i
                })}
              >
                <FlowerMaskImage image={centre.image} />
              </div>
            ))}
          </div>

          <div className={styles.textContent}>
            <IntersectionAnimation
              trigger={inView}
              animation="fadeGrow"
              delay={200}
            >
              <h2 ref={ref} className={cn('d1', styles.header)}>
                {headerText}
              </h2>
            </IntersectionAnimation>

            <IntersectionAnimation
              trigger={inView}
              animation="fadeGrow"
              delay={400}
            >
              <p
                className={cn('h4', styles.centreNames)}
                onMouseEnter={handleMouseEnterList}
                onMouseLeave={handleMouseLeaveList}
              >
                {centres.map(({ name }, i) => (
                  <span
                    onMouseEnter={() => handleMouseEnterItem(i)}
                    className={cn(styles.centreName, {
                      [styles.active]: activeCentreIndex === i
                    })}
                    key={i}
                  >
                    {name}
                  </span>
                ))}
                <span className={styles.andMore}>and more...</span>
              </p>
            </IntersectionAnimation>

            <IntersectionAnimation
              trigger={inView}
              animation="fadeGrow"
              delay={600}
            >
              <UniversalLink link={cta} theme="lightOnDark" />
            </IntersectionAnimation>
          </div>
        </div>
      </LayoutProvider>
    </div>
  );
};

export default CentrePreview;

import React from 'react';
import { Button, LayoutProvider } from '@mayfield/common/components';
import { TThemeColor } from '@mayfield/sanity';
import { useBreakpoints } from '@mayfield/common/hooks';
import * as styles from './styles.module.scss';

export interface INavOption {
  title: string;
  sectionId: string;
}

interface IProps {
  themeColor: TThemeColor;
  navOptions: INavOption[];
}

const PageNav = ({ themeColor, navOptions }: IProps) => {
  const { largeTablet } = useBreakpoints();

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

  const scrollToId = (id: string) => {
    const domNode = document.getElementById(id);
    if (!domNode) return;

    const headerHeight = largeTablet ? 56 : 52;
    const offsetHeight = headerHeight * 2;

    const distanceFromTopOfDocument =
      window.scrollY + domNode.getBoundingClientRect().top;

    window.scrollTo({
      top: distanceFromTopOfDocument - offsetHeight,
      behavior: 'smooth'
    });
  };

  return (
    <nav
      className={styles.container}
      style={{ background: `var(--color-${themeColor}-mid)` }}
    >
      <LayoutProvider padding className={styles.content}>
        <Button
          className={styles.backToTopButton}
          variant="text"
          theme="lightOnDark"
          onClick={scrollToTop}
          color={themeColor}
        >
          Back to Top
        </Button>

        <ul className={styles.links}>
          {navOptions.map((navLink, i) => (
            <li className={styles.linkListItem} key={i}>
              <Button
                color={themeColor}
                variant="secondary"
                theme="lightOnDark"
                onClick={() => scrollToId(navLink.sectionId)}
              >
                {navLink.title}
              </Button>
            </li>
          ))}
        </ul>
      </LayoutProvider>
    </nav>
  );
};

export default PageNav;

// ! Note
// This component needs to be wrapped with <LoadScript> to work
// See implementation in CentreSearch slice

import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { LayoutProvider, TextInput } from '@mayfield/common/components';
import { Autocomplete } from '@react-google-maps/api';
import { TThemeColor } from '@mayfield/sanity';
import * as styles from './styles.module.scss';

interface IProps {
  setSearchedPlace: React.Dispatch<
    React.SetStateAction<google.maps.places.PlaceResult | undefined>
  >;
  className?: string;
  placeholder?: string;
  themeColor: TThemeColor;
  defaultSearch?: string;
}

const MapAutocompleteSearch = ({
  setSearchedPlace,
  placeholder,
  themeColor = 'sea',
  className,
  defaultSearch
}: IProps) => {
  const [input, setInput] = useState(defaultSearch);
  const [autocompleteData, setAutocompleteData] =
    useState<google.maps.places.Autocomplete>();

  useEffect(() => {
    if (!input) {
      setSearchedPlace(undefined);
    }
  }, [input, setSearchedPlace]);

  return (
    <LayoutProvider grid className={cn(styles.container, className)}>
      <Autocomplete
        className={styles.autocomplete}
        types={['postal_code', 'locality', 'administrative_area_level_1']}
        options={{
          componentRestrictions: { country: 'au' }
        }}
        onLoad={(autocomplete) => setAutocompleteData(autocomplete)}
        onPlaceChanged={() => {
          setSearchedPlace(autocompleteData?.getPlace());
          setInput(autocompleteData?.getPlace().formatted_address || '');
        }}
      >
        <TextInput
          placeholder={placeholder}
          themeColor={themeColor}
          onChange={setInput}
          value={input}
        />
      </Autocomplete>
    </LayoutProvider>
  );
};

export default MapAutocompleteSearch;

// extracted by mini-css-extract-plugin
export var button = "styles-module--button--cf675";
export var content = "styles-module--content--5e14b";
export var earth = "styles-module--earth--7442e";
export var floral = "styles-module--floral--082de";
export var fluid = "styles-module--fluid--cc6cc";
export var grass = "styles-module--grass--4b46a";
export var icon = "styles-module--icon--02218";
export var inverted = "styles-module--inverted--c8f83";
export var loader = "styles-module--loader--ccdfe";
export var loaderContainer = "styles-module--loaderContainer--180ea";
export var loading = "styles-module--loading--c75de";
export var primary = "styles-module--primary--3470b";
export var secondary = "styles-module--secondary--5e0e9";
export var sky = "styles-module--sky--e4c94";
export var spin = "styles-module--spin--bd9eb";
export var text = "styles-module--text--14a3d";
export var textBig = "styles-module--textBig--c750a";
// extracted by mini-css-extract-plugin
export var container = "styles-module--container--ebb44";
export var desktop = "1340px";
export var dropdownOptionContainer = "styles-module--dropdownOptionContainer--de2cd";
export var dropdownOptions = "styles-module--dropdownOptions--7821c";
export var giant = "2200px";
export var headerHeight = "56px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mainButtonContainer = "styles-module--mainButtonContainer--06750";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
// extracted by mini-css-extract-plugin
export var carouselContainer = "styles-module--carouselContainer--a4255";
export var carouselContent = "styles-module--carouselContent--9510c";
export var container = "styles-module--container--c9c78";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var nonCarouselContent = "styles-module--nonCarouselContent--d5ec6";
export var paginationContainer = "styles-module--paginationContainer--9baab";
export var slide = "styles-module--slide--4c2aa";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var title = "styles-module--title--51001";
import React, { useEffect } from 'react';
import { SVG } from '@mayfield/common/components';
import { useAccordion } from '@mayfield/common/hooks';
import cn from 'classnames';
import * as styles from './styles.module.scss';

export interface IDropdownOption {
  label: string;
  value: string;
}

export interface IDropdownOptionGroup {
  title: string;
  options: IDropdownOption[];
}

interface IProps {
  placeholder: string;
  activeOption: IDropdownOption;
  setActiveOption: React.Dispatch<React.SetStateAction<IDropdownOption>>;
  options: IDropdownOption[];
  onClickOption?: () => void;
  optionGroups?: IDropdownOptionGroup[];
  disabled?: boolean;
}

const Dropdown = ({
  placeholder,
  activeOption,
  setActiveOption,
  options,
  onClickOption,
  optionGroups,
  disabled
}: IProps) => {
  const {
    containerHeight,
    contentRef,
    isExpanded,
    setIsExpanded,
    calculateContentHeight
  } = useAccordion();

  const handleClickOption = (option: IDropdownOption) => {
    setActiveOption(option);
    setIsExpanded(false);
    onClickOption && onClickOption();
  };

  useEffect(() => {
    setIsExpanded(false);
  }, [activeOption, setIsExpanded]);

  useEffect(() => {
    calculateContentHeight();
  }, [options, calculateContentHeight]);

  return (
    <div
      className={cn('b2', styles.container, {
        [styles.expanded]: isExpanded,
        [styles.disabled]: disabled
      })}
    >
      <button
        className={styles.button}
        disabled={disabled}
        onClick={() => setIsExpanded((prev) => !prev)}
      >
        <span>{activeOption.label || placeholder}</span>
        {<SVG className={styles.chevronIcon} svg="chevronDown" />}
      </button>
      <div className={styles.accordion} style={{ height: containerHeight }}>
        <div ref={contentRef}>
          <ul>
            {options.map((option) => (
              <li key={option.value}>
                <button
                  onClick={() => handleClickOption(option)}
                  disabled={!isExpanded || disabled}
                  className={styles.option}
                >
                  {option.label}
                </button>
              </li>
            ))}

            {optionGroups?.map((group) => (
              <div key={group.title}>
                <div className={cn('caption', styles.groupTitle)}>
                  {group.title}
                </div>

                <ul>
                  {group.options.map((option) => (
                    <li key={option.value}>
                      <button
                        onClick={() => handleClickOption(option)}
                        disabled={!isExpanded || disabled}
                        className={styles.option}
                      >
                        {option.label}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Dropdown;

import React from 'react';
import { IStaff } from '@mayfield/sanity';
import { graphql, useStaticQuery } from 'gatsby';
import cn from 'classnames';
import {
  IntersectionAnimation,
  LayoutProvider
} from '@mayfield/common/components';
import { useIntersectionAnimation } from '@mayfield/common/hooks';
import * as styles from './styles.module.scss';
import StaffMemberTile from './components/StaffMemberTile';

const StaffGallery = () => {
  const {
    sanityStaff: { management, operations }
  }: IQueryData = useStaticQuery(query);

  const { ref: refManagement, inView: inViewManagement } =
    useIntersectionAnimation();
  const { ref: refOperations, inView: inViewOperations } =
    useIntersectionAnimation();

  return (
    <div className={styles.container}>
      {management?.[0] && (
        <LayoutProvider maxWidth grid padding className={styles.team}>
          <IntersectionAnimation className={styles.title}>
            <h2 ref={refManagement} className={cn('h1')}>
              Our Management Team
            </h2>
          </IntersectionAnimation>

          {management.map((staffMember, i) => (
            <IntersectionAnimation
              key={i}
              trigger={inViewManagement}
              className={styles.staffMemberTile}
              delay={200 + 150 * i}
            >
              <StaffMemberTile {...staffMember} index={i} />
            </IntersectionAnimation>
          ))}
        </LayoutProvider>
      )}

      {operations?.[0] && (
        <LayoutProvider maxWidth grid padding className={styles.team}>
          <IntersectionAnimation className={styles.title}>
            <h2 ref={refOperations} className={cn('h1')}>
              Operations and Compliance Team
            </h2>
          </IntersectionAnimation>

          {operations.map((staffMember, i) => (
            <IntersectionAnimation
              key={i}
              trigger={inViewOperations}
              className={styles.staffMemberTile}
              delay={200 + 150 * i}
            >
              <StaffMemberTile {...staffMember} index={i} />
            </IntersectionAnimation>
          ))}
        </LayoutProvider>
      )}
    </div>
  );
};

export default StaffGallery;

/**
 * GraphQL ====================================================================
 */

interface IQueryData {
  sanityStaff: IStaff;
}

const query = graphql`
  query {
    sanityStaff {
      management {
        ...StaffMemberFragment
      }
      operations {
        ...StaffMemberFragment
      }
    }
  }
`;

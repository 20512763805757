import React from 'react';
import { IAboutOverview } from '@mayfield/sanity';
import {
  AnimatedGraphic,
  IntersectionAnimation,
  LayoutProvider,
  PortableTextRenderer,
  SVG
} from '@mayfield/common/components';
import { ChalkDrawImage } from '@mayfield/website/components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import { useIntersectionAnimation } from '@mayfield/common/hooks';
import cn from 'classnames';
import pencilVideo from './assets/pencil.mp4';
import girlVideo from './assets/girl.mp4';
import houseVideo from './assets/house.mp4';
import * as styles from './styles.module.scss';

interface IProps {
  data: IAboutOverview;
}

const AboutOverview = ({
  data: {
    title,
    description,
    _rawBlock1Text,
    _rawBlock2Text,
    _rawBlock3Text,
    _rawBlock4Text,
    block1Title,
    block2Title,
    block3Title,
    block4Title,
    block1Image,
    block2Image,
    block3Image,
    block4Image
  }
}: IProps) => {
  const { ref: refIntro, inView: inViewIntro } = useIntersectionAnimation();
  const { ref: ref1, inView: inView1 } = useIntersectionAnimation();
  const { ref: ref2, inView: inView2 } = useIntersectionAnimation();
  const { ref: ref3, inView: inView3 } = useIntersectionAnimation();
  const { ref: ref4, inView: inView4 } = useIntersectionAnimation();

  return (
    <div className={styles.container}>
      <LayoutProvider grid padding maxWidth className={styles.content}>
        <div className={styles.topBlock}>
          <AnimatedGraphic className={styles.pencilVideo} url={pencilVideo} />
          <SVG svg="arrowChalk1" className={styles.chalkArrow} />

          <div className={styles.blockContent}>
            <IntersectionAnimation trigger={inViewIntro} delay={200}>
              <h2 ref={refIntro} className={cn('h1', styles.title)}>
                {title}
              </h2>
            </IntersectionAnimation>

            <IntersectionAnimation trigger={inViewIntro} delay={400}>
              <p className={cn('h2', styles.description)}>{description}</p>
            </IntersectionAnimation>
          </div>
        </div>

        <div className={styles.block1}>
          <div className={styles.blockContent}>
            <ChalkDrawImage image={block1Image} className={styles.image} />

            <IntersectionAnimation trigger={inView1} delay={200}>
              <h3 ref={ref1} className={cn('h2', styles.blockTitle)}>
                {block1Title}
              </h3>
            </IntersectionAnimation>

            <IntersectionAnimation trigger={inView1} delay={400}>
              <PortableTextRenderer
                gatsbyImageComponent={GatsbyImage}
                getGatsbyImageData={getGatsbyImageData}
                rawText={_rawBlock1Text}
                containerClassName={styles.blockText}
                textClassName="b2"
              />
            </IntersectionAnimation>
          </div>
        </div>

        <div className={styles.block2}>
          <SVG svg="arrowChalk1" className={styles.chalkArrow} />

          <ChalkDrawImage image={block2Image} className={styles.image} />

          <IntersectionAnimation trigger={inView2} delay={200}>
            <h3 ref={ref2} className={cn('h2', styles.blockTitle)}>
              {block2Title}
            </h3>
          </IntersectionAnimation>

          <IntersectionAnimation trigger={inView2} delay={400}>
            <PortableTextRenderer
              rawText={_rawBlock2Text}
              containerClassName={styles.blockText}
              textClassName="b2"
              gatsbyImageComponent={GatsbyImage}
              getGatsbyImageData={getGatsbyImageData}
            />
          </IntersectionAnimation>
        </div>

        <div className={styles.block3}>
          <AnimatedGraphic className={styles.girlVideo} url={girlVideo} />
          <SVG svg="arrowChalk2" className={styles.chalkArrow} />

          <div className={styles.blockContent}>
            <ChalkDrawImage image={block3Image} className={styles.image} />

            <IntersectionAnimation trigger={inView3} delay={200}>
              <h3 ref={ref3} className={cn('h2', styles.blockTitle)}>
                {block3Title}
              </h3>
            </IntersectionAnimation>

            <IntersectionAnimation trigger={inView3} delay={400}>
              <PortableTextRenderer
                rawText={_rawBlock3Text}
                containerClassName={styles.blockText}
                textClassName="b2"
                gatsbyImageComponent={GatsbyImage}
                getGatsbyImageData={getGatsbyImageData}
              />
            </IntersectionAnimation>
          </div>
        </div>

        <div className={styles.block4}>
          <AnimatedGraphic className={styles.houseVideo} url={houseVideo} />
          <SVG svg="arrowChalk1" className={styles.chalkArrow} />

          <div className={styles.blockContent}>
            <ChalkDrawImage image={block4Image} className={styles.image} />

            <IntersectionAnimation trigger={inView4} delay={200}>
              <h3 ref={ref4} className={cn('h2', styles.blockTitle)}>
                {block4Title}
              </h3>
            </IntersectionAnimation>

            <IntersectionAnimation trigger={inView4} delay={400}>
              <PortableTextRenderer
                rawText={_rawBlock4Text}
                containerClassName={styles.blockText}
                textClassName="b2"
                gatsbyImageComponent={GatsbyImage}
                getGatsbyImageData={getGatsbyImageData}
              />
            </IntersectionAnimation>
          </div>
        </div>
      </LayoutProvider>
    </div>
  );
};

export default AboutOverview;

import React, { useRef } from 'react';
import { Button } from '@mayfield/common/components';
import * as styles from './styles.module.scss';

export interface IDropdownOption {
  title: string;
  to: string;
}

interface IProps {
  buttonText: string;
  dropdownOptions: IDropdownOption[];
  to?: string;
}

const DropdownButton = ({ buttonText, dropdownOptions, to }: IProps) => {
  const buttonRefs = useRef<(HTMLDivElement | null)[]>([]);

  const blurAllButtons = () => {
    buttonRefs.current.forEach((buttonContainer) => {
      const buttonElement = buttonContainer?.querySelector('a');
      buttonElement?.blur();
    });
  };

  return (
    <div className={styles.container} onMouseLeave={blurAllButtons}>
      <div className={styles.mainButtonContainer}>
        <Button to={to} variant="text">
          {buttonText}
        </Button>
      </div>

      <div className={styles.dropdownOptions}>
        {dropdownOptions.map((option, i) => (
          <div
            ref={(el) => (buttonRefs.current[i] = el)}
            key={i}
            className={styles.dropdownOptionContainer}
          >
            <Button variant="text" to={option.to}>
              {option.title}
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DropdownButton;

import React from 'react';
import { IQuestionWithTooltip } from '@mayfield/sanity';
import { useAccordion, useBreakpoints } from '@mayfield/common/hooks';
import { SVG } from '@mayfield/common/components';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import DesktopTooltip from './components/DesktopTooltip';

const Question = ({ question, tooltip }: IQuestionWithTooltip) => {
  const { smallTablet } = useBreakpoints();
  const { containerHeight, contentRef, setIsExpanded } = useAccordion();

  const handleClick = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <>
      <div className={cn('b1', styles.question)}>
        <p className={styles.questionText}>{question}</p>

        {tooltip && (
          <>
            {smallTablet && <DesktopTooltip tooltip={tooltip} />}

            {!smallTablet && (
              <button onClick={handleClick} className={styles.tooltipButton}>
                <SVG svg="tooltip" />
              </button>
            )}
          </>
        )}
      </div>

      {tooltip && !smallTablet && (
        <div className={styles.accordion} style={{ height: containerHeight }}>
          <div ref={contentRef} className={styles.accordionContent}>
            <p className={cn('caption', styles.tooltipText)}>{tooltip}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default Question;

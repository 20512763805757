// extracted by mini-css-extract-plugin
export var active = "styles-module--active--7467f";
export var backButton = "styles-module--backButton--a7204";
export var container = "styles-module--container--253c1";
export var content = "styles-module--content--38536";
export var desktop = "1340px";
export var findCentreMenu = "styles-module--findCentreMenu--754dd";
export var giant = "2200px";
export var headerHeight = "56px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var menu = "styles-module--menu--990d9";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
// extracted by mini-css-extract-plugin
export var blockQuote = "styles-module--blockQuote--b8c57";
export var bulletLevel1 = "styles-module--bulletLevel1--a8abb";
export var bulletLevel2 = "styles-module--bulletLevel2--22c38";
export var bulletLevel3 = "styles-module--bulletLevel3--4075c";
export var buttonContainer = "styles-module--buttonContainer--b88ac";
export var container = "styles-module--container--26cea";
export var mediaContainer = "styles-module--mediaContainer--71309";
export var numericLevel1 = "styles-module--numericLevel1--fafa5";
export var numericLevel2 = "styles-module--numericLevel2--7b6e1";
export var numericLevel3 = "styles-module--numericLevel3--5c6e5";
import React from "react";
import { Helmet } from "react-helmet";
import { IColor } from "@mayfield/sanity";
import { graphql, useStaticQuery } from "gatsby";

const Colors = () => {
  const colors: IColor[] = useStaticQuery(graphql`
    query ColorThemeQuery {
      allSanityColor {
        nodes {
          slug {
            current
          }
          hex
        }
      }
    }
  `).allSanityColor.nodes;

  let colorsCss = `:root{`;

  colors.forEach((color) => {
    colorsCss += `--color-${color.slug?.current.replace(`/`, `-`)}: ${
      color.hex
    };`;
  });

  colorsCss += `--cubic-easing: cubic-bezier(0.215, 0.61, 0.355, 1);`;
  colorsCss += `}`;

  return (
    <Helmet>
      <style>{colorsCss}</style>
    </Helmet>
  );
};

export default Colors;

// extracted by mini-css-extract-plugin
export var caption = "styles-module--caption--d5b4f";
export var carousel = "styles-module--carousel--a5a72";
export var carouselContainer = "styles-module--carouselContainer--da370";
export var carouselContent = "styles-module--carouselContent--0adea";
export var container = "styles-module--container--f4d74";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var slide = "styles-module--slide--eb98d";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
import React from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';

interface IProps {
  text: string;
  onClick: () => void;
  active: boolean;
}

const FormOptionButton = ({ active, onClick, text }: IProps) => {
  return (
    <button
      className={cn('caption', styles.button, { [styles.active]: active })}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default FormOptionButton;

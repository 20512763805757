import React from 'react';
import { ISectionIntro } from '@mayfield/sanity';
import cn from 'classnames';
import { useIntersectionAnimation } from '@mayfield/common/hooks';
import {
  IntersectionAnimation,
  LayoutProvider
} from '@mayfield/common/components';
import * as styles from './styles.module.scss';

interface IProps {
  data: ISectionIntro;
}

const SectionIntro = ({
  data: { backgroundColor, textColor, text, title }
}: IProps) => {
  const { inView, ref } = useIntersectionAnimation();

  return (
    <div
      className={styles.container}
      style={{ backgroundColor: backgroundColor.hex, color: textColor.hex }}
    >
      <LayoutProvider grid maxWidth padding>
        <div ref={ref} className={styles.content}>
          {title && (
            <IntersectionAnimation
              className={styles.title}
              trigger={inView}
              delay={200}
            >
              <h2 className={cn('h1')}>{title}</h2>
            </IntersectionAnimation>
          )}
          {text && (
            <IntersectionAnimation trigger={inView} delay={title ? 400 : 200}>
              <p className={cn('h4')}>{text}</p>
            </IntersectionAnimation>
          )}
        </div>
      </LayoutProvider>
    </div>
  );
};

export default SectionIntro;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  IntersectionAnimation,
  LayoutProvider,
  PortableTextRenderer,
  UniversalLink
} from '@mayfield/common/components';
import { ITopicSelectorTopic } from '@mayfield/sanity';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useIntersectionAnimation } from '@mayfield/common/hooks';
import cn from 'classnames';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import * as styles from './styles.module.scss';

interface IProps {
  topics: ITopicSelectorTopic[];
  colors: string[];
}

const TopicSelectorDesktop = ({ colors, topics }: IProps) => {
  const [activeTopicIndex, setActiveTopicIndex] = useState(0);
  const [currentTopicHeight, setCurrentTopicHeight] = useState(1000);

  const topicRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    topicRefs.current = topicRefs.current.slice(0, topics.length);
  }, [topics]);

  const updateActiveTopicHeight = useCallback(() => {
    const activeTopicRef = topicRefs.current[activeTopicIndex];
    const height = activeTopicRef?.getBoundingClientRect().height;
    setCurrentTopicHeight(height || 0);
  }, [activeTopicIndex]);

  useEffect(() => {
    // Doesn't work if not in timeout
    setTimeout(() => {
      updateActiveTopicHeight();
    }, 100);

    window.addEventListener('resize', updateActiveTopicHeight);
    return () => {
      window.removeEventListener('resize', updateActiveTopicHeight);
    };
  }, [updateActiveTopicHeight]);

  useEffect(() => {
    updateActiveTopicHeight();
  }, [activeTopicIndex, updateActiveTopicHeight]);

  const { ref, inView } = useIntersectionAnimation();

  return (
    <div
      className={styles.container}
      style={{
        background: colors[activeTopicIndex % colors.length]
      }}
    >
      <LayoutProvider grid padding maxWidth>
        <ul ref={ref} className={styles.menu}>
          {topics.map((topic, i) => (
            <li
              key={i}
              className={cn('h1', styles.topicListItem, {
                [styles.active]: activeTopicIndex === i
              })}
            >
              <IntersectionAnimation
                trigger={inView}
                delay={100 + i * 100}
                animation="fadeGrow"
              >
                <button
                  onClick={() => setActiveTopicIndex(i)}
                  className={styles.topicButton}
                >
                  {topic.title}
                </button>
              </IntersectionAnimation>
            </li>
          ))}
        </ul>

        <div
          className={styles.topicContentContainer}
          style={{
            height: currentTopicHeight
          }}
        >
          <IntersectionAnimation animation="fade">
            {topics.map(({ _rawText, button, image }, i) => (
              <div
                ref={(el) => (topicRefs.current[i] = el)}
                className={cn(styles.topicContent, {
                  [styles.active]: activeTopicIndex === i
                })}
                key={i}
              >
                {image && (
                  <div className={styles.imageContainer}>
                    <GatsbyImage
                      alt={image.altText || ''}
                      image={image.asset.gatsbyImageData}
                    />
                  </div>
                )}

                <PortableTextRenderer
                  rawText={_rawText}
                  containerClassName={styles.text}
                  textClassName="b2"
                  gatsbyImageComponent={GatsbyImage}
                  getGatsbyImageData={getGatsbyImageData}
                />

                {button && (
                  <UniversalLink
                    variant="secondary"
                    theme="lightOnDark"
                    link={button}
                  />
                )}
              </div>
            ))}
          </IntersectionAnimation>
        </div>
      </LayoutProvider>
    </div>
  );
};

export default TopicSelectorDesktop;

import React from 'react';
import { IStaffMember } from '@mayfield/sanity';
import { GatsbyImage } from 'gatsby-plugin-image';
import { SVG } from '@mayfield/common/components';
import cn from 'classnames';
import { useApp } from '@mayfield/website/hooks';
import { TModalDrawingsVariant } from '@mayfield/website/context';
import BioModalContent from '../BioModalContent';
import * as styles from './styles.module.scss';

interface IProps extends IStaffMember {
  index: number;
}

const StaffMemberTile = ({
  headshot,
  name,
  position,
  _rawBio,
  linkedinProfile,
  babyPhoto,
  wantedToBe,
  index
}: IProps) => {
  const { setModalContent, setModalDrawingsVariant } = useApp();

  const modalDrawingVariants: TModalDrawingsVariant[] = [1, 2, 3];

  const updateIsBioOpen = () => {
    setModalContent(
      <BioModalContent
        _rawBio={_rawBio}
        headshot={headshot}
        name={name}
        position={position}
        linkedinUrl={linkedinProfile}
      />
    );
    setModalDrawingsVariant(
      modalDrawingVariants[index % modalDrawingVariants.length]
    );
  };

  const Element = _rawBio ? 'button' : 'div';

  return (
    <div className={styles.container}>
      <Element className={styles.button} onClick={updateIsBioOpen}>
        <div className={styles.imageContainer}>
          <GatsbyImage
            style={{ position: 'absolute', inset: 0 }}
            alt={`Headshot of ${name}, ${position} at Mayfield`}
            image={headshot.asset.gatsbyImageData}
          />

          {babyPhoto && (
            <div className={styles.babyPhoto}>
              <GatsbyImage
                style={{ position: 'absolute', inset: 0 }}
                alt={`Headshot of ${name}, ${position} at Mayfield`}
                image={babyPhoto.asset.gatsbyImageData}
              />
            </div>
          )}
        </div>

        <h3 className={cn('b1', styles.name)}>{name}</h3>

        <div className={cn('b2', styles.positionContainer)}>
          <div
            className={cn(styles.position, {
              [styles.positionToggle]: wantedToBe
            })}
          >
            {position}
          </div>

          {wantedToBe && (
            <div className={styles.wantedToBe}>Wanted to be {wantedToBe}</div>
          )}
        </div>

        {/* Render even when not needed to preserve layout */}
        <div
          aria-hidden={!_rawBio}
          className={cn(styles.bioPrompt, { [styles.hidden]: !_rawBio })}
        >
          <div className={cn('button-small', styles.readMoreTag)}>
            <span>Read More</span>
            <SVG svg="plus" className={styles.plusIcon} />
          </div>
        </div>
      </Element>
    </div>
  );
};

export default StaffMemberTile;

import React from 'react';
import { useCCSResults } from '@mayfield/website/hooks';
import FormOptionButton from '../FormOptionButton';
import { IFormData, TNumberOfChildren } from '../..';

interface IProps {
  options: TNumberOfChildren[];
  formData: IFormData;
  setFormData: React.Dispatch<React.SetStateAction<IFormData>>;
}

// Need this seperate component + CCS context to be able to update container accordion height
// when number of children is changed

const NumberOfChildrenSelector = ({
  options,
  formData,
  setFormData
}: IProps) => {
  const { setNumberOfChildren } = useCCSResults();

  const handleClick = (option: TNumberOfChildren) => {
    setFormData((prev) => ({
      ...prev,
      numberOfChildren: option
    }));
    setNumberOfChildren(option);
  };

  return (
    <>
      {options.map((option, i) => (
        <FormOptionButton
          key={i}
          active={formData.numberOfChildren === option}
          text={option.toString()}
          onClick={() => handleClick(option)}
        />
      ))}
    </>
  );
};

export default NumberOfChildrenSelector;

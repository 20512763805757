import React, { useEffect } from 'react';
import cn from 'classnames';
import { LayoutProvider, SVG } from '@mayfield/common/components';
import { useAccordion, useBreakpoints } from '@mayfield/common/hooks';
import { CCSResultsProvider } from '@mayfield/website/context';
import { useCCSResults } from '@mayfield/website/hooks';
import { TActiveSection } from '../..';
import * as styles from './styles.module.scss';

interface IProps {
  children: React.ReactNode;
  title: string;
  section: TActiveSection;
  completed?: boolean;
  activeSection: TActiveSection;
  setActiveSection: React.Dispatch<React.SetStateAction<TActiveSection>>;
}

const SectionContent = ({
  children,
  title,
  section,
  completed,
  activeSection,
  setActiveSection
}: IProps) => {
  const { containerHeight, contentRef, setIsExpanded, calculateContentHeight } =
    useAccordion();
  const { smallTablet } = useBreakpoints();
  const { numberOfChildren } = useCCSResults();

  const active = section === activeSection;

  useEffect(() => {
    setIsExpanded(active);
  }, [active, setIsExpanded]);

  useEffect(() => {
    if (!contentRef.current) return;

    const handleMutations = (mutationsList: MutationRecord[]) => {
      const TRANSITION_DURATION_MS = 400; // sync with styles.module.scss

      for (const mutation of mutationsList) {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'style'
        ) {
          setTimeout(() => {
            calculateContentHeight();
          }, TRANSITION_DURATION_MS);
        }
      }
    };

    const observer = new MutationObserver(handleMutations);

    observer.observe(contentRef.current, {
      attributes: true,
      attributeFilter: ['style'],
      subtree: true
    });

    return () => {
      observer.disconnect();
    };
  }, [contentRef, calculateContentHeight]);

  useEffect(() => {
    calculateContentHeight();
  }, [numberOfChildren, calculateContentHeight]);

  const isFaded = () => {
    switch (section) {
      case '1. Subsidy':
        return false;
      case '2. Children':
        if (activeSection === '1. Subsidy' && !completed) return true;
        return false;
      case '3. Results':
        if (activeSection === '1. Subsidy' || activeSection === '2. Children')
          return true;
        return false;

      default:
        return false;
    }
  };

  return (
    <LayoutProvider className={styles.grid} padding={smallTablet} grid>
      <div className={cn(styles.container, { [styles.faded]: isFaded() })}>
        <div className={styles.titleContainer}>
          <h2 className={cn('h3')}>{title}</h2>

          <div className={styles.iconContainer}>
            <SVG
              svg="tick"
              className={cn(styles.tickIcon, {
                [styles.visible]:
                  completed && !active && activeSection !== '3. Results'
              })}
            />

            <button
              disabled={
                activeSection !== '3. Results' || section === '3. Results'
              }
              className={styles.editButton}
              onClick={() => setActiveSection(section)}
            >
              <SVG svg="edit" className={styles.buttonIcon} />
            </button>
          </div>
        </div>

        <div style={{ height: containerHeight }} className={styles.accordion}>
          <div ref={contentRef} className={styles.content}>
            {children}
          </div>
        </div>
      </div>
    </LayoutProvider>
  );
};

const Section = (props: IProps) => (
  <CCSResultsProvider>
    <SectionContent {...props} />
  </CCSResultsProvider>
);

export default Section;

import React from 'react';
import { LayoutProvider } from '@mayfield/common/components';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { toKebabCase } from '@mayfield/common/utils';
import cn from 'classnames';
import * as styles from './styles.module.scss';

const CitiesList = () => {
  const {
    allSanityCentre: { nodes }
  }: IQueryData = useStaticQuery(query);

  const citiesWithCentres = nodes
    .map((node) => node.address.city)

    .filter(Boolean)
    .filter((city) => city !== 'None');
  const cities = [...new Set(citiesWithCentres)];

  return (
    <div className={styles.container}>
      <LayoutProvider maxWidth padding>
        <h2 className={cn('h2', styles.header)}>View centres in...</h2>
        <ul className={styles.items}>
          {cities.map((city) => (
            <li key={city}>
              <Link
                className={cn('button-small', styles.linkItem)}
                to={`/centres/${toKebabCase(city)}`}
              >
                {city}
              </Link>
            </li>
          ))}
        </ul>
      </LayoutProvider>
    </div>
  );
};

export default CitiesList;

/**
 * GraphQL ====================================================================
 */

interface IQueryData {
  allSanityCentre: {
    nodes: {
      address: {
        city: string;
      };
    }[];
  };
}

const query = graphql`
  query {
    allSanityCentre {
      nodes {
        address {
          city
        }
      }
    }
  }
`;

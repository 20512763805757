export const victoria: google.maps.places.PlaceResult = {
  address_components: [
    {
      long_name: 'Victoria',
      short_name: 'VIC',
      types: ['administrative_area_level_1', 'political']
    },
    {
      long_name: 'Australia',
      short_name: 'AU',
      types: ['country', 'political']
    }
  ],
  formatted_address: 'Victoria, Australia',
  geometry: {
    location: {
      lat: -36.9847807,
      lng: 143.3906074
    },
    viewport: {
      south: -39.15919272492777,
      west: 140.9618588835476,
      north: -33.98064980643837,
      east: 149.9764989974489
    }
  },
  types: ['administrative_area_level_1', 'political']
};

export const queensland: google.maps.places.PlaceResult = {
  address_components: [
    {
      long_name: 'Queensland',
      short_name: 'QLD',
      types: ['administrative_area_level_1', 'political']
    },
    {
      long_name: 'Australia',
      short_name: 'AU',
      types: ['country', 'political']
    }
  ],
  formatted_address: 'Queensland, Australia',
  geometry: {
    location: {
      lat: -22.575197,
      lng: 144.0847926
    },
    viewport: {
      south: -29.17789772132697,
      west: 137.9959573174723,
      north: -9.14120295827351,
      east: 153.5552415557782
    }
  },
  name: 'Queensland',
  types: ['administrative_area_level_1', 'political']
};

export const southAustralia: google.maps.places.PlaceResult = {
  address_components: [
    {
      long_name: 'South Australia',
      short_name: 'SA',
      types: ['administrative_area_level_1', 'political']
    },
    {
      long_name: 'Australia',
      short_name: 'AU',
      types: ['country', 'political']
    }
  ],
  formatted_address: 'South Australia, Australia',
  geometry: {
    location: {
      lat: -30.0002315,
      lng: 136.2091547
    },
    viewport: {
      south: -38.06260305541446,
      west: 129.0013399850446,
      north: -25.99637649100899,
      east: 141.0029556480488
    }
  },
  types: ['administrative_area_level_1', 'political']
};

export const melbourne: google.maps.places.PlaceResult = {
  address_components: [
    {
      long_name: 'Melbourne',
      short_name: 'Melbourne',
      types: ['colloquial_area', 'locality', 'political']
    },
    {
      long_name: 'Victoria',
      short_name: 'VIC',
      types: ['administrative_area_level_1', 'political']
    },
    {
      long_name: 'Australia',
      short_name: 'AU',
      types: ['country', 'political']
    }
  ],
  formatted_address: 'Melbourne VIC, Australia',
  geometry: {
    location: {
      lat: -37.8136276,
      lng: 144.9630576
    },
    viewport: {
      south: -38.43385934606901,
      west: 144.5937417793496,
      north: -37.5112736581378,
      east: 145.512528780816
    }
  },
  types: ['colloquial_area', 'locality', 'political']
};

export const adelaide: google.maps.places.PlaceResult = {
  address_components: [
    {
      long_name: 'Adelaide',
      short_name: 'Adelaide',
      types: ['colloquial_area', 'locality', 'political']
    },
    {
      long_name: 'South Australia',
      short_name: 'SA',
      types: ['administrative_area_level_1', 'political']
    },
    {
      long_name: 'Australia',
      short_name: 'AU',
      types: ['country', 'political']
    }
  ],
  formatted_address: 'Adelaide SA, Australia',
  geometry: {
    location: {
      lat: -34.9284989,
      lng: 138.6007456
    },
    viewport: {
      south: -35.34896993945445,
      west: 138.4421298502447,
      north: -34.65256396872677,
      east: 138.7801898270823
    }
  },
  types: ['colloquial_area', 'locality', 'political']
};

export const brisbane: google.maps.places.PlaceResult = {
  address_components: [
    {
      long_name: 'Brisbane',
      short_name: 'Brisbane',
      types: ['colloquial_area', 'locality', 'political']
    },
    {
      long_name: 'Queensland',
      short_name: 'QLD',
      types: ['administrative_area_level_1', 'political']
    },
    {
      long_name: 'Australia',
      short_name: 'AU',
      types: ['country', 'political']
    }
  ],
  formatted_address: 'Brisbane QLD, Australia',
  geometry: {
    location: {
      lat: -27.4704528,
      lng: 153.0260341
    },
    viewport: {
      south: -27.76744089416125,
      west: 152.6685227400751,
      north: -26.99684492277586,
      east: 153.3178702187602
    }
  },
  types: ['colloquial_area', 'locality', 'political']
};

export const goldCoast: google.maps.places.PlaceResult = {
  address_components: [
    {
      long_name: 'Gold Coast',
      short_name: 'Gold Coast',
      types: ['colloquial_area', 'locality', 'political']
    },
    {
      long_name: 'Queensland',
      short_name: 'QLD',
      types: ['administrative_area_level_1', 'political']
    },
    {
      long_name: 'Australia',
      short_name: 'AU',
      types: ['country', 'political']
    }
  ],
  formatted_address: 'Gold Coast QLD, Australia',
  geometry: {
    location: {
      lat: -28.016667,
      lng: 153.4
    },
    viewport: {
      south: -28.20031703071094,
      west: 153.1865516538155,
      north: -27.70812624627418,
      east: 153.5521711326502
    }
  },
  types: ['colloquial_area', 'locality', 'political']
};

export const cairns: google.maps.places.PlaceResult = {
  address_components: [
    {
      long_name: 'Cairns',
      short_name: 'Cairns',
      types: ['colloquial_area', 'locality', 'political']
    },
    {
      long_name: 'Cairns Regional',
      short_name: 'Cairns',
      types: ['administrative_area_level_2', 'political']
    },
    {
      long_name: 'Queensland',
      short_name: 'QLD',
      types: ['administrative_area_level_1', 'political']
    },
    {
      long_name: 'Australia',
      short_name: 'AU',
      types: ['country', 'political']
    }
  ],
  formatted_address: 'Cairns QLD, Australia',
  geometry: {
    location: {
      lat: -16.9203476,
      lng: 145.7709529
    },
    viewport: {
      south: -17.03865891626855,
      west: 145.6504569871566,
      north: -16.72239395299669,
      east: 145.7834315760682
    }
  },
  types: ['colloquial_area', 'locality', 'political']
};

import React from 'react';
import { IAltImage } from '@mayfield/sanity';
import { GatsbyImage } from 'gatsby-plugin-image';
import cn from 'classnames';
import * as styles from './styles.module.scss';

interface IProps {
  image: IAltImage;
  className?: string;
}

const FlowerMaskImage = ({ image, className }: IProps) => {
  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.imageContainer}>
        <GatsbyImage
          style={{ height: '100%', width: '100%' }}
          alt={image.altText || ''}
          image={image.asset.gatsbyImageData}
        />
      </div>
    </div>
  );
};

export default FlowerMaskImage;

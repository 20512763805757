import React from 'react';
import { UniversalLink } from '@mayfield/common/components';
import { IRichTextButton } from '@mayfield/sanity';

export interface IProps {
  data: IRichTextButton;
}

const PortableTextButton = ({ data }: IProps) => {
  const { link, variant } = data || {};

  if (!link) return null;

  return <UniversalLink link={link} variant={variant || 'primary'} />;
};

export default PortableTextButton;

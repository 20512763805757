// extracted by mini-css-extract-plugin
export var accordion = "styles-module--accordion--110b8";
export var accordionContent = "styles-module--accordionContent--73a9a";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var question = "styles-module--question--91611";
export var questionText = "styles-module--questionText--44fc9";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var tooltipButton = "styles-module--tooltipButton--abb6f";
export var tooltipText = "styles-module--tooltipText--67cf4";
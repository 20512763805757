// extracted by mini-css-extract-plugin
export var babyPhoto = "styles-module--babyPhoto--65138";
export var bioPrompt = "styles-module--bioPrompt--0b998";
export var button = "styles-module--button--12d16";
export var container = "styles-module--container--b4fe4";
export var desktop = "1340px";
export var giant = "2200px";
export var hidden = "styles-module--hidden--70a50";
export var imageContainer = "styles-module--imageContainer--2db03";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var name = "styles-module--name--27d56";
export var open = "styles-module--open--88c54";
export var plusIcon = "styles-module--plusIcon--ea5a6";
export var position = "styles-module--position--f6377";
export var positionContainer = "styles-module--positionContainer--ec052";
export var positionToggle = "styles-module--positionToggle--fe272";
export var readMoreTag = "styles-module--readMoreTag--ec1b2";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var wantedToBe = "styles-module--wantedToBe--07f0f";
// extracted by mini-css-extract-plugin
export var container = "styles-module--container--62e32";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var topic1 = "styles-module--topic1--0847d";
export var topic2 = "styles-module--topic2--5e169";
export var topic3 = "styles-module--topic3--5da87";
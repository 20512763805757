import React from 'react';
import { IResponsiveImage } from '@mayfield/sanity';
import { TBreakpoint } from '@mayfield/common/hooks';
import cn from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useBreakpoints } from '@mayfield/common/hooks';

interface IProps {
  imageData: IResponsiveImage;
  style?: React.CSSProperties; // GatsbyImage doesn't play nice with classNames
  className?: string;
  breakpoint?: TBreakpoint;
  loading?: 'eager' | 'lazy';
}

const ResponsiveImage = ({
  imageData,
  style: inlineStyles,
  className,
  breakpoint = 'smallTablet',
  loading
}: IProps) => {
  const { desktopImage, mobileImage, altText } = imageData;
  const breakpoints = useBreakpoints();

  const isAtBreakpoint = breakpoints[breakpoint];

  return (
    <div className={cn(className)}>
      {isAtBreakpoint ? (
        <GatsbyImage
          style={inlineStyles}
          loading={loading}
          alt={altText || ''}
          image={desktopImage.asset.gatsbyImageData}
        />
      ) : (
        <GatsbyImage
          style={inlineStyles}
          alt={altText || ''}
          loading={loading}
          image={mobileImage.asset.gatsbyImageData}
        />
      )}
    </div>
  );
};

export default ResponsiveImage;

import React from 'react';
import cn from 'classnames';
import { SVG } from '@mayfield/common/components';
import * as styles from './styles.module.scss';

interface IProps {
  numberOfItems: number;
  scrollPrev: () => void;
  scrollNext: () => void;
  activeIndex: number;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
  color?: string;
  className?: string;
}

const PaginationControls = ({
  numberOfItems,
  scrollPrev,
  scrollNext,
  activeIndex,
  setActiveIndex,
  className,
  color
}: IProps) => {
  const textColor = color || `var(--color-theme-dark)`;

  const iterableArray = Array.from(Array(numberOfItems));

  return (
    <div
      className={cn(styles.controls, className)}
      style={{ color: textColor }}
    >
      <button
        className={styles.arrowButton}
        onClick={scrollPrev}
        disabled={activeIndex === 0}
      >
        <SVG svg="arrowLeft" className={styles.arrowIcon} />
      </button>

      <div className={styles.pagination}>
        {iterableArray.map((_, i) => (
          <button
            tabIndex={-1}
            key={i}
            onClick={() => setActiveIndex(i)}
            className={cn(styles.paginationDot, {
              [styles.active]: i === activeIndex
            })}
          />
        ))}
      </div>

      <button
        className={styles.arrowButton}
        onClick={scrollNext}
        disabled={activeIndex === numberOfItems - 1}
      >
        <SVG svg="arrowRight" className={styles.arrowIcon} />
      </button>
    </div>
  );
};

export default PaginationControls;

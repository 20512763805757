// extracted by mini-css-extract-plugin
export var articleTile = "styles-module--articleTile--11d08";
export var container = "styles-module--container--585a4";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--3bb2d";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var loadMoreButtonContainer = "styles-module--loadMoreButtonContainer--21048";
export var mobile = "400px";
export var noJobsText = "styles-module--noJobsText--2356e";
export var searchAndFilter = "styles-module--searchAndFilter--90499";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var tiles = "styles-module--tiles--028d9";
export var title = "styles-module--title--d035b";
export var titleAndSearch = "styles-module--titleAndSearch--cb09e";
export var topTeam = "styles-module--topTeam--4b789";
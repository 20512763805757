// extracted by mini-css-extract-plugin
export var button = "styles-module--button--8b5e0";
export var container = "styles-module--container--e349d";
export var defaultContent = "styles-module--defaultContent--e2d7f";
export var description = "styles-module--description--52f85";
export var desktop = "1340px";
export var giant = "2200px";
export var hoverContent = "styles-module--hoverContent--7cfba";
export var info = "styles-module--info--73962";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var location = "styles-module--location--1ffc8";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var tag = "styles-module--tag--681f4";
export var tags = "styles-module--tags--1f7eb";
export var title = "styles-module--title--488ab";
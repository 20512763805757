import React, { useState } from 'react';
import cn from 'classnames';
import {
  Button,
  IntersectionAnimation,
  LayoutProvider
} from '@mayfield/common/components';
import {
  ArticleTile,
  IFilterCategory,
  SearchAndFilter,
  ThemeColorVariableInjector
} from '@mayfield/website/components';
import {
  useBreakpoints,
  useIntersectionAnimation
} from '@mayfield/common/hooks';
import { useStaggerAnimation } from '@mayfield/website/hooks';
import { IArticleTileData } from '../..';
import * as styles from './styles.module.scss';

interface IProps {
  articles: IArticleTileData[];
}

const ArticleGrid = ({ articles }: IProps) => {
  const getInitialFilterCategories: () => IFilterCategory[] = () => {
    const categories = new Set<string>();
    const locations = new Set<string>();

    articles.forEach((article) => {
      if (article.state !== 'none') {
        locations.add(article.state);
      }

      article.categories.forEach((category) => categories.add(category.title));
    });

    const locationsCategory: IFilterCategory = {
      name: 'Location',
      options: Array.from(locations).map((location) => ({
        name: location,
        selected: false
      }))
    };

    const categoriesCategory: IFilterCategory = {
      name: 'Category',
      options: Array.from(categories).map((category) => ({
        name: category,
        selected: false
      }))
    };

    return [locationsCategory, categoriesCategory];
  };

  const [filterCategories, setFilterCategories] = useState<IFilterCategory[]>(
    getInitialFilterCategories()
  );
  const [searchQuery, setSearchQuery] = useState('');

  const { largeTablet } = useBreakpoints();

  const [batchesToDisplay, setBatchesToDisplay] = useState(1);

  const numberOfArticlesPerBatch = largeTablet ? 9 : 8;

  const getFilteredArticles = () => {
    const locationFilter = filterCategories.find(
      (category) => category.name === 'Location'
    );
    const categoryFilter = filterCategories.find(
      (category) => category.name === 'Category'
    );

    const activeLocationFilters = locationFilter?.options.filter(
      (option) => option.selected
    );
    const activeCategoryFilters = categoryFilter?.options.filter(
      (option) => option.selected
    );

    const isLocationFilterActive = activeLocationFilters?.[0];
    const isCategoryFilterActive = activeCategoryFilters?.[0];

    const filteredArticles = articles.filter((article) => {
      if (
        isLocationFilterActive &&
        !activeLocationFilters?.some((option) => option.name === article.state)
      ) {
        return false;
      }

      if (
        isCategoryFilterActive &&
        !activeCategoryFilters?.some((option) =>
          article.categories.map((c) => c.title).includes(option.name)
        )
      ) {
        return false;
      }

      return true;
    });

    const queryFilteredArticles = filteredArticles.filter((article) => {
      const { title } = article;
      const searchQueryLowercase = searchQuery.toLowerCase();

      return title.toLowerCase().includes(searchQueryLowercase);
    });

    return queryFilteredArticles;
  };

  const filteredArticles = getFilteredArticles();

  const articlesToDisplay = filteredArticles.slice(
    0,
    numberOfArticlesPerBatch * batchesToDisplay
  );

  const { ref, inView } = useIntersectionAnimation();

  const { getDelayTime, staggerAnimation } = useStaggerAnimation(
    articlesToDisplay.length,
    inView
  );

  return (
    <div className={styles.container}>
      <ThemeColorVariableInjector themeColor="sky" />

      <LayoutProvider maxWidth padding grid>
        <div className={styles.titleAndSearch}>
          <IntersectionAnimation delay={200}>
            <h2 className={cn('h1', styles.title)}>All News Articles</h2>
          </IntersectionAnimation>

          <IntersectionAnimation delay={300}>
            <SearchAndFilter
              filterCategories={filterCategories}
              setFilterCategories={setFilterCategories}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              className={styles.searchAndFilter}
              themeColor="sky"
              placeholder="Search News Articles"
            />
          </IntersectionAnimation>
        </div>

        <div className={styles.tiles} ref={ref}>
          <LayoutProvider grid className={styles.grid}>
            {articlesToDisplay.map((article, i) => (
              <IntersectionAnimation
                trigger={inView}
                delay={getDelayTime(i)}
                key={article.slug.current}
                className={styles.articleTile}
                animation={staggerAnimation ? 'fadeUp' : 'none'}
              >
                <ArticleTile
                  image={article.image}
                  key={i}
                  date={article.date}
                  slug={article.slug}
                  title={article.title}
                />
              </IntersectionAnimation>
            ))}

            {!articlesToDisplay?.[0] && (
              <p className={cn('h3', styles.noJobsText)}>
                Sorry, currently no articles match your search!
              </p>
            )}

            {articlesToDisplay.length < filteredArticles.length && (
              <div className={styles.loadMoreButtonContainer}>
                <Button onClick={() => setBatchesToDisplay((prev) => prev + 1)}>
                  Load More
                </Button>
              </div>
            )}
          </LayoutProvider>
        </div>
      </LayoutProvider>
    </div>
  );
};

export default ArticleGrid;

// extracted by mini-css-extract-plugin
export var buttonContent = "styles-module--buttonContent--622bf";
export var closeButtonContent = "styles-module--closeButtonContent--67476";
export var container = "styles-module--container--bf3af";
export var content = "styles-module--content--a041d";
export var ctaButton = "styles-module--ctaButton--21ab1";
export var desktop = "1340px";
export var giant = "2200px";
export var hamburgerButton = "styles-module--hamburgerButton--89c29";
export var headerHeight = "56px";
export var headerLinks = "styles-module--headerLinks--573c1";
export var hidden = "styles-module--hidden--fef89";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var middleItems = "styles-module--middleItems--967b1";
export var mobile = "400px";
export var pageContentPadding = "styles-module--pageContentPadding--f5fb0";
export var rightItems = "styles-module--rightItems--9d20d";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var wordmark = "styles-module--wordmark--0280f";
export var wordmarkLink = "styles-module--wordmarkLink--3f5a0";
export var xIcon = "styles-module--xIcon--be58f";
// extracted by mini-css-extract-plugin
export var container = "styles-module--container--36fc1";
export var desktop = "1340px";
export var giant = "2200px";
export var icon = "styles-module--icon--b8b61";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var tooltipContent = "styles-module--tooltipContent--e8d02";
export var tooltipText = "styles-module--tooltipText--3c3d5";
export var triangle = "styles-module--triangle--b5905";
export var triangleContainer = "styles-module--triangleContainer--929de";
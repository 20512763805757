import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export type TIllustration =
  | 'award'
  | 'balloon'
  | 'butterfly'
  | 'candy'
  | 'flower'
  | 'girl'
  | 'heart'
  | 'house'
  | 'pencil'
  | 'people'
  | 'planet'
  | 'present'
  | 'rainbow'
  | 'star'
  | 'sun'
  | 'ticTacToe';

interface IProps {
  illustration: TIllustration;
  style?: React.CSSProperties;
}

const Illustration = ({ illustration, style }: IProps) => {
  switch (illustration) {
    case 'award':
      return (
        <StaticImage
          placeholder="none"
          style={style}
          src="./assets/award.png"
          alt=""
        />
      );
    case 'balloon':
      return (
        <StaticImage
          placeholder="none"
          style={style}
          src="./assets/balloon.png"
          alt=""
        />
      );
    case 'butterfly':
      return (
        <StaticImage
          placeholder="none"
          style={style}
          src="./assets/butterfly.png"
          alt=""
        />
      );
    case 'candy':
      return (
        <StaticImage
          placeholder="none"
          style={style}
          src="./assets/candy.png"
          alt=""
        />
      );
    case 'flower':
      return (
        <StaticImage
          placeholder="none"
          style={style}
          src="./assets/flower.png"
          alt=""
        />
      );
    case 'girl':
      return (
        <StaticImage
          placeholder="none"
          style={style}
          src="./assets/girl.png"
          alt=""
        />
      );
    case 'heart':
      return (
        <StaticImage
          placeholder="none"
          style={style}
          src="./assets/heart.png"
          alt=""
        />
      );
    case 'house':
      return (
        <StaticImage
          placeholder="none"
          style={style}
          src="./assets/house.png"
          alt=""
        />
      );
    case 'pencil':
      return (
        <StaticImage
          placeholder="none"
          style={style}
          src="./assets/pencil.png"
          alt=""
        />
      );
    case 'people':
      return (
        <StaticImage
          placeholder="none"
          style={style}
          src="./assets/people.png"
          alt=""
        />
      );
    case 'planet':
      return (
        <StaticImage
          placeholder="none"
          style={style}
          src="./assets/planet.png"
          alt=""
        />
      );
    case 'present':
      return (
        <StaticImage
          placeholder="none"
          style={style}
          src="./assets/present.png"
          alt=""
        />
      );
    case 'rainbow':
      return (
        <StaticImage
          placeholder="none"
          style={style}
          src="./assets/rainbow.png"
          alt=""
        />
      );
    case 'star':
      return (
        <StaticImage
          placeholder="none"
          style={style}
          src="./assets/star.png"
          alt=""
        />
      );
    case 'sun':
      return (
        <StaticImage
          placeholder="none"
          style={style}
          src="./assets/sun.png"
          alt=""
        />
      );
    case 'ticTacToe':
      return (
        <StaticImage
          placeholder="none"
          style={style}
          src="./assets/ticTacToe.png"
          alt=""
        />
      );

    default:
      return null;
  }
};

export default Illustration;

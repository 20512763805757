import React, { useEffect } from 'react';
import cn from 'classnames';
import { IQuestionWithTooltip } from '@mayfield/sanity';
import { SVG, TextInput } from '@mayfield/common/components';
import { useAccordion, useBreakpoints } from '@mayfield/common/hooks';
import Question from '../Question';
import FormOptionButton from '../FormOptionButton';
import { IFormData, TDaysPerWeek } from '../..';
import * as styles from './styles.module.scss';

interface IProps {
  index: number;
  daysPerWeekQuestion: IQuestionWithTooltip;
  hoursPerDayQuestion: IQuestionWithTooltip;
  dailyCentreRateQuestion: IQuestionWithTooltip;
  daysPerWeekOptions: TDaysPerWeek[];
  formData: IFormData;
  setFormData: React.Dispatch<React.SetStateAction<IFormData>>;
  numberOfChildren: number;
}

const ChildDetailsForm = (props: IProps) => {
  const { index } = props;

  const { tablet } = useBreakpoints();
  const { containerHeight, contentRef, setIsExpanded, isExpanded } =
    useAccordion();

  const handleClick = () => {
    if (tablet) return;
    setIsExpanded((prev) => !prev);
  };

  const useAccordionLayout = false;
  // const useAccordionLayout = !tablet && numberOfChildren > 1;

  useEffect(() => {
    setIsExpanded(index === 0);
  }, [index, setIsExpanded, tablet, useAccordionLayout]);

  useEffect(() => {
    if (tablet) {
      setIsExpanded(true);
    } else {
      setIsExpanded(index === 0);
    }
  }, [tablet, setIsExpanded, index]);

  return (
    <>
      {!useAccordionLayout && (
        <div className={styles.container}>
          <div className={styles.titleContainer}>
            <div className={cn('h4')}>Child {index + 1}</div>
          </div>

          <FormFields {...props} />
        </div>
      )}

      {useAccordionLayout && (
        <div className={styles.container}>
          <button
            tabIndex={tablet ? -1 : 0}
            role={tablet ? 'none' : 'button'}
            onClick={handleClick}
            className={cn(styles.titleContainer, styles.button)}
          >
            <div
              className={cn(styles.chevronCircle, {
                [styles.active]: isExpanded
              })}
            >
              <SVG svg="chevronRight" className={styles.chevron} />
            </div>

            <h3 className={cn('h4')}>Child {index + 1}</h3>
          </button>

          <div className={styles.accordion} style={{ height: containerHeight }}>
            <div ref={contentRef}>
              <FormFields {...props} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChildDetailsForm;

const FormFields = ({
  dailyCentreRateQuestion,
  daysPerWeekOptions,
  daysPerWeekQuestion,
  formData,
  hoursPerDayQuestion,
  index,
  setFormData
}: IProps) => {
  return (
    <div className={styles.formContent}>
      {/* Days Per Week */}
      <Question {...daysPerWeekQuestion} />
      <div className={styles.formInput}>
        {daysPerWeekOptions.map((option, i) => (
          <FormOptionButton
            key={i}
            active={
              formData.childData[index]?.daysPerWeek.includes(option) || false
            }
            text={option.toString()}
            onClick={() =>
              setFormData((prev) => ({
                ...prev,
                childData: prev.childData.map((child, j) => {
                  if (j !== index) return child;

                  const daysPerWeek = child.daysPerWeek.includes(option)
                    ? child.daysPerWeek.filter((day) => day !== option)
                    : [...child.daysPerWeek, option];

                  return {
                    ...child,
                    daysPerWeek
                  };
                })
              }))
            }
          />
        ))}
      </div>

      {/* Hours per day */}
      <Question {...hoursPerDayQuestion} />
      <TextInput
        className={cn(styles.textInput, styles.formInput)}
        placeholder="Enter Hours"
        type="number"
        themeColor="grass"
        onChange={(e) =>
          setFormData((prev) => ({
            ...prev,

            childData: prev.childData.map((child, j) => {
              if (j !== index) return child;

              return {
                ...child,
                hoursPerDay: parseInt(e)
              };
            })
          }))
        }
        value={formData.childData[index]?.hoursPerDay?.toString() || ''}
      />

      {/* Daily centre rate */}
      <Question {...dailyCentreRateQuestion} />
      <TextInput
        className={cn(styles.textInput, styles.formInput)}
        placeholder="Enter daily rate"
        type="number"
        iconLeft="dollarSign"
        themeColor="grass"
        onChange={(e) =>
          setFormData((prev) => ({
            ...prev,

            childData: prev.childData.map((child, j) => {
              if (j !== index) return child;

              return {
                ...child,
                dailyCentreRate: parseInt(e)
              };
            })
          }))
        }
        value={formData.childData[index]?.dailyCentreRate?.toString() || ''}
      />
    </div>
  );
};

import React from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import { useSpring, animated } from 'react-spring';
import { useMousePosition } from '@mayfield/common/hooks';
import { useApp } from '@mayfield/website/hooks';
import { Illustration } from '@mayfield/website/components';

const Drawings = () => {
  const TRANSLATE_MULTIPLIER = 10;

  const { normalisedMousePosition } = useMousePosition();
  const { modalDrawingsVariant } = useApp();

  const animationConfig = {
    tension: 200,
    friction: 10
  };

  const springs1 = useSpring({
    x: normalisedMousePosition.x * TRANSLATE_MULTIPLIER,
    y: normalisedMousePosition.y * TRANSLATE_MULTIPLIER,
    config: animationConfig
  });

  const springs2 = useSpring({
    x: normalisedMousePosition.y * TRANSLATE_MULTIPLIER,
    y: normalisedMousePosition.x * TRANSLATE_MULTIPLIER,
    config: animationConfig
  });

  const springs3 = useSpring({
    x: normalisedMousePosition.y * -TRANSLATE_MULTIPLIER,
    y: normalisedMousePosition.x * -TRANSLATE_MULTIPLIER,
    config: animationConfig
  });

  if (!modalDrawingsVariant) return null;

  return (
    <>
      {modalDrawingsVariant === 1 && (
        <>
          <animated.div
            style={{
              ...springs1
            }}
            className={cn(styles.drawing, styles.drawing1a)}
          >
            <Illustration illustration="flower" />
          </animated.div>

          <animated.div
            style={{
              ...springs2
            }}
            className={cn(styles.drawing, styles.drawing1b)}
          >
            <Illustration illustration="star" />
          </animated.div>
        </>
      )}

      {modalDrawingsVariant === 2 && (
        <>
          <animated.div
            style={{
              ...springs1
            }}
            className={cn(styles.drawing, styles.drawing2a)}
          >
            <Illustration illustration="sun" />
          </animated.div>

          <animated.div
            style={{
              ...springs3
            }}
            className={cn(styles.drawing, styles.drawing2b)}
          >
            <Illustration illustration="heart" />
          </animated.div>
        </>
      )}

      {modalDrawingsVariant === 3 && (
        <>
          <animated.div
            style={{
              ...springs1
            }}
            className={cn(styles.drawing, styles.drawing3a)}
          >
            <Illustration illustration="planet" />
          </animated.div>

          <animated.div
            style={{
              ...springs2
            }}
            className={cn(styles.drawing, styles.drawing3b)}
          >
            <Illustration illustration="butterfly" />
          </animated.div>

          <animated.div
            style={{
              ...springs3
            }}
            className={cn(styles.drawing, styles.drawing3c)}
          >
            <Illustration illustration="rainbow" />
          </animated.div>
        </>
      )}
    </>
  );
};

export default Drawings;

// extracted by mini-css-extract-plugin
export var address = "styles-module--address--561cd";
export var buttons = "styles-module--buttons--8f766";
export var container = "styles-module--container--a1a1e";
export var desktop = "1340px";
export var giant = "2200px";
export var imageContainer = "styles-module--imageContainer--c2d7c";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var logo = "styles-module--logo--cefcb";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var title = "styles-module--title--07824";
export var titleAndLogo = "styles-module--titleAndLogo--1636e";
import { IReviews } from '@mayfield/sanity';
import React from 'react';
import cn from 'classnames';
import {
  IntersectionAnimation,
  LayoutProvider
} from '@mayfield/common/components';
import * as styles from './styles.module.scss';
import Review from './components/Review';

interface IProps {
  data: IReviews;
  id?: string;
}

const Reviews = ({ data: { reviews, title = 'Reviews' }, id }: IProps) => {
  return (
    <div className={styles.container} id={id}>
      <LayoutProvider maxWidth padding>
        <h2 className={cn('h2', styles.title)}>{title}</h2>

        <div
          className={cn(styles.reviews, {
            [styles.singleReview]: reviews.length === 1
          })}
        >
          {reviews.map((review, i) => (
            <IntersectionAnimation
              delay={100 + i * 100}
              animation="fadeGrow"
              className={styles.review}
              key={i}
            >
              <Review {...review} />
            </IntersectionAnimation>
          ))}
        </div>
      </LayoutProvider>
    </div>
  );
};

export default Reviews;

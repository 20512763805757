// extracted by mini-css-extract-plugin
export var buttonContainer = "styles-module--buttonContainer--fa44f";
export var closeButton = "styles-module--closeButton--beacd";
export var container = "styles-module--container--f974e";
export var desktop = "1340px";
export var dropdownContainer = "styles-module--dropdownContainer--7392b";
export var dropdownLabel = "styles-module--dropdownLabel--7d05f";
export var formBackButton = "styles-module--formBackButton--090a5";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var open = "styles-module--open--dbc13";
export var sidebar = "styles-module--sidebar--03593";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var title = "styles-module--title--5071b";
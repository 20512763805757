import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { toKebabCase } from '@mayfield/common/utils';
import { IUniversalLinkWebsite } from '@mayfield/sanity';
import { Button, UniversalLink } from '@mayfield/common/components';
import * as styles from './styles.module.scss';

interface IProps {
  headerLinks: IUniversalLinkWebsite[];
  regions: string[];
  isActive: boolean;
}

const MobileMenu = ({ isActive, headerLinks, regions }: IProps) => {
  const [isFindCentreMenuOpen, setIsFindCentreMenuOpen] = useState(false);

  const openFindCentreMenu = () => setIsFindCentreMenuOpen(true);
  const closeFindCentreMenu = () => setIsFindCentreMenuOpen(false);

  // Close find centre menu when menu is closed
  useEffect(() => {
    if (!isActive) {
      closeFindCentreMenu();
    }
  }, [isActive]);

  return (
    <div className={cn(styles.container, { [styles.active]: isActive })}>
      <div className={styles.content}>
        <nav className={styles.menu}>
          <Button
            onClick={openFindCentreMenu}
            variant="textBig"
            iconRight="chevronRight"
            disabled={!isActive || isFindCentreMenuOpen}
          >
            Find Your Centre
          </Button>

          {headerLinks.map((link, i) => (
            <UniversalLink
              disabled={!isActive || isFindCentreMenuOpen}
              key={i}
              link={link}
              variant="textBig"
            />
          ))}
        </nav>

        <div
          className={cn(styles.menu, styles.findCentreMenu, {
            [styles.active]: isFindCentreMenuOpen
          })}
        >
          <Button
            className={styles.backButton}
            variant="text"
            iconLeft="chevronLeft"
            onClick={closeFindCentreMenu}
            disabled={!isFindCentreMenuOpen}
          >
            Back
          </Button>

          {regions.map((region, i) => (
            <Button
              key={i}
              variant="textBig"
              disabled={!isFindCentreMenuOpen}
              to={`/centres/${toKebabCase(region)}`}
            >
              {region}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;

import React from 'react';
import { SVG, TSvg } from '@mayfield/common/components';
import cn from 'classnames';
import { Link } from 'gatsby';
import { TThemeColor } from '@mayfield/sanity';
import * as styles from './styles.module.scss';

export type TButtonVariant = 'primary' | 'secondary' | 'text' | 'textBig';

export interface IButton {
  // Appearance
  children: React.ReactNode;
  variant?: TButtonVariant;
  theme?: 'lightOnDark' | 'darkOnLight';
  color?: TThemeColor;
  iconLeft?: TSvg;
  iconRight?: TSvg;
  disabled?: boolean;
  fluid?: boolean;
  loading?: boolean;
  // Functionality
  href?: string;
  to?: string;
  ariaLabel?: string;
  buttonType?: 'button' | 'reset' | 'submit';
  display?: boolean;
  onClick?: () => void;
  className?: string;
}

const Button = ({
  children,
  variant = 'primary',
  buttonType = 'button',
  theme = 'darkOnLight',
  color = 'sea',
  to,
  href,
  iconLeft,
  iconRight,
  disabled,
  fluid,
  loading,
  ariaLabel,
  display,
  onClick,
  className
}: IButton) => {
  let BtnElement: any;
  if (display) BtnElement = 'div';
  else if (to) BtnElement = Link;
  else if (href) BtnElement = 'a';
  else BtnElement = 'button';

  return (
    <BtnElement
      type={to || href ? '' : buttonType}
      href={href}
      aria-label={ariaLabel}
      target={href ? '_blank' : null}
      rel={href ? 'noreferrer noopener' : null}
      disabled={disabled}
      onClick={() => onClick && onClick()}
      tabIndex={disabled ? '-1' : 0}
      to={to}
      className={cn(styles.button, className, styles[variant], styles[color], {
        [styles.fluid]: fluid,
        [styles.loading]: loading,
        [styles.inverted]: theme === 'lightOnDark'
      })}
    >
      <div className={styles.content}>
        {iconLeft && <SVG svg={iconLeft} className={styles.icon} />}

        <span className={variant === 'textBig' ? 'button-big' : 'button-small'}>
          {children}
        </span>

        {iconRight && <SVG svg={iconRight} className={styles.icon} />}
      </div>

      {!display && (
        <div className={styles.loaderContainer}>
          <div className={styles.loader} />
        </div>
      )}
    </BtnElement>
  );
};

export default Button;

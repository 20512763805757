// extracted by mini-css-extract-plugin
export var backgroundButton = "styles-module--backgroundButton--88ca9";
export var buttonContainer = "styles-module--buttonContainer--5c82d";
export var closeModalButton = "styles-module--closeModalButton--77d37";
export var container = "styles-module--container--f8bdf";
export var desktop = "1340px";
export var fadeIn = "styles-module--fade-in--7beea";
export var giant = "2200px";
export var grid = "styles-module--grid--75b8d";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var modalContent = "styles-module--modalContent--722ea";
export var modalContentContainer = "styles-module--modalContentContainer--5b923";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var xIcon = "styles-module--xIcon--0ceeb";
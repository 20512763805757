import React, { ChangeEvent, useEffect } from 'react';
import { LayoutProvider, SVG } from '@mayfield/common/components';
import cn from 'classnames';
import { useAccordion } from '@mayfield/common/hooks';
import { TThemeColor } from '@mayfield/sanity';
import * as styles from './styles.module.scss';

interface IFilterOption {
  name: string;
  selected: boolean;
}

export interface IFilterCategory {
  name: string;
  options: IFilterOption[];
  radio?: boolean;
}
export interface IProps {
  filterCategories: IFilterCategory[];
  isFilterOpen?: boolean;
  setFilterCategories: React.Dispatch<React.SetStateAction<IFilterCategory[]>>;
  themeColor?: TThemeColor;
}

const SearchFilters = ({
  filterCategories,
  setFilterCategories,
  isFilterOpen,
  themeColor
}: IProps) => {
  const { containerHeight, contentRef, isExpanded, setIsExpanded } =
    useAccordion();

  const handleCheckboxChange = (
    e: ChangeEvent<HTMLInputElement>,
    categoryName: string,
    filterName: string
  ) => {
    setFilterCategories((prev) =>
      prev.map((category) => {
        if (category.name !== categoryName) {
          return category;
        }
        return {
          name: category.name,
          options: category.options.map((option) => {
            if (option.name !== filterName) {
              return option;
            }
            return {
              name: option.name,
              selected: e.target.checked
            };
          }),
          radio: category.radio
        };
      })
    );
  };

  const handleRadioChange = (categoryName: string, filterName: string) => {
    setFilterCategories((prev) =>
      prev.map((category) => {
        if (category.name !== categoryName) {
          return category;
        }
        return {
          name: category.name,
          options: category.options.map((option) => ({
            name: option.name,
            selected: option.name === filterName
          })),
          radio: category.radio
        };
      })
    );
  };

  useEffect(() => {
    if (isFilterOpen) {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  }, [isFilterOpen, setIsExpanded]);

  return (
    <>
      <button
        className={styles.filterToggle}
        style={{ color: `var(--color-${themeColor}-mid)` }}
        onClick={() => setIsExpanded((prev) => !prev)}
      >
        <span className={cn('b2')}>Refine search</span>
        <SVG
          svg="chevronDown"
          className={cn(styles.chevronIcon, {
            [styles.filterActive]: isExpanded
          })}
        />
      </button>

      <div
        className={styles.filtersContainer}
        style={{
          height: containerHeight,
          color: `var(--color-${themeColor}-dark)`
        }}
      >
        <div ref={contentRef}>
          <form className={styles.filtersContent} aria-hidden={!isExpanded}>
            <LayoutProvider grid>
              {filterCategories.map((category, i) => (
                <fieldset key={i} className={styles.category}>
                  <legend className={cn('caption', styles.categoryTitle)}>
                    {category.name}
                  </legend>

                  {category.options.map((option, j) => (
                    <label
                      className={cn('b2', styles.checkboxContainer)}
                      key={j}
                    >
                      {!category.radio && (
                        <input
                          className={styles.checkbox}
                          style={{
                            accentColor: `var(--color-${themeColor}-mid)`
                          }}
                          type="checkbox"
                          checked={option.selected}
                          onChange={(e) =>
                            handleCheckboxChange(e, category.name, option.name)
                          }
                          tabIndex={isExpanded ? 0 : -1}
                        />
                      )}

                      {category.radio && (
                        <input
                          className={styles.checkbox}
                          style={{
                            accentColor: `var(--color-${themeColor}-mid)`
                          }}
                          type="radio"
                          checked={option.selected}
                          onChange={() =>
                            handleRadioChange(category.name, option.name)
                          }
                          tabIndex={isExpanded ? 0 : -1}
                        />
                      )}
                      {option.name}
                    </label>
                  ))}
                </fieldset>
              ))}
            </LayoutProvider>
          </form>
        </div>
      </div>
    </>
  );
};

export default SearchFilters;

// extracted by mini-css-extract-plugin
export var centreTile = "styles-module--centreTile--df434";
export var container = "styles-module--container--43179";
export var controls = "styles-module--controls--67651";
export var desktop = "1340px";
export var giant = "2200px";
export var inlineBlock = "styles-module--inlineBlock--3212f";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var loadMoreButtonContainer = "styles-module--loadMoreButtonContainer--2b84d";
export var mapContainer = "styles-module--mapContainer--a41e7";
export var mobile = "400px";
export var noResultsText = "styles-module--noResultsText--72d0e";
export var pageNavigation = "styles-module--pageNavigation--ab17d";
export var searchAndFilter = "styles-module--searchAndFilter--379d8";
export var searchResults = "styles-module--searchResults--4b227";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var title = "styles-module--title--66b1d";
export var titleAndSearch = "styles-module--titleAndSearch--6041f";
export var topTeam = "styles-module--topTeam--61137";
// extracted by mini-css-extract-plugin
export var animatedGraphic = "styles-module--animatedGraphic--3dcaf";
export var block1 = "styles-module--block1--a2350";
export var block2 = "styles-module--block2--8fd98";
export var block3 = "styles-module--block3--ba177";
export var blockContent = "styles-module--blockContent--80645";
export var blockText = "styles-module--blockText--ed54d";
export var blockTitle = "styles-module--blockTitle--a3956";
export var chalkArrow = "styles-module--chalkArrow--36316";
export var chalkArrow1 = "styles-module--chalkArrow1--13bd8";
export var chalkArrow2 = "styles-module--chalkArrow2--0bf27";
export var container = "styles-module--container--bc4b8";
export var content = "styles-module--content--03729";
export var description = "styles-module--description--dc5bf";
export var desktop = "1340px";
export var flowerVideo = "styles-module--flowerVideo--f13d9";
export var giant = "2200px";
export var image = "styles-module--image--fcad2";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var starVideo = "styles-module--starVideo--76550";
export var tablet = "769px";
export var ticTacToeVideo = "styles-module--ticTacToeVideo--9d95c";
export var title = "styles-module--title--aeafc";
export var topBlock = "styles-module--topBlock--212f6";
import React from 'react';
import { ITopicSelector } from '@mayfield/sanity';
import { useBreakpoints } from '@mayfield/common/hooks';
import TopicSelectorDesktop from './components/Desktop';
import TopicSelectorMobile from './components/Mobile';

interface IProps {
  data: ITopicSelector;
}

const TopicSelector = ({ data: { topics } }: IProps) => {
  const colors = [
    'var(--color-sky-mid',
    'var(--color-floral-mid',
    'var(--color-grass-mid',
    'var(--color-sea-mid',
    'var(--color-earth-mid'
  ];

  const { largeTablet } = useBreakpoints();

  return (
    <>
      {largeTablet ? (
        <TopicSelectorDesktop topics={topics} colors={colors} />
      ) : (
        <TopicSelectorMobile topics={topics} colors={colors} />
      )}
    </>
  );
};

export default TopicSelector;

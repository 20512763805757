// extracted by mini-css-extract-plugin
export var animatedGraphic = "styles-module--animatedGraphic--9b81c";
export var block1 = "styles-module--block1--02104";
export var block2 = "styles-module--block2--9e39c";
export var block3 = "styles-module--block3--c4fb5";
export var block4 = "styles-module--block4--53a41";
export var blockContent = "styles-module--blockContent--e42d7";
export var blockText = "styles-module--blockText--22252";
export var blockTitle = "styles-module--blockTitle--e365c";
export var chalkArrow = "styles-module--chalkArrow--60c4d";
export var container = "styles-module--container--a7c57";
export var content = "styles-module--content--4ed56";
export var description = "styles-module--description--62bfa";
export var desktop = "1340px";
export var giant = "2200px";
export var girlVideo = "styles-module--girlVideo--cd2f1";
export var houseVideo = "styles-module--houseVideo--482f7";
export var image = "styles-module--image--03b3f";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var pencilVideo = "styles-module--pencilVideo--759a6";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var title = "styles-module--title--ff748";
export var topBlock = "styles-module--topBlock--653c2";
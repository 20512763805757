import React from 'react';
import {
  NewsAtf,
  ThemeColorVariableInjector
} from '@mayfield/website/components';
import { graphql, useStaticQuery } from 'gatsby';
import { IAltImage } from '@mayfield/sanity';
import Title from './components/Title';
import ArticleGrid from './components/ArticleGrid';
import FeaturedArticles from './components/FeaturedArticles';

export interface IArticleTileData {
  title: string;
  image: IAltImage;
  description: string;
  date: string;
  slug: {
    current: string;
  };
  state: string;
  categories: {
    title: string;
  }[];
}

interface IQueryResults {
  allSanityNewsArticle: {
    nodes: IArticleTileData[];
  };
}

const News = () => {
  const {
    allSanityNewsArticle: { nodes: allArticles }
  } = useStaticQuery(query) as IQueryResults;

  const mostRecentArticle = allArticles[0];
  const { date, image, title, description, slug } = mostRecentArticle;

  return (
    <>
      <ThemeColorVariableInjector themeColor="sky" />

      <Title />

      <NewsAtf
        date={date}
        image={image}
        title={title}
        description={description}
        themeColor="sky"
        slug={slug.current}
      />

      <FeaturedArticles />

      <ArticleGrid articles={allArticles} />
    </>
  );
};

export default News;

const query = graphql`
  query NewsPageQuery {
    allSanityNewsArticle(sort: { date: DESC }) {
      nodes {
        title
        image {
          altText
          asset {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        description
        date
        slug {
          current
        }
        categories {
          title
        }
        state
      }
    }
  }
`;

import React from 'react';
import * as styles from './styles.module.scss';
import cn from 'classnames';
import { IRichTextVideo } from '@mayfield/sanity';

const VideoComponent = ({ value }: IRichTextVideo) => {
  const { video, credit, caption, aspectRatio } = value || {};

  return (
    <figure
      style={{
        aspectRatio: `${aspectRatio?.ratio ? aspectRatio.ratio : `1`}`
      }}
    >
      {video?.url && (
        <video className={styles.video} autoPlay muted loop playsInline>
          <source src={value.video.url} type="video/mp4" />
        </video>
      )}

      {caption && <p className={cn('b2', styles.caption)}>{caption}</p>}
      {credit && <p className={cn('caption', styles.credit)}>{credit}</p>}
    </figure>
  );
};

export default VideoComponent;

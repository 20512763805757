import * as breakpoints from '@mayfield/common/styles/breakpoints.module.scss';
import { IFont, ITypestyle } from '@mayfield/sanity';
import { pxStringToNumber } from '@mayfield/common/utils';

const getTypestyleCss = (
  typestyles: ITypestyle[],
  getFontById: (id: string) => IFont | undefined
) => {
  const TABLET_BREAKPOINT = breakpoints.tablet; // 796px
  const DESKTOP_BREAKPOINT = breakpoints.largeTablet; // 1025px

  const minTabletPx = pxStringToNumber(TABLET_BREAKPOINT);
  const minDesktopPx = pxStringToNumber(DESKTOP_BREAKPOINT);

  if (!typestyles) return ``;

  let css = ``;

  typestyles.forEach((typestyle) => {
    const font = getFontById(typestyle.font._id);
    if (!font) return;

    css += `
				.${typestyle.className} {
					font-family: ${font?.title};
					font-size: ${typestyle.fontSizes.mobile};
					line-height: ${typestyle.lineHeight};
					letter-spacing: ${typestyle.letterSpacing};
					text-transform: ${typestyle.uppercase ? 'uppercase' : 'none'};
					text-decoration: ${typestyle.underline ? 'underline' : 'none'};
				}
				@media (min-width: ${minTabletPx}px) {
					.${typestyle.className} {
						font-size: ${typestyle.fontSizes.tablet};
					}
				}
				@media (min-width: ${minDesktopPx}px) {
					.${typestyle.className} {
						font-size: ${typestyle.fontSizes.desktop};
					}
				}
			`;
  });

  return css;
};

export default getTypestyleCss;

// extracted by mini-css-extract-plugin
export var container = "styles-module--container--166f7";
export var desktop = "1340px";
export var giant = "2200px";
export var jobTile = "styles-module--jobTile--405f7";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var loadMoreButtonContainer = "styles-module--loadMoreButtonContainer--3b65f";
export var mobile = "400px";
export var noJobsText = "styles-module--noJobsText--3ec96";
export var searchAndFilter = "styles-module--searchAndFilter--92137";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var tiles = "styles-module--tiles--1e0a5";
export var title = "styles-module--title--1cb16";
export var titleAndSearch = "styles-module--titleAndSearch--48d0a";
export var topTeam = "styles-module--topTeam--35c54";
import React from 'react';
import { IImageGallery } from '@mayfield/sanity';
import cn from 'classnames';
import {
  LayoutProvider,
  PaginationControls
} from '@mayfield/common/components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useEmbla } from '@mayfield/website/hooks';
import * as styles from './styles.module.scss';

interface IProps {
  data: IImageGallery;
  id?: string;
}

const ImageGallery = ({ data: { galleryItems }, id }: IProps) => {
  const {
    activeSlideIndex,
    setActiveSlideIndex,
    emblaRef,
    scrollNext,
    scrollPrev
  } = useEmbla(galleryItems.length);

  const currentCaption = galleryItems[activeSlideIndex].caption;

  return (
    <div className={styles.container} id={id}>
      <LayoutProvider padding maxWidth grid>
        <div className={styles.carouselContainer}>
          <div className={styles.carousel} ref={emblaRef}>
            <div className={styles.carouselContent}>
              {galleryItems.map(({ image }, i) => (
                <div className={styles.slide} key={i}>
                  <GatsbyImage
                    alt={image.altText || ''}
                    image={image.asset.gatsbyImageData}
                  />
                </div>
              ))}
            </div>
          </div>

          <PaginationControls
            activeIndex={activeSlideIndex}
            numberOfItems={galleryItems.length}
            scrollNext={scrollNext}
            scrollPrev={scrollPrev}
            setActiveIndex={setActiveSlideIndex}
          />

          {currentCaption && (
            <p className={cn('b2', styles.caption)}>{currentCaption}</p>
          )}
        </div>
      </LayoutProvider>
    </div>
  );
};

export default ImageGallery;

// extracted by mini-css-extract-plugin
export var active = "styles-module--active--fca20";
export var container = "styles-module--container--f6010";
export var desktop = "1340px";
export var giant = "2200px";
export var imageContainer = "styles-module--imageContainer--7bf5c";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var menu = "styles-module--menu--4164f";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var text = "styles-module--text--391bf";
export var topicButton = "styles-module--topicButton--320e7";
export var topicContent = "styles-module--topicContent--f0c60";
export var topicContentContainer = "styles-module--topicContentContainer--dff46";
export var topicListItem = "styles-module--topicListItem--96766";
import React from 'react';
import { IAltImage, ISlug } from '@mayfield/sanity';
import cn from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import { formatDateString } from '@mayfield/common/utils';
import { Button } from '@mayfield/common/components';
import { Link } from 'gatsby';
import * as styles from './styles.module.scss';

export interface IFeaturedArticleTileProps {
  title: string;
  slug: ISlug;
  image: IAltImage;
  description: string;
  date: string;
  className?: string;
}

const FeaturedArticleTile = ({
  date,
  description,
  image,
  slug,
  title,
  className
}: IFeaturedArticleTileProps) => {
  const toPath = `/article/${slug.current}`;

  return (
    <div className={cn(className, styles.container)}>
      <Link to={toPath} aria-label="Read article">
        <div className={styles.imageContainer}>
          <div className={styles.imageContent}>
            <GatsbyImage
              alt={image.altText || ''}
              image={image.asset.gatsbyImageData}
              style={{ width: '100%', height: '100%' }}
            />
          </div>
        </div>
      </Link>

      <div className={cn('caption', styles.date)}>{formatDateString(date)}</div>

      <Link to={toPath}>
        <h3 className={cn('h3', styles.title)}>{title}</h3>
      </Link>

      <p className={cn('b2', styles.description)}>{description}</p>

      <div className={styles.buttonContainer}>
        <Button color="sky" variant="secondary" to={toPath}>
          Read Full Article
        </Button>
      </div>
    </div>
  );
};

export default FeaturedArticleTile;

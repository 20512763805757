import { TThemeColor } from '@mayfield/sanity';

const themeColor: TThemeColor = 'sky';

const outline =
  '0px 0px 0px 2px var(--color-earth),0px 0px 0px 4px var(--color-sky-dark);';

export default `
  .fc-event {
    font-size:0.85em;
    line-height:1.4;
    border-radius:4px;
    padding: 8px 5px 9px;
    border: 1px solid #d9d9d9;
    text-align: center;
    color: var(--color-${themeColor}-dark);
    margin:5px;
    transition: 0.2s;
    pointer-events: none;
  }

  .fc-left {
    line-height: 2em;
  }

  .fc-event.available-tour-time {
    pointer-events: all;
  }

  .fc-event.available-tour-time:hover {
    background: var(--color-${themeColor}-mid);
    color: var(--color-buff);
  }

  .childcarecrm-form {
    padding: 0
  }

  .childcarecrm-form__step {
    color: var(--color-${themeColor}-dark);
  }

  .childcarecrm-form button{
    color: var(--color-buff);
    background: var(--color-${themeColor}-dark);
    border-radius: 100px;
    padding: 0.5rem 1rem;
    font-family: Moranga Regular;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.16px;
    transition: 0.2s;
  }

  .childcarecrm-form button:hover {
    background: var(--color-${themeColor}-mid);
  }

  .childcarecrm-form button:focus-visible {
    box-shadow: ${outline};
  }

  .childcarecrm-form__disclaimer {
    margin-bottom: 1rem;
  }

  #childcarecrm-form__confirm-button {
    margin-right: 1rem;
  }

  .childcarecrm-form__control {
    padding: 0.75rem 1rem;
    border-radius: 100px;
    border: 1px solid var(--color-buff-darker);
    background: var(--color-buff-darker);
    color: var(--color-${themeColor}-dark);
    transition: 0.2s;
  }

  .childcarecrm-form__control--tour-time {
    border-radius: 1rem;
    padding: 1.5rem;
  }

  .childcarecrm-form__control:focus {
    border: 1px solid var(--color-${themeColor}-dark);
  }

  .childcarecrm-form__field > label {
    margin-bottom: 0.75rem;
    display: block;
  }
  .childcarecrm-form__field {
    margin-bottom: 1rem;
  }

  .childcarecrm-form__pst-type-display {
    margin-bottom: 0.5rem;
  }

  .chosenTimeDisplay {
    margin: 1rem 0;
    font-size: 32px;
    display: block;
  }

  .childcarecrm-form__success-message h1 {
    margin-bottom: 1rem;
  }
  
  .fc-unthemed th, .fc-unthemed td, .fc-unthemed thead, .fc-unthemed tbody, .fc-unthemed .fc-divider, .fc-unthemed .fc-row, .fc-unthemed .fc-content, .fc-unthemed .fc-popover, .fc-unthemed .fc-list-view, .fc-unthemed .fc-list-heading td { border-color:transparent!important;}

  #childcarecrm-tour-form table td:nth-child(6), #childcarecrm-tour-form table td:nth-child(7){
    display:none;
  }

  #childcarecrm-tour-form table th:nth-child(6), #childcarecrm-tour-form table th:nth-child(7){
    display:none;
  }

  

  

`;

import React, { useState } from 'react';
import { ITopicSelectorTopic } from '@mayfield/sanity';
import MobileTopic from './components/Topic';

export type TActiveTopicIndex = number | null;

interface IProps {
  topics: ITopicSelectorTopic[];
  colors: string[];
}

const TopicSelectorMobile = ({ colors, topics }: IProps) => {
  const [activeTopicIndex, setActiveTopicIndex] =
    useState<TActiveTopicIndex>(null);

  return (
    <>
      {topics.map((topic, i) => {
        const handleClick = () => {
          if (activeTopicIndex === i) {
            setActiveTopicIndex(null);
          } else {
            setActiveTopicIndex(i);
          }
        };

        return (
          <MobileTopic
            key={i}
            topic={topic}
            backgroundColor={colors[i % colors.length]}
            handleClick={handleClick}
            isActive={activeTopicIndex === i}
          />
        );
      })}
    </>
  );
};

export default TopicSelectorMobile;

import React from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';

interface IProps {
  text?: string;
  textStyle?: string;
  className?: string;
}

const LoadingText = ({
  text = 'Loading',
  textStyle = 'caption',
  className
}: IProps) => {
  return (
    <p className={cn(textStyle, className)}>
      {text}
      <span className={styles.dot1}>.</span>
      <span className={styles.dot2}>.</span>
      <span className={styles.dot3}>.</span>
    </p>
  );
};

export default LoadingText;

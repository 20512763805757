import React from 'react';
import { IAtfImageText } from '@mayfield/sanity';
import cn from 'classnames';
import {
  IntersectionAnimation,
  LayoutProvider,
  ResponsiveImage
} from '@mayfield/common/components';
import { useIntersectionAnimation } from '@mayfield/common/hooks';
import * as styles from './styles.module.scss';

interface IProps {
  data: IAtfImageText;
}

const AtfImageText = ({
  data: { title, subtitle, backgroundImage, shortVersion }
}: IProps) => {
  const { inView, ref } = useIntersectionAnimation();

  return (
    <div
      className={cn(styles.container, { [styles.shortVersion]: shortVersion })}
    >
      <ResponsiveImage
        imageData={backgroundImage}
        style={{ position: 'absolute', inset: 0 }}
        loading="eager"
      />

      <div className={styles.contrast}>
        <LayoutProvider maxWidth padding grid>
          <div className={styles.content}>
            <div>
              <IntersectionAnimation
                className={styles.titleContainer}
                trigger={inView}
                delay={200}
              >
                <h1 ref={ref} className={cn('d1', styles.title)}>
                  {title}
                </h1>
              </IntersectionAnimation>

              {subtitle && (
                <IntersectionAnimation trigger={inView} delay={400}>
                  <p className={cn('h4')}>{subtitle}</p>
                </IntersectionAnimation>
              )}
            </div>
          </div>
        </LayoutProvider>
      </div>
    </div>
  );
};

export default AtfImageText;

// extracted by mini-css-extract-plugin
export var bookGraphic = "styles-module--bookGraphic--e29c3";
export var bookGraphicDesktop = "styles-module--bookGraphicDesktop--16c6c";
export var bookGraphicMobile = "styles-module--bookGraphicMobile--bab8a";
export var container = "styles-module--container--66e8d";
export var cta = "styles-module--cta--13641";
export var desktop = "1340px";
export var giant = "2200px";
export var imageContainer = "styles-module--imageContainer--dfa3a";
export var imageDesktop = "styles-module--imageDesktop--bb0ee";
export var imageMobile = "styles-module--imageMobile--3b1f4";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
import React from 'react';
import {
  Theme,
  Header,
  Footer,
  BookATourSidebar,
  Modal
} from '@mayfield/website/components';
import { NoJs } from '@mayfield/common/components';
import 'modern-normalize/modern-normalize.css';
import '@mayfield/common/styles/global.css';

interface IProps {
  children: React.ReactNode;
  className?: string;
}

const Layout = ({ children }: IProps) => (
  <>
    <NoJs />
    <Theme />

    <Header />
    <BookATourSidebar />
    {children}
    <Footer />
    <Modal />
  </>
);

export default Layout;

import React from 'react';
import cn from 'classnames';
import { IAltImage, ISlug } from '@mayfield/sanity';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { formatDateString } from '@mayfield/common/utils';
import * as styles from './styles.module.scss';

interface IProps {
  className?: string;
  image: IAltImage;
  title: string;
  date: string;
  slug: ISlug;
}

const ArticleTile = ({ className, image, title, slug, date }: IProps) => {
  const formattedDate = formatDateString(date);

  return (
    <Link
      to={`/article/${slug.current}`}
      className={cn(styles.container, className)}
    >
      <div className={styles.imageContainer}>
        <div className={styles.imageContent}>
          <GatsbyImage
            alt={image.altText || ''}
            image={image.asset.gatsbyImageData}
            style={{ width: '100%', height: '100%' }}
          />
        </div>
      </div>

      <h3 className={cn('h4', styles.title)}>{title}</h3>

      <p className={cn('caption')}>{formattedDate}</p>
    </Link>
  );
};

export default ArticleTile;

import React, { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

type TNumberOfChildren = number | null;

export interface ICCSResultsContext {
  numberOfChildren: TNumberOfChildren;
  setNumberOfChildren: React.Dispatch<React.SetStateAction<TNumberOfChildren>>;
}

export const CCSResultsContext = createContext<ICCSResultsContext>(
  {} as ICCSResultsContext
);

interface IProps {
  children: React.ReactNode;
}

const CCSResultsProvider = ({ children }: IProps) => {
  const [numberOfChildren, setNumberOfChildren] =
    useState<TNumberOfChildren>(null);

  const contextProps = useMemo(
    () => ({
      numberOfChildren,
      setNumberOfChildren
    }),
    [numberOfChildren, setNumberOfChildren]
  );

  return (
    <CCSResultsContext.Provider value={contextProps}>
      {children}
    </CCSResultsContext.Provider>
  );
};

CCSResultsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default CCSResultsProvider;

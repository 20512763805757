import React from 'react';
import { IAltImage, TThemeColor } from '@mayfield/sanity';
import {
  Button,
  IntersectionAnimation,
  LayoutProvider
} from '@mayfield/common/components';
import cn from 'classnames';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { formatDateString } from '@mayfield/common/utils';
import { useIntersectionAnimation } from '@mayfield/common/hooks';
import * as styles from './styles.module.scss';

interface IProps {
  date: string;
  title: string;
  description?: string;
  image: IAltImage;
  themeColor: TThemeColor;
  slug?: string; // If provided, uses inverted theme + links to article
}

const NewsAtf = ({
  date,
  description,
  image,
  themeColor,
  title,
  slug
}: IProps) => {
  const wrapWithLink = (element: React.JSX.Element) => {
    if (!slug) {
      return element;
    }

    return <Link to={`/article/${slug}`}>{element}</Link>;
  };

  const { inView, ref } = useIntersectionAnimation();

  const isOnNewsPage = slug ? true : false;
  const delayOffset = isOnNewsPage ? 150 : 0;

  return (
    <div ref={ref} className={cn(styles.container, { [styles.hasLink]: slug })}>
      <LayoutProvider padding maxWidth grid className={styles.grid}>
        <IntersectionAnimation
          delay={delayOffset}
          trigger={inView}
          className={styles.imageContainer}
        >
          {wrapWithLink(
            <GatsbyImage
              image={image.asset.gatsbyImageData}
              style={{ position: 'absolute', inset: 0 }}
              alt={image.altText || ''}
            />
          )}
        </IntersectionAnimation>

        <div className={styles.text}>
          <IntersectionAnimation
            delay={150 + delayOffset}
            trigger={inView}
            className={cn('caption', styles.date)}
          >
            {formatDateString(date)}
          </IntersectionAnimation>

          {wrapWithLink(
            <IntersectionAnimation delay={300 + delayOffset} trigger={inView}>
              <h1 className={cn('h3', styles.title, { [styles.isLink]: slug })}>
                {title}
              </h1>
            </IntersectionAnimation>
          )}

          <IntersectionAnimation delay={450 + delayOffset} trigger={inView}>
            <p className={cn('b1')}>{description}</p>
          </IntersectionAnimation>

          {slug && (
            <IntersectionAnimation delay={600 + delayOffset} trigger={inView}>
              <Button
                color={themeColor}
                variant="secondary"
                className={styles.articleLinkButton}
                to={`/article/${slug}`}
              >
                Read Full Article
              </Button>
            </IntersectionAnimation>
          )}
        </div>
      </LayoutProvider>
    </div>
  );
};

export default NewsAtf;

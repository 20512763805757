import React from 'react';
import { PortableText } from '@portabletext/react';
import {
  IRichTextButton,
  IRichTextEmbed,
  IRichTextImage,
  IRichTextVideo
} from '@mayfield/sanity';
import cn from 'classnames';
import EmbedComponent from './components/EmbedComponent';
import VideoComponent from './components/VideoComponent';
import ImageComponent from './components/ImageComponent';
import PortableTextButton from './components/PortableTextButton';
import * as styles from './styles.module.scss';

export interface ISanityConfig {
  projectId: string;
  dataset: string;
}

interface ICustomComponentsConfig {
  gatsbyImageComponent: React.FunctionComponent<any>; // import { GatsbyImage } from 'gatsby-plugin-image';
  getGatsbyImageData: (imageId: string, {}, sanityConfig: ISanityConfig) => any; // import { getGatsbyImageData } from 'gatsby-source-sanity';
  textClassName?: string;
}

interface IProps extends ICustomComponentsConfig {
  containerClassName?: string;
  rawText: any;
}

interface IRichTextProps {
  value: IRichTextButton;
}

const customComponents = ({
  gatsbyImageComponent,
  getGatsbyImageData,
  textClassName
}: ICustomComponentsConfig) => ({
  listItem: {
    number: ({ value, children }: any) => {
      const { level } = value || {};

      const nodeStyle = children?.props?.node?.style || textClassName || 'b2';

      const getNumericLevelClass = () => {
        switch (level) {
          case 1:
            return styles.numericLevel1;
          case 2:
            return styles.numericLevel2;
          case 3:
            return styles.numericLevel3;
          default:
            return styles.numericLevel1;
        }
      };

      return (
        <li className={cn(getNumericLevelClass(), nodeStyle)}>{children}</li>
      );
    },
    bullet: ({ children, value }: any) => {
      const { level } = value || {};

      const nodeStyle = children?.props?.node?.style || textClassName || 'b2';

      const getBulletLevelClass = () => {
        switch (level) {
          case 1:
            return styles.bulletLevel1;
          case 2:
            return styles.bulletLevel2;
          case 3:
            return styles.bulletLevel3;
          default:
            return styles.bulletLevel1;
        }
      };

      return (
        <li className={cn(getBulletLevelClass(), nodeStyle)}>{children}</li>
      );
    }
  },
  types: {
    richTextImage: ({ value }: IRichTextImage) => (
      <div className={styles.mediaContainer}>
        <ImageComponent
          gatsbyImageComponent={gatsbyImageComponent}
          getGatsbyImageData={getGatsbyImageData}
          value={value}
        />
      </div>
    ),
    richTextVideo: ({ value }: IRichTextVideo) => (
      <div className={styles.mediaContainer}>
        <VideoComponent value={value} />
      </div>
    ),
    richTextEmbed: ({ value }: IRichTextEmbed) => (
      <div className={styles.mediaContainer}>
        <EmbedComponent value={value} />
      </div>
    ),
    richTextButton: ({ value }: IRichTextProps) => (
      <div className={styles.buttonContainer}>
        <PortableTextButton data={value} />
      </div>
    )
  },
  block: {
    normal: ({ children }: any) => (
      <p className={textClassName || 'b2'}>{children}</p>
    ),
    d1: ({ children }: any) => <h2 className={'d1'}>{children}</h2>,
    h1: ({ children }: any) => <h2 className={'h1'}>{children}</h2>,
    h2: ({ children }: any) => <h3 className={'h2'}>{children}</h3>,
    h3: ({ children }: any) => <h4 className={'h3'}>{children}</h4>,
    b1: ({ children }: any) => <p className={'b1'}>{children}</p>,
    b2: ({ children }: any) => <p className={'b2'}>{children}</p>,
    caption: ({ children }: any) => <p className={'caption'}>{children}</p>,
    button: ({ children }: any) => <p className={'button'}>{children}</p>,
    underline: ({ children }: any) => <p className={'underline'}>{children}</p>,
    blockquote: ({ children }: any) => (
      <p className={cn('h1', styles.blockQuote)}>{children}</p>
    )
  },
  marks: {
    link: ({ children, value }: any) => {
      const rel = value.href?.startsWith('/')
        ? undefined
        : 'noreferrer noopener';
      return (
        <a style={{ textDecoration: `underline` }} href={value.href} rel={rel}>
          {children}
        </a>
      );
    },
    fileLink: ({ children, value }: any) => {
      return (
        <a
          style={{ textDecoration: 'underline' }}
          href={value.file.asset.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
        </a>
      );
    }
  }
});

const PortableTextRenderer = ({
  rawText,
  containerClassName,
  textClassName,
  gatsbyImageComponent,
  getGatsbyImageData
}: IProps) => (
  <div className={cn(styles.container, containerClassName)}>
    <PortableText
      value={rawText}
      components={customComponents({
        textClassName,
        gatsbyImageComponent,
        getGatsbyImageData
      })}
    />
  </div>
);

export default PortableTextRenderer;

// extracted by mini-css-extract-plugin
export var accordion = "styles-module--accordion--7ada3";
export var active = "styles-module--active--1f029";
export var button = "styles-module--button--d1a9a";
export var chevron = "styles-module--chevron--00aa5";
export var chevronCircle = "styles-module--chevronCircle--8de0d";
export var container = "styles-module--container--a248a";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var result = "styles-module--result--291b1";
export var results = "styles-module--results--1b691";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var titleContainer = "styles-module--titleContainer--9d562";
// extracted by mini-css-extract-plugin
export var articleLinkButton = "styles-module--articleLinkButton--ecdd0";
export var container = "styles-module--container--4bfbe";
export var date = "styles-module--date--1e2a8";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--f8e3a";
export var hasLink = "styles-module--hasLink--31000";
export var imageContainer = "styles-module--imageContainer--701c9";
export var isLink = "styles-module--isLink--64f47";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var text = "styles-module--text--428c5";
export var title = "styles-module--title--578db";
import React, { useEffect, useState } from 'react';
import { LayoutProvider, TextInput } from '@mayfield/common/components';
import { useApp } from '@mayfield/website/hooks';
import { IPassword } from '@mayfield/sanity';
import cn from 'classnames';
import * as styles from './styles.module.scss';

interface IProps {
  data: IPassword;
}

const Password = ({ data: { password } }: IProps) => {
  const [input, setInput] = useState(``);
  const [hasError, setHasError] = useState(false);

  const { setPagePasswordStatus, windowLocation } = useApp();

  const handleSubmit = () => {
    const pathname = windowLocation?.pathname || '';

    if (input === password) {
      setPagePasswordStatus((prev) => ({
        ...prev,
        [pathname]: 'unlocked'
      }));
      return;
    }

    setHasError(true);
  };

  useEffect(() => {
    setHasError(false);
  }, [input]);

  return (
    <div className={styles.container}>
      <LayoutProvider padding maxWidth grid>
        <div className={styles.columnContainer}>
          <div className={styles.mainContent}>
            <h2 className={cn('h4', styles.header)}>This content is gated.</h2>
            <p className={cn('b2', styles.subheader)}>
              To view, please enter the password.
            </p>

            <TextInput
              placeholder="Enter password"
              type="password"
              value={input}
              onChange={setInput}
              hasError={hasError}
              onClick={handleSubmit}
              warningMessage="Incorrect password"
            />
          </div>

          <p
            className={cn('caption', styles.infoText)}
          >{`Passwords are provided by individual centre.\nDon’t have a password? Contact your centre for access.`}</p>
        </div>
      </LayoutProvider>
    </div>
  );
};

export default Password;

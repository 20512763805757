// extracted by mini-css-extract-plugin
export var buttonContainer = "styles-module--buttonContainer--64fd5";
export var container = "styles-module--container--a8e67";
export var date = "styles-module--date--3f378";
export var description = "styles-module--description--72431";
export var desktop = "1340px";
export var giant = "2200px";
export var imageContainer = "styles-module--imageContainer--d654c";
export var imageContent = "styles-module--imageContent--f204a";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var title = "styles-module--title--d772a";
// extracted by mini-css-extract-plugin
export var container = "styles-module--container--67ac7";
export var content = "styles-module--content--1f8dc";
export var contrast = "styles-module--contrast--1eb14";
export var desktop = "1340px";
export var giant = "2200px";
export var headerText = "styles-module--headerText--58058";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var subtitleText = "styles-module--subtitleText--285d6";
export var tablet = "769px";
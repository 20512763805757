// extracted by mini-css-extract-plugin
export var accordion = "styles-module--accordion--c5d84";
export var active = "styles-module--active--fe18e";
export var button = "styles-module--button--eace9";
export var chevron = "styles-module--chevron--7c7cd";
export var chevronCircle = "styles-module--chevronCircle--bfe04";
export var container = "styles-module--container--1b73d";
export var desktop = "1340px";
export var formContent = "styles-module--formContent--af1ef";
export var formInput = "styles-module--formInput--c3100";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var textInput = "styles-module--textInput--fddf1";
export var titleContainer = "styles-module--titleContainer--8e1f9";
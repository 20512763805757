import { TState, TThemeColor, TCity } from '@mayfield/sanity';

export const states: TState[] = [
  'Queensland',
  'Victoria',
  'South Australia',
  'ACT',
  'New South Wales',
  'Northern Territory',
  'Tasmania',
  'Western Australia'
];

export const cities: TCity[] = [
  'None',
  'Melbourne',
  'Adelaide',
  'Brisbane',
  'Gold Coast',
  'Cairns'
];

export const themeColors: TThemeColor[] = ['earth', 'grass', 'floral', 'sea'];

import { useState, useEffect } from 'react';

const useMousePosition = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const getNormalisedMousePosition = () => {
    const { innerWidth, innerHeight } = window;
    const { x, y } = mousePosition;

    return {
      x: (x - innerWidth / 2) / (innerWidth / 2),
      y: (y - innerHeight / 2) / (innerHeight / 2)
    };
  };

  const normalisedMousePosition = getNormalisedMousePosition();

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      setMousePosition({
        x: event.clientX,
        y: event.clientY
      });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return { mousePosition, normalisedMousePosition };
};

export default useMousePosition;

import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import cn from 'classnames';
import { IntersectionAnimation } from '@mayfield/common/components';
import { LoadingText } from '@mayfield/website/components';
import formStyles from './formStyles';
import * as styles from './styles.module.scss';

interface IProps {
  centreId: string;
  customerId: string;
  centreTitle: string;
}

type TIsFormLoaded = true | false | 'error';

const BookATourForm = ({ centreId, customerId, centreTitle }: IProps) => {
  const LINELEADER_FORM_ID = '5247d740-3926-4f6a-b814-305fe2f9aa95';
  const FORM_CONTAINER_ID = 'childcarecrm-tour-form';

  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [isFormLoaded, setIsFormLoaded] = useState<TIsFormLoaded>(false);

  const installScript = useCallback(() => {
    if (isScriptLoaded) {
      return;
    }

    const installedScript = document.querySelector('#lineleader-script');

    if (installedScript) {
      setIsScriptLoaded(true);
      return;
    }

    const lineleaderScript = document.createElement('script');
    lineleaderScript.dataset.lineleaderScript = '';
    lineleaderScript.id = 'lineleader-script';
    lineleaderScript.src = 'https://live.childcarecrm.com.au/form/v1.js';
    lineleaderScript.async = true;
    lineleaderScript.setAttribute('data-cfasync', 'false');

    lineleaderScript.onload = () => {
      setIsScriptLoaded(true);
    };

    document.head.appendChild(lineleaderScript);
  }, [isScriptLoaded]);

  const loadForm = () => {
    if (!centreId || !customerId) {
      setIsFormLoaded('error');
      return;
    }

    const pushSubmissionDataLayerEvent = () => {
      window.dataLayer = window.dataLayer || [];

      window.dataLayer.push({
        event: 'parent_scheduled_tour',
        centre: centreTitle
      });
    };

    const crmForm = new window.ChildCareCRM.Forms.Form({
      centerId: centreId,
      customerId,
      formId: LINELEADER_FORM_ID,
      selector: `#${FORM_CONTAINER_ID}`,
      labels: {
        next: 'Next',
        submit: 'Submit',
        additional_child: 'Additional Child',
        remove_child: 'Remove Child',
        change_time: 'Change Time',
        disclaimer_text:
          'By clicking "Submit" I agree to be sent text and email notifications from <% center_name %>.'
      },
      callback: pushSubmissionDataLayerEvent,
      show_confirmation: true
    });

    crmForm.render();
  };

  useEffect(() => {
    installScript();
  }, [installScript]);

  useEffect(() => {
    if (!isScriptLoaded) {
      return;
    }

    loadForm();
  }, [isScriptLoaded]);

  // Need to remove flatpickr calendars from DOM on unmount
  useEffect(() => {
    return () => {
      const flatpickrCalendar = document.querySelectorAll(
        '.flatpickr-calendar'
      );
      flatpickrCalendar.forEach((calendar) => calendar.remove());
    };
  }, []);

  // Check if form has been rendered
  useEffect(() => {
    const targetNode = document.getElementById(FORM_CONTAINER_ID);

    const observerOptions = {
      childList: true,
      attributes: false,
      subtree: false
    };

    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          setIsFormLoaded(true);
        }
      }
    });

    observer.observe(targetNode!, observerOptions);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className={styles.container}>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          href="https://live.childcarecrm.com.au/form/v1.css"
        />
      </Helmet>

      <IntersectionAnimation trigger delay={200}>
        <h2 className={cn('h1', styles.text)}>Book a Tour at {centreTitle}</h2>

        <p className={cn('b2', styles.text)}>
          A tour is an excellent way to discover our learning environments, meet
          the team, and witness our programs and curriculum in action. If the
          scheduled tour times do not suit your availability, please feel free
          to get in touch with the centre to arrange your preferred time.
        </p>
      </IntersectionAnimation>

      <div className={cn('b2')}>
        <style>{formStyles}</style>

        {!isFormLoaded && (
          <IntersectionAnimation trigger delay={300}>
            <LoadingText className={styles.loadingText} textStyle="b1" />
          </IntersectionAnimation>
        )}

        {isFormLoaded === 'error' && (
          <IntersectionAnimation trigger delay={300}>
            <p className={cn('b1', styles.loadingText)}>
              Something went wrong fetching form.
            </p>
          </IntersectionAnimation>
        )}

        <div id={FORM_CONTAINER_ID} />
      </div>
    </div>
  );
};

export default BookATourForm;

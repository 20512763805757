import React from 'react';
import { IAboutAtf } from '@mayfield/sanity';
import { ResponsiveImage } from '@mayfield/common/components';
import { useBreakpoints } from '@mayfield/common/hooks';
import DesktopLetters from './components/DesktopLetters';
import MobileLetters from './components/MobileLetters';
import * as styles from './styles.module.scss';

interface IProps {
  data: IAboutAtf;
}

const AboutAtf = ({ data: { backgroundImage } }: IProps) => {
  const { smallDesktop } = useBreakpoints();

  return (
    <div className={styles.container}>
      <ResponsiveImage
        imageData={backgroundImage}
        style={{ position: 'absolute', inset: 0 }}
        loading="eager"
      />

      <div className={styles.contrast}>
        {smallDesktop ? <DesktopLetters /> : <MobileLetters />}
      </div>
    </div>
  );
};

export default AboutAtf;

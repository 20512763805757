import React from 'react';
import { IJobListing } from '@mayfield/sanity';
import { Button, Tag } from '@mayfield/common/components';
import cn from 'classnames';
import * as styles from './styles.module.scss';

const JobTile = ({
  description,
  employmentType,
  centre,
  salaryType,
  title,
  salary,
  slug
}: IJobListing) => {
  const {
    address: { suburb, state }
  } = centre;

  return (
    <div className={styles.container}>
      <h3 className={cn('h4', styles.title)}>{title}</h3>

      <div className={styles.info}>
        <div className={styles.defaultContent}>
          <div className={cn('b2', styles.location)}>{suburb}</div>

          <div className={styles.tags}>
            <Tag className={styles.tag} text={state} color="earth" />
            <Tag className={styles.tag} text={employmentType} color="grass" />
            <Tag className={styles.tag} text={salaryType} color="floral" />
            {salary && <Tag className={styles.tag} text={salary} color="sea" />}
          </div>
        </div>

        <div className={styles.hoverContent}>
          <p className={cn('b2', styles.description)}>{description}</p>

          <div>
            <Button className={styles.button} to={`/careers/${slug.current}`}>
              Read More & Apply
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobTile;

// extracted by mini-css-extract-plugin
export var content = "styles-module--content--01cfc";
export var hoverArea = "styles-module--hoverArea--cd8b7";
export var letter = "styles-module--letter--c3ad8";
export var letterA = "styles-module--letterA--1f3cd";
export var letterContainer = "styles-module--letterContainer--2df7a";
export var letterD = "styles-module--letterD--7fd3e";
export var letterE = "styles-module--letterE--8d1b1";
export var letterFI = "styles-module--letterFI--c7d41";
export var letterL = "styles-module--letterL--f9524";
export var letterM = "styles-module--letterM--f9d01";
export var letterY = "styles-module--letterY--db7ac";
export var ratioProvider = "styles-module--ratioProvider--6ed2e";
import { ICalculator, IIncomeToCCSPercentage } from '@mayfield/sanity';
import { TFortnightlyActivity } from '.';

export interface ISubsidyCalculatorOptions {
  // User data
  fortnightlyActivity: TFortnightlyActivity;
  annualIncome: number;
  childIndex: number;
  daysPerWeek: number;
  hoursPerDay: number;
  dailyCentreRate: number;
  // Backend data
  calculatorSettings: ICalculator;
}

const calculateSubsidy = ({
  annualIncome,
  calculatorSettings,
  childIndex,
  dailyCentreRate,
  daysPerWeek,
  fortnightlyActivity,
  hoursPerDay
}: ISubsidyCalculatorOptions) => {
  const {
    activityLevel,
    hourlyRateCap,
    incomeToCCSPercentage1Child: unsortedIncomeToCCSPercentage1Child,
    incomeToCCSPercentageAdditionalChildren:
      unsortedIncomeToCCSPercentageAdditionalChildren,
    receivePercentage: receivePercentageNumber
  } = calculatorSettings;

  const incomeToCCSPercentage1Child = unsortedIncomeToCCSPercentage1Child.sort(
    (a, b) => a.totalFamilyIncome - b.totalFamilyIncome
  );
  const incomeToCCSPercentageAdditionalChildren =
    unsortedIncomeToCCSPercentageAdditionalChildren.sort(
      (a, b) => a.totalFamilyIncome - b.totalFamilyIncome
    );

  const receivePercentage = receivePercentageNumber / 100;

  const getCCSPercentage: () => number = () => {
    const getMappedBracket: (
      orderedBrackets: IIncomeToCCSPercentage[],
      income: number
    ) => IIncomeToCCSPercentage = (orderedBrackets, income) => {
      const minimumSalaryThreshold = orderedBrackets[0];
      const maximumSalaryThreshold =
        orderedBrackets[orderedBrackets.length - 1];

      if (income <= minimumSalaryThreshold.totalFamilyIncome) {
        return minimumSalaryThreshold;
      }
      if (income >= maximumSalaryThreshold.totalFamilyIncome) {
        return maximumSalaryThreshold;
      }

      const bracket = orderedBrackets.reduce((prev, curr) => {
        if (curr.totalFamilyIncome <= income) {
          return curr;
        } else {
          return prev;
        }
      });

      return bracket;
    };

    const brackets =
      childIndex === 0
        ? incomeToCCSPercentage1Child
        : incomeToCCSPercentageAdditionalChildren;

    const bracket = getMappedBracket(brackets, annualIncome);

    return bracket.percentage / 100;
  };

  const getFortnightlySubsidisedHours: () => number = () => {
    switch (fortnightlyActivity) {
      case 'Less than 8 hours':
        return activityLevel.lessThan8;
      case '8-16 hours':
        return activityLevel.between8and16;
      case '17-48 hours':
        return activityLevel.between17and48;
      case '48+ hours':
        return activityLevel.moreThan48;

      default:
        return 0;
    }
  };

  // Derived values
  const hourlyRate = dailyCentreRate / hoursPerDay;
  const cappedHourlyRate = Math.min(hourlyRate, hourlyRateCap);
  const hoursPerFortnight = daysPerWeek * 2 * hoursPerDay;
  const subsidisedHourlyRate = cappedHourlyRate * getCCSPercentage();
  const subsidisedHoursFortnightly = Math.min(
    hoursPerFortnight,
    getFortnightlySubsidisedHours()
  );

  // Outputs
  const estimatedCCSFortnightly =
    subsidisedHourlyRate * subsidisedHoursFortnightly * receivePercentage;

  const childcareFeesFortnightly = hourlyRate * hoursPerFortnight;

  const outOfPocketCostsFortnightly =
    childcareFeesFortnightly - estimatedCCSFortnightly;

  return {
    estimatedCCSFortnightly,
    childcareFeesFortnightly,
    outOfPocketCostsFortnightly
  };
};

export default calculateSubsidy;

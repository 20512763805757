import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import cn from 'classnames';
import {
  LayoutProvider,
  SVG,
  UniversalLink
} from '@mayfield/common/components';
import { IFooter, TCity } from '@mayfield/sanity';
import { toKebabCase } from '@mayfield/common/utils';
import * as styles from './styles.module.scss';
import DesktopLetters from './components/DesktopLetters';

const Footer = () => {
  const {
    sanitySettings: { footer },
    allSanityCentre: { nodes }
  }: IQueryResults = useStaticQuery(query);
  const { internalLinks, socialLinks, tagline } = footer;

  const citiesWithCentres = nodes
    .map((node) => node.address.city)

    .filter(Boolean)
    .filter((city) => city !== 'None');
  const cities = [...new Set(citiesWithCentres)];

  return (
    <div className={styles.container}>
      <LayoutProvider grid maxWidth padding>
        <h2 className={cn('h1', styles.footerText)}>{tagline}</h2>

        <nav className={cn(styles.nav, styles.internalLinks)}>
          <h3 className={cn(`caption`, styles.listHeader)}>Links</h3>
          <ul>
            {internalLinks.map((link, i) => (
              <li key={i} className={styles.linkItem}>
                <UniversalLink variant="text" link={link} />
              </li>
            ))}
          </ul>
        </nav>

        <nav className={cn(styles.nav, styles.socials)}>
          <h3 className={cn(`caption`, styles.listHeader)}>Connect</h3>
          <ul>
            {socialLinks.map((link, i) => (
              <li key={i} className={styles.linkItem}>
                <UniversalLink variant="text" link={link} />
              </li>
            ))}
          </ul>
        </nav>

        {cities?.[0] && (
          <nav className={cn(styles.nav, styles.locations)}>
            <h3 className={cn(`caption`, styles.listHeader)}>Locations</h3>
            <ul>
              {cities.map((city) => (
                <li key={city} className={styles.linkItem}>
                  <Link
                    className="button-small"
                    to={`/centres/${toKebabCase(city)}`}
                  >
                    {city}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        )}

        <div className={styles.singleColumnContent}>
          <div className={styles.letters}>
            <SVG className={styles.lettersMobile} svg="footerMobileLetters" />

            <DesktopLetters className={styles.lettersDesktop} />
          </div>

          <div className={cn('b2', styles.bottomText)}>
            <div>© Mayfield {new Date().getFullYear()}</div>

            <div className={styles.corporateLinkContainer}>
              <div className={cn('b2')}>In safe hands with</div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className={cn('button-small', styles.corporateLink)}
                href="https://www.mayfieldchildcare.com.au/"
              >
                Mayfield Childcare Limited
              </a>
            </div>
          </div>
        </div>
      </LayoutProvider>
    </div>
  );
};

export default Footer;

const query = graphql`
  query {
    sanitySettings {
      footer {
        tagline
        internalLinks {
          ...UniversalLinkWebsiteFragment
        }
        socialLinks {
          ...UniversalLinkWebsiteFragment
        }
      }
    }
    allSanityCentre {
      nodes {
        address {
          city
        }
      }
    }
  }
`;

interface IQueryResults {
  sanitySettings: {
    footer: IFooter;
  };
  allSanityCentre: {
    nodes: {
      address: {
        city: TCity;
      };
    }[];
  };
}

// extracted by mini-css-extract-plugin
export var columnContainer = "styles-module--columnContainer--3591e";
export var container = "styles-module--container--38594";
export var desktop = "1340px";
export var giant = "2200px";
export var header = "styles-module--header--e248e";
export var infoText = "styles-module--infoText--c4778";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mainContent = "styles-module--mainContent--ec73b";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var subheader = "styles-module--subheader--b174a";
export var tablet = "769px";
import React from 'react';
import {
  IntersectionAnimation,
  LayoutProvider,
  PortableTextRenderer
} from '@mayfield/common/components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import * as styles from './styles.module.scss';

interface IProps {
  data: {
    _rawText: any;
  };
}

const RichText = ({ data: { _rawText } }: IProps) => {
  return (
    <div className={styles.container}>
      <LayoutProvider padding maxWidth>
        <IntersectionAnimation>
          <PortableTextRenderer
            gatsbyImageComponent={GatsbyImage}
            getGatsbyImageData={getGatsbyImageData}
            rawText={_rawText}
            textClassName="b1"
          />
        </IntersectionAnimation>
      </LayoutProvider>
    </div>
  );
};

export default RichText;

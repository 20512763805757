import React from 'react';
import cn from 'classnames';
import { PortableTextRenderer } from '@mayfield/common/components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import * as styles from './styles.module.scss';

interface IProps {
  header: string;
  _rawText?: any;
}

const ArticleTile = ({ header, _rawText }: IProps) => {
  return (
    <>
      <h1 className={cn('h1', styles.header)}>{header}</h1>

      {_rawText && (
        <PortableTextRenderer
          gatsbyImageComponent={GatsbyImage}
          getGatsbyImageData={getGatsbyImageData}
          rawText={_rawText}
          textClassName={cn('b2', styles.text)}
        />
      )}
    </>
  );
};

export default ArticleTile;

import React from 'react';
import cn from 'classnames';
import { LayoutProvider, TextInput } from '@mayfield/common/components';
import {
  ISearchFiltersProps,
  SearchFilters
} from '@mayfield/website/components';
import * as styles from './styles.module.scss';

interface IProps extends ISearchFiltersProps {
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  className?: string;
  placeholder?: string;
}

const SearchAndFilter = ({
  filterCategories,
  setFilterCategories,
  searchQuery,
  setSearchQuery,
  placeholder,
  isFilterOpen,
  themeColor = 'sea',
  className
}: IProps) => {
  return (
    <LayoutProvider grid className={cn(styles.container, className)}>
      <TextInput
        className={styles.textInput}
        placeholder={placeholder}
        value={searchQuery}
        onChange={setSearchQuery}
        themeColor={themeColor}
        iconLeft="lookingGlass"
      />

      <SearchFilters
        filterCategories={filterCategories}
        setFilterCategories={setFilterCategories}
        isFilterOpen={isFilterOpen}
        themeColor={themeColor}
      />
    </LayoutProvider>
  );
};

export default SearchAndFilter;

import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Dropdown,
  IDropdownOption,
  IDropdownOptionGroup
} from '@mayfield/common/components';
import { BookATourForm } from '@mayfield/website/components';
import { useApp } from '@mayfield/website/hooks';
import { useTrapFocus, useDisableFocus } from '@mayfield/common/hooks';
import { ICentre } from '@mayfield/sanity';
import cn from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import * as styles from './styles.module.scss';

type TStep = 'selectCentre' | 'form';

const BookATourSidebar = () => {
  const [step, setStep] = useState<TStep>('selectCentre');

  const centres: ICentre[] =
    useStaticQuery(BookTourCentreQuery).allSanityCentre.nodes;

  const {
    isBookATourSidebarOpen,
    setIsBookATourSidebarOpen,
    setBookATourSelectedCentre,
    setBookATourSelectedState,
    bookATourSelectedCentre,
    bookATourSelectedState
  } = useApp();

  const containerRef = useRef<HTMLDivElement>(null);

  useTrapFocus(isBookATourSidebarOpen, containerRef);
  useDisableFocus(!isBookATourSidebarOpen, containerRef);

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.target as HTMLDivElement;
    if (target.id === 'book-a-tour-sidebar-container') {
      setIsBookATourSidebarOpen(false);
    }
  };

  const getStateDropdownOptions: () => IDropdownOption[] = () => {
    const allStatesWithCentres: string[] = [];

    centres.forEach((centre) => {
      if (!allStatesWithCentres.includes(centre.address.state)) {
        allStatesWithCentres.push(centre.address.state);
      }
    });

    const listItems = allStatesWithCentres.map((state) => ({
      label: state,
      value: state
    }));

    return listItems;
  };

  const getCentreDropdownOptions: () => {
    centreOptions: IDropdownOption[];
    centreOptionGroups: IDropdownOptionGroup[];
  } = () => {
    const getSuburbsWithMultipleCentres = () => {
      const suburbsWithMultipleCentres: string[] = [];

      centres.forEach((centre) => {
        if (
          centre.address.state === bookATourSelectedState?.value &&
          !suburbsWithMultipleCentres.includes(centre.address.suburb)
        ) {
          const centresInSuburb = centres.filter(
            (c) => c.address.suburb === centre.address.suburb
          );

          if (centresInSuburb.length > 1) {
            suburbsWithMultipleCentres.push(centre.address.suburb);
          }
        }
      });

      return suburbsWithMultipleCentres.sort();
    };

    const suburbsWithMultipleCentres = getSuburbsWithMultipleCentres();

    const centresInSelectedState = centres.filter(
      (centre) => centre.address.state === bookATourSelectedState?.value
    );

    const centresInUnsharedSuburb = centresInSelectedState.filter(
      (centre) => !suburbsWithMultipleCentres.includes(centre.address.suburb)
    );

    const centreOptions: IDropdownOption[] = centresInUnsharedSuburb.map(
      (centre) => ({
        label: centre.title,
        value: centre.title
      })
    );

    const centreOptionGroups: IDropdownOptionGroup[] =
      suburbsWithMultipleCentres.map((suburb) => {
        const options = centresInSelectedState
          .filter((centre) => centre.address.suburb === suburb)
          .map((centre) => ({
            label: centre.title,
            value: centre.title
          }));

        return { title: suburb, options };
      });

    return { centreOptionGroups, centreOptions };
  };

  const { centreOptionGroups, centreOptions } = getCentreDropdownOptions();

  const selectedCentreInfo = centres.find(
    (centre) => centre.title === bookATourSelectedCentre?.value
  );

  useEffect(() => {
    const stateOfSelectedCentre = selectedCentreInfo?.address.state;

    if (!stateOfSelectedCentre) {
      return;
    }

    setBookATourSelectedState({
      label: stateOfSelectedCentre,
      value: stateOfSelectedCentre
    });
  }, [bookATourSelectedCentre, setBookATourSelectedState, selectedCentreInfo]);

  useEffect(() => {
    if (!isBookATourSidebarOpen) {
      setStep('selectCentre');
    }
  }, [isBookATourSidebarOpen]);

  return (
    <div
      onClick={handleClick}
      className={cn(styles.container, {
        [styles.open]: isBookATourSidebarOpen
      })}
      id="book-a-tour-sidebar-container"
    >
      <div className={styles.sidebar} ref={containerRef}>
        {step === 'selectCentre' && (
          <>
            <div>
              <h3 className={cn('h2', styles.title)}>Choose your centre</h3>

              <div className={styles.dropdownContainer}>
                <div className={cn('b1', styles.dropdownLabel)}>State</div>
                <Dropdown
                  placeholder="Select your state"
                  options={getStateDropdownOptions()}
                  activeOption={bookATourSelectedState}
                  setActiveOption={setBookATourSelectedState}
                  onClickOption={() =>
                    setBookATourSelectedCentre({ label: '', value: '' })
                  }
                />
              </div>

              <div className={styles.dropdownContainer}>
                <div className={cn('b1', styles.dropdownLabel)}>Centre</div>
                <Dropdown
                  placeholder="Select your Centre"
                  activeOption={bookATourSelectedCentre}
                  setActiveOption={setBookATourSelectedCentre}
                  options={centreOptions}
                  optionGroups={centreOptionGroups}
                  disabled={!bookATourSelectedState.value}
                />
              </div>
            </div>

            <div className={styles.buttonContainer}>
              <Button
                disabled={!bookATourSelectedCentre.value}
                onClick={() => setStep('form')}
              >
                Next
              </Button>
            </div>
          </>
        )}

        {step === 'form' && (
          <div>
            <BookATourForm
              centreId={selectedCentreInfo?.centreId || '0'}
              customerId={selectedCentreInfo?.customerId || '0'}
              centreTitle={selectedCentreInfo?.title || ''}
            />

            <Button
              variant="secondary"
              onClick={() => setStep('selectCentre')}
              className={styles.formBackButton}
            >
              Back
            </Button>
          </div>
        )}

        <div className={styles.closeButton}>
          <Button
            onClick={() => setIsBookATourSidebarOpen(false)}
            variant="text"
          >
            Close
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BookATourSidebar;

const BookTourCentreQuery = graphql`
  query BookTourCentreQuery {
    allSanityCentre(sort: { title: ASC }) {
      nodes {
        title
        centreId
        customerId
        address {
          state
          suburb
        }
      }
    }
  }
`;

import React from 'react';
import { IRichTextImage } from '@mayfield/sanity';
import cn from 'classnames';
import { ISanityConfig } from '../..';
import * as styles from './styles.module.scss';

interface IProps extends IRichTextImage {
  getGatsbyImageData: (imageId: string, {}, sanityConfig: ISanityConfig) => any;
  gatsbyImageComponent: React.FunctionComponent<any>;
}

const ImageComponent = ({
  value,
  gatsbyImageComponent: GatsbyImage,
  getGatsbyImageData
}: IProps) => {
  const sanityConfig = {
    projectId: process.env.SANITY_PROJECT_ID || '3m717coo',
    dataset: process.env.SANITY_DATASET || 'production'
  };
  const imageId = value.asset.id;

  const imageData = getGatsbyImageData(imageId, {}, sanityConfig);

  const { altText, aspectRatio, caption, credit } = value || {};

  if (!imageData) return null;

  return (
    <figure
      className={styles.imageContainer}
      style={{
        aspectRatio: `${aspectRatio?.ratio ? aspectRatio.ratio : `1`}`
      }}
    >
      <GatsbyImage
        style={{ position: 'absolute', inset: 0 }}
        alt={altText || caption || ''}
        image={imageData}
      />

      {caption && <p className={cn('b2', styles.caption)}>{caption}</p>}
      {credit && <p className={cn('caption', styles.credit)}>{credit}</p>}
    </figure>
  );
};

export default ImageComponent;

import React from 'react';
import { IImage } from '@mayfield/sanity';
import cn from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import { PortableTextRenderer, SVG } from '@mayfield/common/components';
import * as styles from './styles.module.scss';

interface IProps {
  headshot: IImage;
  name: string;
  position: string;
  _rawBio: any;
  linkedinUrl?: string;
}

const BioModalContent = ({
  _rawBio,
  headshot,
  name,
  position,
  linkedinUrl
}: IProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <GatsbyImage
          style={{ position: 'absolute', inset: 0 }}
          alt={`Headshot of ${name}`}
          image={headshot.asset.gatsbyImageData}
        />
      </div>

      <div>
        <div className={styles.nameAndLinkedin}>
          <h2 className={cn('h3', styles.name)}>{name}</h2>

          {linkedinUrl && (
            <a
              href={linkedinUrl}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={`Visit ${name}'s Linkedin profile`}
              className={styles.linkedinLink}
            >
              <SVG svg="linkedin" className={styles.linkedinIcon} />
            </a>
          )}
        </div>

        <h3 className={cn('b2', styles.position)}>{position}</h3>

        <div className={styles.bioContainer}>
          <PortableTextRenderer
            gatsbyImageComponent={GatsbyImage}
            getGatsbyImageData={getGatsbyImageData}
            textClassName="b2"
            rawText={_rawBio}
          />
        </div>
      </div>
    </div>
  );
};

export default BioModalContent;

import React from 'react';
import { TThemeColor } from '@mayfield/sanity';

interface IProps {
  themeColor: TThemeColor;
}

const ThemeColorVariableInjector = ({ themeColor }: IProps) => {
  const textColorCss = `
    :root {
      --color-theme-mid: var(--color-${themeColor}-mid);
      --color-theme-dark: var(--color-${themeColor}-dark);
    }
  `;

  return <style>{textColorCss}</style>;
};

export default ThemeColorVariableInjector;

import React from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';

interface IProps {
  url: string;
  className?: string;
}

const AnimatedGraphic = ({ url, className }: IProps) => {
  return (
    <div className={cn(className)}>
      <div className={styles.container}>
        <video
          muted
          loop
          autoPlay
          playsInline
          className={styles.animatedGraphic}
          src={url}
        />

        {/* There's a weird 1px border on windows without this */}
        <div className={styles.borderMask} />
      </div>
    </div>
  );
};

export default AnimatedGraphic;

import React from 'react';
import { IReview } from '@mayfield/sanity';
import cn from 'classnames';
import { SVG } from '@mayfield/common/components';
import * as styles from './styles.module.scss';

interface IProps extends IReview {
  className?: string;
}

const Review = ({ name, rating, review, className }: IProps) => {
  const ratingInt = parseInt(rating);
  const ratingArray = Array.from({ length: ratingInt });

  return (
    <div className={cn(className)}>
      <div className={styles.stars}>
        {ratingArray.map((_, i) => (
          <SVG key={i} svg="star" className={styles.starIcon} />
        ))}
      </div>

      <p className={cn('b2', styles.text)}>{review}</p>

      <p className={cn('b2', styles.name)}>— {name}</p>
    </div>
  );
};

export default Review;

import React from 'react';
import { IHomepageAtf } from '@mayfield/sanity';
import cn from 'classnames';
import {
  ButtonBig,
  IntersectionAnimation,
  LayoutProvider,
  ResponsiveImage,
  SVG
} from '@mayfield/common/components';
import {
  useBreakpoints,
  useIntersectionAnimation,
  useMousePosition
} from '@mayfield/common/hooks';
import { useSpring, animated } from 'react-spring';
import { useApp } from '@mayfield/website/hooks';
import * as styles from './styles.module.scss';

interface IProps {
  data: IHomepageAtf;
}

const HomepageAtf = ({
  data: { headerText, subtitleText, backgroundImage }
}: IProps) => {
  const ROTATION_MULT = 10;
  const TRANSLATE_Y_MULT = 50;

  const { smallTablet } = useBreakpoints();

  const { normalisedMousePosition } = useMousePosition();
  const { inView, ref } = useIntersectionAnimation();
  const { setIsBookATourSidebarOpen } = useApp();

  const animationConfig = {
    tension: 200,
    friction: 10
  };

  const springsM = useSpring({
    y: normalisedMousePosition.x * TRANSLATE_Y_MULT,
    rotate: normalisedMousePosition.y * ROTATION_MULT,
    config: animationConfig
  });

  const springsA = useSpring({
    y: normalisedMousePosition.y * TRANSLATE_Y_MULT,
    rotate: normalisedMousePosition.x * ROTATION_MULT,
    config: animationConfig
  });

  const springsY = useSpring({
    y: normalisedMousePosition.y * TRANSLATE_Y_MULT * -1,
    rotate: normalisedMousePosition.x * ROTATION_MULT * -1,
    config: animationConfig
  });

  const springsFI = useSpring({
    y: normalisedMousePosition.x * TRANSLATE_Y_MULT * -1,
    rotate: normalisedMousePosition.y * ROTATION_MULT * -1,
    config: animationConfig
  });

  const bodyCopyStyle = smallTablet ? 'h4' : 'b1';

  const openBookATourSidebar = () => {
    setIsBookATourSidebarOpen(true);
  };

  return (
    <div className={styles.container} ref={ref}>
      <ResponsiveImage
        imageData={backgroundImage}
        style={{ position: 'absolute', inset: 0 }}
        loading="eager"
      />

      <div className={styles.contrast}>
        <div className={cn(styles.letter, styles.letterM)}>
          <animated.div
            style={{
              ...springsM
            }}
          >
            <SVG svg="letterM" />
          </animated.div>
        </div>

        <div className={cn(styles.letter, styles.letterA)}>
          <animated.div
            style={{
              ...springsA
            }}
          >
            <SVG svg="letterA" />
          </animated.div>
        </div>

        <div className={cn(styles.letter, styles.letterY)}>
          <animated.div
            style={{
              ...springsY
            }}
          >
            <SVG svg="letterY" />
          </animated.div>
        </div>

        <div className={cn(styles.letter, styles.letterFI)}>
          <animated.div
            style={{
              ...springsFI
            }}
          >
            <SVG svg="letterFI" />
          </animated.div>
        </div>

        <LayoutProvider maxWidth padding grid>
          <div className={styles.content}>
            <div>
              <IntersectionAnimation trigger={inView} delay={200}>
                <h1 className={cn('d1', styles.headerText)}>{headerText}</h1>
              </IntersectionAnimation>

              <IntersectionAnimation trigger={inView} delay={400}>
                <p className={cn(bodyCopyStyle, styles.subtitleText)}>
                  {subtitleText}
                </p>
              </IntersectionAnimation>

              <IntersectionAnimation trigger={inView} delay={600}>
                <ButtonBig text="Book a Tour" onClick={openBookATourSidebar} />
              </IntersectionAnimation>
            </div>
          </div>
        </LayoutProvider>
      </div>
    </div>
  );
};

export default HomepageAtf;

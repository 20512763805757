import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { ButtonBig, SVG } from '@mayfield/common/components';
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image';
import { useSpring, animated } from 'react-spring';
import { useApp } from '@mayfield/website/hooks';
import { IBookTour } from '@mayfield/sanity';
import { config } from '@react-spring/web';
import * as styles from './styles.module.scss';

interface IProps {
  data?: IBookTour;
}

const BookTour = ({ data }: IProps) => {
  const { setIsBookATourSidebarOpen } = useApp();

  const backgroundImage = data?.backgroundImage;

  const IMAGE_TRANSLATE_Y_RANGE = 100;
  const WORD_TRANSLATE_Y_RANGE = -40;

  const [percentageScrolled, setPercentageScrolled] = useState(0);

  const containerRef = useRef<HTMLDivElement>(null);

  const imageStyles: CSSProperties = {
    position: 'absolute',
    inset: 0
  };

  const getYValue = (range: number) => {
    const clampedPercentage = Math.max(0, Math.min(percentageScrolled, 100));

    const multiplier = 2 * (clampedPercentage / 100) - 1;

    const yValue = (range / 2) * multiplier;
    return yValue;
  };

  const imageSpring = useSpring({
    y: getYValue(IMAGE_TRANSLATE_Y_RANGE),
    config: config.slow
  });

  const wordSpring = useSpring({
    y: getYValue(WORD_TRANSLATE_Y_RANGE),
    config: config.slow
  });

  const calculateScrollPercentage = () => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      // Completely below the viewport
      if (rect.top >= windowHeight) {
        setPercentageScrolled(0);
        return;
      }

      // Completely above the viewport
      if (rect.bottom <= 0) {
        setPercentageScrolled(100);
        return;
      }

      // Partially visible
      const totalScrollableDistance = rect.height + windowHeight;
      const scrolledDistance = windowHeight - rect.top;
      const percentageScrolled =
        (scrolledDistance / totalScrollableDistance) * 100;

      setPercentageScrolled(percentageScrolled);
    }
  };

  useEffect(() => {
    calculateScrollPercentage();

    window.addEventListener('scroll', calculateScrollPercentage);
    window.addEventListener('resize', calculateScrollPercentage);

    return () => {
      window.removeEventListener('scroll', calculateScrollPercentage);
      window.removeEventListener('resize', calculateScrollPercentage);
    };
  }, []);

  const handleClick = () => {
    setIsBookATourSidebarOpen(true);
  };

  return (
    <div className={styles.container}>
      <animated.div
        className={styles.imageContainer}
        style={{ ...imageSpring }}
        ref={containerRef}
      >
        <div className={styles.imageMobile}>
          {backgroundImage && (
            <GatsbyImage
              style={imageStyles}
              alt={backgroundImage.altText || ''}
              image={backgroundImage.mobileImage.asset.gatsbyImageData}
            />
          )}

          {!backgroundImage && (
            <StaticImage
              style={imageStyles}
              alt=""
              src="./assets/book-tour-slice-mobile.jpg"
            />
          )}
        </div>
        <div className={styles.imageDesktop}>
          {backgroundImage && (
            <GatsbyImage
              style={imageStyles}
              alt={backgroundImage.altText || ''}
              image={backgroundImage.desktopImage.asset.gatsbyImageData}
            />
          )}

          {!backgroundImage && (
            <StaticImage
              style={imageStyles}
              alt=""
              src="./assets/book-tour-slice-desktop.jpg"
            />
          )}
        </div>
      </animated.div>

      <div className={styles.cta}>
        <ButtonBig text="Book a Tour" onClick={handleClick} />
      </div>

      <animated.div className={styles.bookGraphic} style={{ ...wordSpring }}>
        <SVG className={styles.bookGraphicMobile} svg="bookMobile" />
        <SVG className={styles.bookGraphicDesktop} svg="bookDesktop" />
      </animated.div>
    </div>
  );
};

export default BookTour;

import { IFont } from '@mayfield/sanity';

const getFontFamilyCss = (fonts: IFont[]) => {
  if (!fonts) return ``;

  let cssString = ``;

  fonts.forEach((font) => {
    cssString += `
			@font-face {
				font-family: ${font.title};
				font-style: "normal";
				font-weight: ${font.fontWeight};
				font-display: "block";
				src: url("${font.woff2.asset.url}") format("woff2"),url("${font.woff.asset.url}") format("woff");
			}
		`;
  });

  return cssString;
};

export default getFontFamilyCss;

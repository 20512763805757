// extracted by mini-css-extract-plugin
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--936ed";
export var image = "styles-module--image--d8afc";
export var imageOnLeft = "styles-module--imageOnLeft--88f9e";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var textContent = "styles-module--textContent--eea3c";
export var title = "styles-module--title--fc11c";
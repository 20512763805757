import React from 'react';
import { IUniversalLinkToolkit, IUniversalLinkWebsite } from '@mayfield/sanity';
import { Button, type IButton } from '@mayfield/common/components';

type UniversalLink = IUniversalLinkToolkit | IUniversalLinkWebsite;

type UniversalLinkExtended = {
  [K in keyof UniversalLink]: UniversalLink[K];
};

type TRequiredButtonProps = Omit<
  IButton,
  'children' | 'buttonType' | 'to' | 'href' | 'onClick' | 'display' | 'loading'
>;

type TProps = {
  link: UniversalLinkExtended;
} & TRequiredButtonProps;

const UniversalLink = ({
  link: universalLink,
  className,
  disabled,
  fluid,
  iconLeft,
  iconRight,
  variant,
  theme,
  color
}: TProps) => {
  const buttonProps = {
    className,
    disabled,
    fluid,
    iconLeft,
    iconRight,
    variant,
    theme,
    color
  };

  const link = universalLink.link[0];
  const text = universalLink.text;

  switch (link._type) {
    case `externalUrl`:
      return (
        <Button href={link.url} {...buttonProps}>
          {text}
        </Button>
      );

    case `referenceToolkitPage`:
    case `referenceWebsitePage`:
      return (
        <Button to={`/${link.page.slug.current}`} {...buttonProps}>
          {text}
        </Button>
      );

    default:
      return null;
  }
};

export default UniversalLink;

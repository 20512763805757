export const alphabeticalSort = (
  a: { title: string },
  b: { title: string }
) => {
  const titleA = a?.title?.toUpperCase();
  const titleB = b?.title?.toUpperCase();

  if (titleA < titleB) {
    return -1;
  }
  if (titleA > titleB) {
    return 1;
  }

  return 0;
};

import React, { createContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { globalHistory } from '@reach/router';
import { IDropdownOption } from '@mayfield/common/components';
import * as styles from '@mayfield/common/styles/disableScroll.module.scss';

interface IPagePasswordStatus {
  [key: string]: 'locked' | 'unlocked';
}

export type TModalDrawingsVariant = null | 1 | 2 | 3;

export interface IAppContext {
  bookATourSelectedState: IDropdownOption;
  setBookATourSelectedState: React.Dispatch<
    React.SetStateAction<IDropdownOption>
  >;
  bookATourSelectedCentre: IDropdownOption;
  setBookATourSelectedCentre: React.Dispatch<
    React.SetStateAction<IDropdownOption>
  >;
  isMobileMenuOpen: boolean;
  setIsMobileMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isBookATourSidebarOpen: boolean;
  setIsBookATourSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  windowLocation: Location | undefined;
  pagePasswordStatus: IPagePasswordStatus;
  setPagePasswordStatus: React.Dispatch<
    React.SetStateAction<IPagePasswordStatus>
  >;
  modalContent: React.ReactNode;
  setModalContent: React.Dispatch<React.SetStateAction<React.ReactNode>>;
  modalDrawingsVariant: TModalDrawingsVariant;
  setModalDrawingsVariant: React.Dispatch<
    React.SetStateAction<TModalDrawingsVariant>
  >;
}

export const AppContext = createContext<IAppContext>({} as IAppContext);

interface IProps {
  children: React.ReactNode;
}

const AppProvider = ({ children }: IProps) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isBookATourSidebarOpen, setIsBookATourSidebarOpen] = useState(false);
  const [windowLocation, setWindowLocation] = useState<Location | undefined>();
  const [bookATourSelectedState, setBookATourSelectedState] =
    useState<IDropdownOption>({
      label: '',
      value: ''
    });
  const [bookATourSelectedCentre, setBookATourSelectedCentre] =
    useState<IDropdownOption>({
      label: '',
      value: ''
    });

  const [pagePasswordStatus, setPagePasswordStatus] =
    useState<IPagePasswordStatus>({});
  const [modalContent, setModalContent] = useState<React.ReactNode>(null);
  const [modalDrawingsVariant, setModalDrawingsVariant] =
    useState<TModalDrawingsVariant>(null);

  const isModalOpen = modalContent;

  useEffect(() => {
    if (typeof window !== `undefined` && window?.location) {
      setWindowLocation(window.location);
    }

    return globalHistory.listen(({ location }) => {
      setWindowLocation(location);
    });
  }, []);

  // Close menu on page change
  useEffect(() => {
    setIsMobileMenuOpen(false);
    setIsBookATourSidebarOpen(false);
  }, [windowLocation]);

  // Disable page scroll
  useEffect(() => {
    if (isMobileMenuOpen || isBookATourSidebarOpen || isModalOpen) {
      document.body.classList.add(styles.disableScroll);
    } else {
      document.body.classList.remove(styles.disableScroll);
    }
  }, [isMobileMenuOpen, isBookATourSidebarOpen, isModalOpen]);

  const contextProps = useMemo(
    () => ({
      bookATourSelectedCentre,
      setBookATourSelectedCentre,
      bookATourSelectedState,
      setBookATourSelectedState,
      isMobileMenuOpen,
      setIsMobileMenuOpen,
      isBookATourSidebarOpen,
      setIsBookATourSidebarOpen,
      windowLocation,
      pagePasswordStatus,
      setPagePasswordStatus,
      modalContent,
      setModalContent,
      modalDrawingsVariant,
      setModalDrawingsVariant
    }),
    [
      bookATourSelectedCentre,
      setBookATourSelectedCentre,
      bookATourSelectedState,
      setBookATourSelectedState,
      isMobileMenuOpen,
      setIsMobileMenuOpen,
      isBookATourSidebarOpen,
      setIsBookATourSidebarOpen,
      windowLocation,
      pagePasswordStatus,
      setPagePasswordStatus,
      modalContent,
      setModalContent,
      modalDrawingsVariant,
      setModalDrawingsVariant
    ]
  );

  return (
    <AppContext.Provider value={contextProps}>{children}</AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AppProvider;

// extracted by mini-css-extract-plugin
export var category = "styles-module--category--90c83";
export var categoryTitle = "styles-module--categoryTitle--deab6";
export var checkbox = "styles-module--checkbox--0e638";
export var checkboxContainer = "styles-module--checkboxContainer--91939";
export var chevronIcon = "styles-module--chevronIcon--4cfbb";
export var desktop = "1340px";
export var filterActive = "styles-module--filterActive--e9c44";
export var filterToggle = "styles-module--filterToggle--46592";
export var filtersContainer = "styles-module--filtersContainer--392d2";
export var filtersContent = "styles-module--filtersContent--ba5ee";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
// extracted by mini-css-extract-plugin
export var container = "styles-module--container--67838";
export var content = "styles-module--content--05323";
export var contrast = "styles-module--contrast--752a0";
export var desktop = "1340px";
export var giant = "2200px";
export var headerText = "styles-module--headerText--cc008";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var letter = "styles-module--letter--307ee";
export var letterA = "styles-module--letterA--cfef6";
export var letterFI = "styles-module--letterFI--218df";
export var letterM = "styles-module--letterM--c934f";
export var letterY = "styles-module--letterY--d7023";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var subtitleText = "styles-module--subtitleText--0e213";
export var tablet = "769px";
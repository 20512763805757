import React from 'react';
import cn from 'classnames';
import { LayoutProvider, SVG } from '@mayfield/common/components';
import * as styles from './styles.module.scss';

const MobileLetters = () => {
  return (
    <LayoutProvider maxWidth className={styles.letters}>
      <SVG className={cn(styles.letter, styles.letterM)} svg="letterM" />
      <SVG className={cn(styles.letter, styles.letterA)} svg="letterA" />
      <SVG className={cn(styles.letter, styles.letterY)} svg="letterY" />
      <SVG className={cn(styles.letter, styles.letterFI)} svg="letterFI" />
      <SVG className={cn(styles.letter, styles.letterE)} svg="letterE" />
      <SVG className={cn(styles.letter, styles.letterL)} svg="letterL" />
      <SVG className={cn(styles.letter, styles.letterD)} svg="letterD" />
    </LayoutProvider>
  );
};

export default MobileLetters;

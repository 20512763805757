import React, { useEffect, useRef, useState } from 'react';
import { IResponsiveImage } from '@mayfield/sanity';
import { ResponsiveImage } from '@mayfield/common/components';
import cn from 'classnames';
import { useInView } from 'react-intersection-observer';
import * as styles from './styles.module.scss';
import chalkVideo from './assets/chalk.webm';

interface IProps {
  image: IResponsiveImage;
  className?: string;
}

const ChalkDrawImage = ({ image, className }: IProps) => {
  const [imageHeight, setImageHeight] = useState(0);
  const [isChalkVideoVisible, setIsChalkVideoVisible] = useState(true);

  const { ref, inView } = useInView({
    rootMargin: '-90px',
    triggerOnce: true
  });

  const imageContainerRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const calculateImageHeight = () => {
    const imageContainer = imageContainerRef.current;
    if (!imageContainer) return;

    const updatedImageHeight = imageContainer.offsetHeight;
    setImageHeight(updatedImageHeight);
  };

  const isClientChromium = () => {
    if (typeof navigator === 'undefined') return false;

    const userAgent = navigator.userAgent;

    return userAgent.includes('Chrome');
  };

  const useChalkEffect = isClientChromium();

  useEffect(() => {
    calculateImageHeight();
    window.addEventListener('resize', calculateImageHeight);

    return () => {
      window.removeEventListener('resize', calculateImageHeight);
    };
  }, []);

  useEffect(() => {
    if (!inView) return;

    setTimeout(() => {
      setIsChalkVideoVisible(false);
    }, 1300);
  }, [inView]);

  useEffect(() => {
    if (!videoRef.current) return;

    videoRef.current.playbackRate = 1.5;
  }, []);

  return (
    <div
      ref={ref}
      style={{ height: imageHeight }}
      className={cn(styles.container, className)}
    >
      <div
        className={cn(styles.imageViewContainer, {
          [styles.willOpen]: !useChalkEffect,
          [styles.open]: inView
        })}
      >
        <div ref={imageContainerRef}>
          <ResponsiveImage imageData={image} />
        </div>
      </div>

      {useChalkEffect && (
        <video
          aria-hidden
          className={cn(styles.chalkVideo, {
            [styles.hidden]: !isChalkVideoVisible
          })}
          playsInline
          muted
          src={chalkVideo}
          autoPlay
          ref={videoRef}
        />
      )}
    </div>
  );
};

export default ChalkDrawImage;

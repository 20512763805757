import React, { useState } from 'react';
import { IJobListing } from '@mayfield/sanity';
import { graphql, useStaticQuery } from 'gatsby';
import cn from 'classnames';
import {
  useBreakpoints,
  useIntersectionAnimation
} from '@mayfield/common/hooks';
import {
  SearchAndFilter,
  type IFilterCategory
} from '@mayfield/website/components';
import { useStaggerAnimation } from '@mayfield/website/hooks';
import {
  Button,
  IntersectionAnimation,
  LayoutProvider
} from '@mayfield/common/components';
import * as styles from './styles.module.scss';
import JobTile from './components/JobTile';

const JobSearch = () => {
  const {
    allSanityJobListing: { nodes: jobListings }
  }: IQueryData = useStaticQuery(query);

  const getInitialFilterCategories: () => IFilterCategory[] = () => {
    const locations = new Set<string>();
    const workTypes = new Set<string>();
    const payTypes = new Set<string>();

    jobListings.forEach((job) => {
      locations.add(job.centre.address.state);
      workTypes.add(job.employmentType);
      payTypes.add(job.salaryType);
    });

    const locationsCategory: IFilterCategory = {
      name: 'Location',
      options: Array.from(locations).map((location) => ({
        name: location,
        selected: false
      }))
    };

    const workTypesCategory: IFilterCategory = {
      name: 'Work Type',
      options: Array.from(workTypes).map((workType) => ({
        name: workType,
        selected: false
      }))
    };

    const payTypesCategory: IFilterCategory = {
      name: 'Pay Type',
      options: Array.from(payTypes).map((payType) => ({
        name: payType,
        selected: false
      }))
    };

    return [locationsCategory, workTypesCategory, payTypesCategory];
  };

  const [filterCategories, setFilterCategories] = useState<IFilterCategory[]>(
    getInitialFilterCategories()
  );
  const [searchQuery, setSearchQuery] = useState('');

  const { largeTablet } = useBreakpoints();

  const [batchesToDisplay, setBatchesToDisplay] = useState(1);

  const numberOfJobsPerBatch = largeTablet ? 12 : 8;

  const getFilteredJobs = () => {
    const locationFilter = filterCategories.find(
      (category) => category.name === 'Location'
    );
    const workTypeFilter = filterCategories.find(
      (category) => category.name === 'Work Type'
    );
    const payTypeFilter = filterCategories.find(
      (category) => category.name === 'Pay Type'
    );

    const activeLocationFilters = locationFilter?.options.filter(
      (option) => option.selected
    );
    const activeWorkTypeFilters = workTypeFilter?.options.filter(
      (option) => option.selected
    );
    const activePayTypeFilters = payTypeFilter?.options.filter(
      (option) => option.selected
    );

    const isLocationFilterActive = activeLocationFilters?.[0];
    const isWorkTypeFilterActive = activeWorkTypeFilters?.[0];
    const isPayTypeFilterActive = activePayTypeFilters?.[0];

    const categoryFilteredJobs = jobListings.filter((job) => {
      if (
        isLocationFilterActive &&
        !activeLocationFilters?.some(
          (option) => option.name === job.centre.address.state
        )
      ) {
        return false;
      }
      if (
        isWorkTypeFilterActive &&
        !activeWorkTypeFilters?.some(
          (option) => option.name === job.employmentType
        )
      ) {
        return false;
      }
      if (
        isPayTypeFilterActive &&
        !activePayTypeFilters?.some((option) => option.name === job.salaryType)
      ) {
        return false;
      }
      return true;
    });

    const queryFilteredJobs = categoryFilteredJobs.filter((jobListing) => {
      const {
        centre: { address },
        title
      } = jobListing;
      const { postcode, state } = address;
      const searchQueryLowercase = searchQuery.toLowerCase();

      return (
        postcode.toLowerCase().includes(searchQueryLowercase) ||
        state.toLowerCase().includes(searchQueryLowercase) ||
        title.toLowerCase().includes(searchQueryLowercase)
      );
    });

    return queryFilteredJobs;
  };

  const filteredJobs = getFilteredJobs();

  const jobsToDisplay = filteredJobs.slice(
    0,
    numberOfJobsPerBatch * batchesToDisplay
  );

  const { ref, inView } = useIntersectionAnimation();

  const { getDelayTime, staggerAnimation } = useStaggerAnimation(
    jobsToDisplay.length,
    inView
  );

  return (
    <div className={styles.container}>
      <LayoutProvider maxWidth padding grid>
        <div className={styles.titleAndSearch}>
          <IntersectionAnimation delay={200}>
            <h2 className={cn('h1', styles.title)}>Job Openings</h2>
          </IntersectionAnimation>

          <IntersectionAnimation delay={300}>
            <SearchAndFilter
              filterCategories={filterCategories}
              setFilterCategories={setFilterCategories}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              className={styles.searchAndFilter}
              themeColor="sea"
              placeholder="Enter Role, Postcode or State"
            />
          </IntersectionAnimation>
        </div>

        <div className={styles.tiles} ref={ref}>
          <LayoutProvider grid>
            {jobsToDisplay.map((job, i) => (
              <IntersectionAnimation
                animation={staggerAnimation ? 'fadeUp' : 'none'}
                trigger={inView}
                delay={getDelayTime(i)}
                key={job.slug.current}
                className={styles.jobTile}
              >
                <JobTile {...job} />
              </IntersectionAnimation>
            ))}

            {!jobsToDisplay?.[0] && (
              <p className={cn('h3', styles.noJobsText)}>
                Sorry, currently no jobs match your search!
              </p>
            )}

            {jobsToDisplay.length < filteredJobs.length && (
              <div className={styles.loadMoreButtonContainer}>
                <Button onClick={() => setBatchesToDisplay((prev) => prev + 1)}>
                  Load More
                </Button>
              </div>
            )}
          </LayoutProvider>
        </div>
      </LayoutProvider>
    </div>
  );
};

export default JobSearch;

/**
 * GraphQL ====================================================================
 */

interface IQueryData {
  allSanityJobListing: {
    nodes: IJobListing[];
  };
}

const query = graphql`
  query {
    allSanityJobListing {
      nodes {
        title
        slug {
          current
        }
        salaryType
        salary
        employmentType
        description
        centre {
          title
          address {
            suburb
            postcode
            state
          }
        }
        _key
      }
    }
  }
`;

import React, { useRef, useState } from 'react';
import cn from 'classnames';
import { SVG } from '@mayfield/common/components';
import { useSpring, animated } from 'react-spring';
import * as styles from './styles.module.scss';

const DesktopLetters = () => {
  const [isMouseInTopHalf, setIsMouseInTopHalf] = useState(true);

  const containerRef = useRef<HTMLDivElement>(null);
  const containerHeight = containerRef.current?.offsetHeight || 0;

  const mRef = useRef<HTMLDivElement>(null);
  const mHeight = mRef.current?.offsetHeight || 0;
  const aRef = useRef<HTMLDivElement>(null);
  const aHeight = aRef.current?.offsetHeight || 0;
  const yRef = useRef<HTMLDivElement>(null);
  const yHeight = yRef.current?.offsetHeight || 0;
  const fiRef = useRef<HTMLDivElement>(null);
  const fiHeight = fiRef.current?.offsetHeight || 0;
  const eRef = useRef<HTMLDivElement>(null);
  const eHeight = eRef.current?.offsetHeight || 0;
  const lRef = useRef<HTMLDivElement>(null);
  const lHeight = lRef.current?.offsetHeight || 0;
  const dRef = useRef<HTMLDivElement>(null);
  const dHeight = dRef.current?.offsetHeight || 0;

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const container = containerRef.current;
    if (!container) return;

    const containerTop = container.getBoundingClientRect().top;
    const mouseTop = e.clientY + window.scrollY;
    const newIsMouseInTopHalf =
      mouseTop < containerTop + container.offsetHeight / 2;

    setIsMouseInTopHalf(newIsMouseInTopHalf);
  };

  const animationConfig = {
    tension: 50,
    friction: 20
  };

  const springsM = useSpring({
    y: isMouseInTopHalf ? containerHeight - mHeight * 1.7 : mHeight * 0.3,
    rotate: isMouseInTopHalf ? 0 : 20,
    config: animationConfig
  });

  const springsA = useSpring({
    y: isMouseInTopHalf ? containerHeight - aHeight * 1 : aHeight * 1.1,
    rotate: isMouseInTopHalf ? 20 : 30,
    config: animationConfig
  });

  const springsY = useSpring({
    y: isMouseInTopHalf ? containerHeight - yHeight * 1.5 : yHeight * 0.3,
    rotate: isMouseInTopHalf ? 0 : -20,
    config: animationConfig
  });

  const springsFI = useSpring({
    y: isMouseInTopHalf ? containerHeight - fiHeight * 1.2 : fiHeight * 0.2,
    rotate: isMouseInTopHalf ? 30 : 10,
    config: animationConfig
  });

  const springsE = useSpring({
    y: isMouseInTopHalf ? containerHeight - eHeight * 1.1 : eHeight * 0.2,
    rotate: isMouseInTopHalf ? 10 : 30,
    config: animationConfig
  });

  const springsL = useSpring({
    y: isMouseInTopHalf ? containerHeight - lHeight * 1.1 : lHeight * 0.2,
    rotate: isMouseInTopHalf ? 0 : 40,
    config: animationConfig
  });

  const springsD = useSpring({
    y: isMouseInTopHalf ? containerHeight - dHeight * 1.2 : dHeight * 0.3,
    rotate: isMouseInTopHalf ? 0 : 20,
    config: animationConfig
  });

  return (
    <div
      className={styles.container}
      ref={containerRef}
      onMouseMove={(e) => handleMouseMove(e)}
    >
      <div className={styles.letters}>
        <animated.div
          className={cn(styles.letterContainer, styles.letterContainerM)}
          ref={mRef}
          style={{ ...springsM }}
        >
          <SVG className={styles.letterM} svg="letterM" />
        </animated.div>

        <animated.div
          className={cn(styles.letterContainer, styles.letterContainerA)}
          ref={aRef}
          style={{ ...springsA }}
        >
          <SVG className={styles.letterA} svg="letterA" />
        </animated.div>

        <animated.div
          className={cn(styles.letterContainer, styles.letterContainerY)}
          ref={yRef}
          style={{ ...springsY }}
        >
          <SVG className={styles.letterY} svg="letterY" />
        </animated.div>

        <animated.div
          className={cn(styles.letterContainer, styles.letterContainerFI)}
          ref={fiRef}
          style={{ ...springsFI }}
        >
          <SVG className={styles.letterY} svg="letterFI" />
        </animated.div>

        <animated.div
          className={cn(styles.letterContainer, styles.letterContainerE)}
          ref={eRef}
          style={{ ...springsE }}
        >
          <SVG className={styles.letterE} svg="letterE" />
        </animated.div>

        <animated.div
          className={cn(styles.letterContainer, styles.letterContainerL)}
          ref={lRef}
          style={{ ...springsL }}
        >
          <SVG className={styles.letterL} svg="letterL" />
        </animated.div>

        <animated.div
          className={cn(styles.letterContainer, styles.letterContainerD)}
          ref={dRef}
          style={{ ...springsD }}
        >
          <SVG className={styles.letterD} svg="letterD" />
        </animated.div>
      </div>
    </div>
  );
};

export default DesktopLetters;

// extracted by mini-css-extract-plugin
export var bioContainer = "styles-module--bioContainer--d3cd9";
export var container = "styles-module--container--1e618";
export var desktop = "1340px";
export var giant = "2200px";
export var imageContainer = "styles-module--imageContainer--d0933";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var linkedinIcon = "styles-module--linkedinIcon--f67b1";
export var linkedinLink = "styles-module--linkedinLink--0d605";
export var mobile = "400px";
export var name = "styles-module--name--252d0";
export var nameAndLinkedin = "styles-module--nameAndLinkedin--80090";
export var position = "styles-module--position--0b493";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
// extracted by mini-css-extract-plugin
export var container = "styles-module--container--a79c9";
export var content = "styles-module--content--62dc1";
export var contrast = "styles-module--contrast--ac87e";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var shortVersion = "styles-module--shortVersion--f31a7";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var title = "styles-module--title--98cf3";
export var titleContainer = "styles-module--titleContainer--6de31";
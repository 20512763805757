// extracted by mini-css-extract-plugin
export var accordion = "styles-module--accordion--29272";
export var buttonIcon = "styles-module--buttonIcon--e9f65";
export var container = "styles-module--container--30799";
export var content = "styles-module--content--d1dab";
export var desktop = "1340px";
export var editButton = "styles-module--editButton--8cef5";
export var faded = "styles-module--faded--42a53";
export var giant = "2200px";
export var grid = "styles-module--grid--7f08f";
export var iconContainer = "styles-module--iconContainer--dc369";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var tickIcon = "styles-module--tickIcon--04e0b";
export var titleContainer = "styles-module--titleContainer--0afa8";
export var visible = "styles-module--visible--7c064";
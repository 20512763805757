import React from 'react';
import {
  IntersectionAnimation,
  LayoutProvider
} from '@mayfield/common/components';
import cn from 'classnames';
import * as styles from './styles.module.scss';

const Title = () => {
  return (
    <div className={styles.container}>
      <IntersectionAnimation>
        <LayoutProvider padding maxWidth grid>
          <div className={styles.content}>
            <h1 className={cn('d1', styles.title)}>Mayfield News</h1>
            <p className={cn('h4')}>Stay up to date with our latest articles</p>
          </div>
        </LayoutProvider>
      </IntersectionAnimation>
    </div>
  );
};

export default Title;

import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { Link } from 'gatsby';
import { SVG, TSvg } from '@mayfield/common/components';
import * as styles from './styles.module.scss';

export interface IButtonBig {
  text: string;
  disabled?: boolean;
  href?: string;
  to?: string;
  buttonType?: 'button' | 'reset' | 'submit';
  display?: boolean;
  onClick?: () => void;
  className?: string;
}

const ButtonBig = ({
  text,
  buttonType = 'button',
  to,
  href,
  disabled,
  display,
  onClick,
  className
}: IButtonBig) => {
  let BtnElement: any;
  if (display) BtnElement = 'div';
  else if (to) BtnElement = Link;
  else if (href) BtnElement = 'a';
  else BtnElement = 'button';

  const [animationFrame, setAnimationFrame] = useState(0);
  const animationFrames: TSvg[] = ['scribble1', 'scribble2', 'scribble3'];

  const ANIMATION_SPEED_MS = 200;

  useEffect(() => {
    const julian = setInterval(() => {
      setAnimationFrame((prev) => (prev + 1) % animationFrames.length);
    }, ANIMATION_SPEED_MS);
    return () => clearInterval(julian);
  }, [animationFrame, animationFrames.length]);

  return (
    <BtnElement
      type={to || href ? '' : buttonType}
      href={href}
      target={href ? '_blank' : null}
      rel={href ? 'noreferrer noopener' : null}
      disabled={disabled}
      onClick={() => onClick && onClick()}
      tabIndex={disabled ? '-1' : 0}
      to={to}
      className={cn('button-big', styles.button, className)}
    >
      <SVG className={styles.scribble} svg={animationFrames[animationFrame]} />
      {text}
    </BtnElement>
  );
};

export default ButtonBig;

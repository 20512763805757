import React, { useState } from 'react';
import { ICentre, TCity, TState } from '@mayfield/sanity';
import { graphql, useStaticQuery } from 'gatsby';
import cn from 'classnames';
import {
  useBreakpoints,
  useIntersectionAnimation
} from '@mayfield/common/hooks';
import {
  IPlace,
  Map,
  MapAutocompleteSearch
} from '@mayfield/website/components';
import {
  distanceInKmBetweenEarthCoordinates,
  mapStateToInitials,
  toKebabCase
} from '@mayfield/common/utils';
import {
  IntersectionAnimation,
  LayoutProvider
} from '@mayfield/common/components';
import { LoadScript } from '@react-google-maps/api';
import {
  useGoogleMapsScript,
  useStaggerAnimation
} from '@mayfield/website/hooks';
import { cities } from '@mayfield/sanity/constants';
import { alphabeticalSort } from '@mayfield/website/utils';
import {
  GOOGLE_PLACES_API_CITY_TYPE,
  GOOGLE_PLACES_API_STATE_TYPE
} from '@mayfield/website/constants';
import {
  adelaide,
  brisbane,
  cairns,
  goldCoast,
  melbourne,
  queensland,
  southAustralia,
  victoria
} from '@mayfield/website/data/locations';
import CentreTile from './components/CentreTile';
import * as styles from './styles.module.scss';

interface IProps {
  locationProps?: {
    headerContent?: React.ReactNode;
    location: TState | TCity;
  };
}

const CentreSearch = ({ locationProps }: IProps) => {
  const {
    allSanityCentre: { nodes: centres }
  }: IQueryData = useStaticQuery(query);

  const getLocationData: () =>
    | undefined
    | google.maps.places.PlaceResult = () => {
    if (!locationProps?.location) {
      return undefined;
    }

    switch (locationProps.location) {
      case 'Victoria':
        return victoria;
      case 'South Australia':
        return southAustralia;
      case 'Queensland':
        return queensland;
      case 'Melbourne':
        return melbourne;
      case 'Cairns':
        return cairns;
      case 'Adelaide':
        return adelaide;
      case 'Gold Coast':
        return goldCoast;
      case 'Brisbane':
        return brisbane;
    }

    return undefined;
  };
  const locationData = getLocationData();

  const [searchedPlace, setSearchedPlace] = useState<
    google.maps.places.PlaceResult | undefined
  >(locationData);

  const { smallDesktop } = useBreakpoints();

  const { API_KEY, scriptHasLoaded, setScriptHasLoaded } =
    useGoogleMapsScript();

  const { ref, inView } = useIntersectionAnimation();

  const alphabeticalCentres = centres.sort(alphabeticalSort);

  const centresFilteredBySearchedState = () => {
    const searchedState = searchedPlace?.address_components?.find((component) =>
      component.types.includes(GOOGLE_PLACES_API_STATE_TYPE)
    )?.long_name;

    return alphabeticalCentres.filter((centre) => {
      const centreState = centre.address.state;
      return centreState === searchedState;
    });
  };

  const centresFilteredBySearchedCity = () => {
    if (!searchedPlace) {
      return alphabeticalCentres;
    }

    return alphabeticalCentres.filter((centre) => {
      const searchedCity = searchedPlace?.address_components?.[0].long_name;
      const centreCity = centre.address.city;

      return centreCity === searchedCity;
    });
  };

  const centresFilteredByDistanceFromLocation = () => {
    const MAX_DISTANCE_KM = 10;

    if (!searchedPlace) {
      return alphabeticalCentres;
    }

    const searchedLocationLat = searchedPlace.geometry?.location?.lat() || 0;
    const searchedLocationLng = searchedPlace.geometry?.location?.lng() || 0;

    return centres.filter((centre) => {
      const centreLocationLat = centre.location.lat;
      const centreLocationLng = centre.location.lng;

      const distanceKm = distanceInKmBetweenEarthCoordinates(
        searchedLocationLat,
        searchedLocationLng,
        centreLocationLat,
        centreLocationLng
      );

      if (distanceKm <= MAX_DISTANCE_KM) {
        return true;
      } else {
        return false;
      }
    });
  };

  const getFilteredCentresByLocation = () => {
    if (!searchedPlace) {
      return alphabeticalCentres;
    }

    const hasSearchedState = searchedPlace?.types?.includes(
      GOOGLE_PLACES_API_STATE_TYPE
    );
    if (hasSearchedState) {
      return centresFilteredBySearchedState();
    }

    const hasSearchedCity =
      searchedPlace?.address_components?.[0].types.includes(
        GOOGLE_PLACES_API_CITY_TYPE
      ) &&
      cities.includes(
        searchedPlace?.address_components?.[0].long_name as TCity
      );
    if (hasSearchedCity) {
      return centresFilteredBySearchedCity();
    }

    return centresFilteredByDistanceFromLocation();
  };
  const filteredCentres = getFilteredCentresByLocation();

  const locations: IPlace[] = filteredCentres.map((centre) => {
    const {
      address: { postcode, state, streetAddress, suburb }
    } = centre;
    return {
      address: `${streetAddress}\n${suburb}, ${mapStateToInitials(
        state
      )} ${postcode}`,
      location: centre.location,
      name: centre.title,
      logo: centre.centreInfo.logo,
      url: `/centres/${toKebabCase(centre.address.state)}/${
        centre.slug.current
      }`
    };
  });

  const { getDelayTime, staggerAnimation } = useStaggerAnimation(
    filteredCentres.length,
    inView
  );

  return (
    <LoadScript
      googleMapsApiKey={API_KEY}
      libraries={['places', 'geometry']}
      onLoad={() => setScriptHasLoaded(true)}
    >
      <div className={styles.container}>
        <LayoutProvider maxWidth padding grid>
          <div className={styles.titleAndSearch}>
            {
              <IntersectionAnimation delay={200}>
                {locationProps?.headerContent}

                {!locationProps?.headerContent && (
                  <h1 className={cn('h1', styles.title)}>Mayfield Locations</h1>
                )}
              </IntersectionAnimation>
            }

            <IntersectionAnimation delay={300}>
              <MapAutocompleteSearch
                setSearchedPlace={setSearchedPlace}
                className={styles.searchAndFilter}
                themeColor="grass"
                placeholder="Enter suburb, postcode, or state"
                defaultSearch={locationData?.formatted_address}
              />
            </IntersectionAnimation>
          </div>

          {smallDesktop && (
            <div className={styles.mapContainer}>
              <IntersectionAnimation animation="fade">
                <Map
                  isDefaultView={!searchedPlace}
                  locations={locations}
                  scriptHasLoaded={scriptHasLoaded}
                />
              </IntersectionAnimation>
            </div>
          )}

          <div className={styles.searchResults} ref={ref}>
            <LayoutProvider grid>
              {filteredCentres.map((centre, i) => (
                <IntersectionAnimation
                  animation={staggerAnimation ? 'fadeUp' : 'none'}
                  delay={getDelayTime(i)}
                  key={centre.slug.current}
                  className={styles.centreTile}
                  trigger={inView}
                >
                  <CentreTile {...centre} />
                </IntersectionAnimation>
              ))}

              {!filteredCentres?.[0] && (
                <p className={cn('h3', styles.noResultsText)}>
                  Sorry, currently no centres match your search!
                </p>
              )}
            </LayoutProvider>
          </div>
        </LayoutProvider>
      </div>
    </LoadScript>
  );
};

export default CentreSearch;

/**
 * GraphQL ====================================================================
 */

interface IQueryData {
  allSanityCentre: {
    nodes: ICentre[];
  };
}

const query = graphql`
  query {
    allSanityCentre {
      nodes {
        title

        ageGroups {
          toddler
          preKindy
          nursery
          kindergarten
        }

        centreInfo {
          logo {
            asset {
              gatsbyImageData(placeholder: BLURRED)
              url
            }
          }
        }

        location {
          lat
          lng
        }

        address {
          streetAddress
          suburb
          postcode
          state
          city
        }

        atfImage {
          desktopImage {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          altText
        }

        slug {
          current
        }
      }
    }
  }
`;

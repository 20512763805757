import React from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';

type TColor = 'earth' | 'sea' | 'sky' | 'grass' | 'floral';

interface IProps {
  text: string;
  color?: TColor;
  className?: string;
}

const Tag = ({ text, color = 'earth', className }: IProps) => {
  return (
    <div
      className={cn('caption', styles.container, className)}
      style={{ color: `var(--color-${color}-dark)` }}
    >
      {text}
    </div>
  );
};

export default Tag;

import { useCallback, useEffect, useState } from 'react';
import { useApp } from '@mayfield/website/hooks';

interface ISlice {
  _key: string;
  _type: string;
}

export const usePasswordProtectedSlices = (
  slices: ISlice[],
  pageSlug: string
) => {
  const [unlockedSlices, setUnlockedSlices] = useState<ISlice[]>([]);
  const [
    passwordSliceDistanceFromTopOfPage,
    setPasswordSliceDistanceFromTopOfPage
  ] = useState(0);

  const { pagePasswordStatus, setPagePasswordStatus } = useApp();

  const pathname = pageSlug === '/' ? '/' : `/${pageSlug}/`;

  const passwordSlice = slices.find((slice) => slice._type === 'password');

  useEffect(() => {
    setPagePasswordStatus((prev) => {
      const pagesWithSavedState = Object.keys(prev);
      const pageHasSavedState = pagesWithSavedState.includes(pathname);

      if (pageHasSavedState) {
        return prev;
      }

      const updatedState = { ...prev };
      updatedState[pathname] = 'locked';
      return updatedState;
    });
  }, [pathname, setPagePasswordStatus]);

  const stringifiedPasswordPageStatus = JSON.stringify(pagePasswordStatus);

  useEffect(() => {
    const pageIncludesPasswordSlice = passwordSlice ? true : false;

    if (!pageIncludesPasswordSlice) {
      setUnlockedSlices(slices);
      return;
    }

    const passwordSliceIndex = slices.indexOf(passwordSlice as ISlice);

    if (pagePasswordStatus[pathname] === 'unlocked') {
      const slicesBeforePasswordSlice = slices.slice(0, passwordSliceIndex);
      const slicesAfterPasswordSlice = slices.slice(passwordSliceIndex + 1);

      setUnlockedSlices([
        ...slicesBeforePasswordSlice,
        ...slicesAfterPasswordSlice
      ]);
      return;
    }

    // When password hasn't been entered
    setUnlockedSlices(slices.slice(0, passwordSliceIndex + 1)); // +1 to include the password slice
  }, [
    stringifiedPasswordPageStatus,
    pagePasswordStatus,
    slices,
    passwordSlice,
    pathname
  ]);

  const updatePasswordSliceDistanceFromTopOfPage = useCallback(() => {
    const passwordSliceKey = passwordSlice?._key || '';
    const passwordSliceDomElement = document.querySelector(
      `[data-key="${passwordSliceKey}"]`
    );

    if (!passwordSliceDomElement) return;

    const passwordSliceDistanceFromTopOfPage =
      passwordSliceDomElement.getBoundingClientRect().top + scrollY;

    setPasswordSliceDistanceFromTopOfPage(passwordSliceDistanceFromTopOfPage);
  }, [passwordSlice]);

  useEffect(() => {
    setTimeout(updatePasswordSliceDistanceFromTopOfPage, 200); // Need this for some reason otherwise dom element can't be found

    window.addEventListener('resize', updatePasswordSliceDistanceFromTopOfPage);

    return () => {
      window.removeEventListener(
        'resize',
        updatePasswordSliceDistanceFromTopOfPage
      );
    };
  }, [updatePasswordSliceDistanceFromTopOfPage]);

  useEffect(() => {
    if (pagePasswordStatus[pathname] === 'locked') return;

    const HEADER_HEIGHT_PX = 56;

    setTimeout(() => {
      window.scrollTo({
        top: passwordSliceDistanceFromTopOfPage - HEADER_HEIGHT_PX,
        behavior: 'smooth'
      });
    }, 200);
  }, [pagePasswordStatus, passwordSliceDistanceFromTopOfPage, pathname]);

  return { unlockedSlices };
};

// extracted by mini-css-extract-plugin
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var letter = "styles-module--letter--60d5c";
export var letterA = "styles-module--letterA--30948";
export var letterD = "styles-module--letterD--1f1d4";
export var letterE = "styles-module--letterE--aae89";
export var letterFI = "styles-module--letterFI--80a63";
export var letterL = "styles-module--letterL--c002d";
export var letterM = "styles-module--letterM--086c6";
export var letterY = "styles-module--letterY--c0ad6";
export var letters = "styles-module--letters--f7661";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
import React, { useEffect, useRef, useState } from 'react';
import { SVG } from '@mayfield/common/components';
import cn from 'classnames';
import { useScroll } from '@mayfield/common/hooks';
import * as styles from './styles.module.scss';

interface IProps {
  tooltip: string;
}

const DesktopTooltip = ({ tooltip }: IProps) => {
  // Need to do all this logic so that the tooltip can extend beyong the accordion container
  const [tooltipIconPosition, setTooltipIconPosition] = useState({
    top: 0,
    left: 0
  });

  const { scrollY } = useScroll();

  const tooltipIconRef = useRef<HTMLDivElement>(null);
  const tooltipContentRef = useRef<HTMLDivElement>(null);

  const calculateTooltipPosition = () => {
    if (!tooltipIconRef.current) return;

    const tooltipIconPosition = tooltipIconRef.current.getBoundingClientRect();
    const { left, top } = tooltipIconPosition;

    setTooltipIconPosition({
      top,
      left
    });
  };

  useEffect(() => {
    calculateTooltipPosition();
  }, [scrollY]);

  useEffect(() => {
    window.addEventListener('resize', calculateTooltipPosition);

    return () => {
      window.removeEventListener('resize', calculateTooltipPosition);
    };
  }, []);

  return (
    <>
      <div ref={tooltipIconRef} tabIndex={0} className={styles.container}>
        <div
          style={{
            top: tooltipIconPosition.top,
            left: tooltipIconPosition.left
          }}
          className={styles.tooltipContent}
          ref={tooltipContentRef}
        >
          <p className={cn('caption', styles.tooltipText)}>{tooltip}</p>
          <div className={styles.triangleContainer}>
            <div className={styles.triangle} />
          </div>
        </div>

        <SVG className={styles.icon} svg="tooltip" />
      </div>
    </>
  );
};

export default DesktopTooltip;

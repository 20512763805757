import React, { useEffect } from 'react';
import { ITopicSelectorTopic } from '@mayfield/sanity';
import {
  LayoutProvider,
  PortableTextRenderer,
  SVG,
  UniversalLink
} from '@mayfield/common/components';
import cn from 'classnames';
import { useAccordion } from '@mayfield/common/hooks';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import * as styles from './styles.module.scss';

interface IProps {
  topic: ITopicSelectorTopic;
  backgroundColor: string;
  isActive: boolean;
  handleClick: () => void;
}

const MobileTopic = ({
  backgroundColor,
  topic,
  isActive,
  handleClick
}: IProps) => {
  const { containerHeight, contentRef, isExpanded, setIsExpanded } =
    useAccordion();

  const { _rawText, title, button, image } = topic;

  useEffect(() => {
    setIsExpanded(isActive);
  }, [isActive, setIsExpanded]);

  return (
    <div style={{ backgroundColor }} className={styles.container}>
      <button onClick={handleClick} className={styles.button}>
        <LayoutProvider padding className={styles.buttonContent}>
          <span className={cn('h1')}>{title}</span>

          <SVG
            svg={isActive ? 'minus' : 'plus'}
            key={isActive ? 'foo' : 'bar'} // Retrigger animation
            className={styles.icon}
          />
        </LayoutProvider>
      </button>

      <div
        aria-hidden={!isExpanded}
        className={styles.accordion}
        style={{ height: containerHeight }}
      >
        <div ref={contentRef} className={styles.content}>
          <LayoutProvider padding>
            {image && (
              <div className={styles.imageContainer}>
                <GatsbyImage
                  alt={image.altText || ''}
                  image={image.asset.gatsbyImageData}
                />
              </div>
            )}

            <PortableTextRenderer
              containerClassName={styles.text}
              textClassName="b2"
              rawText={_rawText}
              gatsbyImageComponent={GatsbyImage}
              getGatsbyImageData={getGatsbyImageData}
            />

            {button && (
              <div>
                <UniversalLink
                  variant="secondary"
                  theme="lightOnDark"
                  link={button}
                  disabled={!isActive}
                />
              </div>
            )}
          </LayoutProvider>
        </div>
      </div>
    </div>
  );
};

export default MobileTopic;

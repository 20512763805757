import React from 'react';
import { IRichTextEmbed } from '@mayfield/sanity';

const EmbedComponent = ({ value }: IRichTextEmbed) => {
  if (!value?.code) return null;

  return <div dangerouslySetInnerHTML={{ __html: value?.code }} />;
};

export default EmbedComponent;
